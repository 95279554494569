@charset "UTF-8";
/*!
	DO NOT EDIT stylesheet.css!

	Edit .scss files instead. See http://www.artodia.com/phpbb-31-tutorials/compile-theme/
	If you have any questions, please ask on support forum at http://www.artodia.com/forums/
*/
@font-face {
  font-family: 'FuturaLTPro-Book';
  src: url("/media/fonts/FuturaLTPro-Book.eot?#iefix") format("embedded-opentype"), url("/media/fonts/FuturaLTPro-Book.otf") format("opentype"), url("/media/fonts/FuturaLTPro-Book.woff") format("woff"), url("/media/fonts/FuturaLTPro-Book.ttf") format("truetype"), url("/media/fonts/FuturaLTPro-Book.svg#FuturaLTPro-Book") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'FuturaLTPro-Bold';
  src: url("/media/fonts/FuturaLTPro-Bold.eot?#iefix") format("embedded-opentype"), url("/media/fonts/FuturaLTPro-Bold.otf") format("opentype"), url("/media/fonts/FuturaLTPro-Bold.woff") format("woff"), url("/media/fonts/FuturaLTPro-Bold.ttf") format("truetype"), url("/media/fonts/FuturaLTPro-Bold.svg#FuturaLTPro-Bold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'ChaparralPro-Bold';
  src: url("/media/fonts/ChaparralPro-Bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'ChaparralPro';
  src: url("/media/fonts/Chaparral-Pro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'ChaparralPro-BoldIt';
  src: url("/media/fonts/ChaparralPro-BoldIt.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'ChaparralPro-Italic';
  src: url("/media/fonts/ChaparralPro-Italic.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'OpenSans-Bold';
  src: url("/media/fonts/OpenSans-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'OpenSans-ExtraBold';
  src: url("/media/fonts/OpenSans-ExtraBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'OpenSans-Regular';
  src: url("/media/fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'OpenSans-Light';
  src: url("/media/fonts/OpenSans-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'OpenSans-BoldItalic';
  src: url("/media/fonts/OpenSans-BoldItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'OpenSans-Italic';
  src: url("/media/fonts/OpenSans-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'alegreyablack';
  src: url("/media/fonts/alegreya-black-webfont.woff2") format("woff2"), url("/media/fonts/alegreya-black-webfont.woff") format("woff"), url("/media/fonts/alegreya-black-webfont.svg#alegreyablack") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'alegreyablack_italic';
  src: url("/media/fonts/alegreya-blackitalic-webfont.woff2") format("woff2"), url("/media/fonts/alegreya-blackitalic-webfont.woff") format("woff"), url("/media/fonts/alegreya-blackitalic-webfont.svg#alegreyablack_italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'alegreyabold';
  src: url("/media/fonts/alegreya-bold-webfont.woff2") format("woff2"), url("/media/fonts/alegreya-bold-webfont.woff") format("woff"), url("/media/fonts/alegreya-bold-webfont.svg#alegreyabold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'alegreyabold_italic';
  src: url("/media/fonts/alegreya-bolditalic-webfont.woff2") format("woff2"), url("/media/fonts/alegreya-bolditalic-webfont.woff") format("woff"), url("/media/fonts/alegreya-bolditalic-webfont.svg#alegreyabold_italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'alegreyaitalic';
  src: url("/media/fonts/alegreya-italic-webfont.woff2") format("woff2"), url("/media/fonts/alegreya-italic-webfont.woff") format("woff"), url("/media/fonts/alegreya-italic-webfont.svg#alegreyaitalic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'alegreyaregular';
  src: url("/media/fonts/alegreya-regular-webfont.woff2") format("woff2"), url("/media/fonts/alegreya-regular-webfont.woff") format("woff"), url("/media/fonts/alegreya-regular-webfont.svg#alegreyaregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'alegreya_scblack';
  src: url("/media/fonts/alegreyasc-black-webfont.woff2") format("woff2"), url("/media/fonts/alegreyasc-black-webfont.woff") format("woff"), url("/media/fonts/alegreyasc-black-webfont.svg#alegreya_scblack") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'alegreya_scblack_italic';
  src: url("/media/fonts/alegreyasc-blackitalic-webfont.woff2") format("woff2"), url("/media/fonts/alegreyasc-blackitalic-webfont.woff") format("woff"), url("/media/fonts/alegreyasc-blackitalic-webfont.svg#alegreya_scblack_italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'alegreya_scbold';
  src: url("/media/fonts/alegreyasc-bold-webfont.woff2") format("woff2"), url("/media/fonts/alegreyasc-bold-webfont.woff") format("woff"), url("/media/fonts/alegreyasc-bold-webfont.svg#alegreya_scbold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'alegreya_scbold_italic';
  src: url("/media/fonts/alegreyasc-bolditalic-webfont.woff2") format("woff2"), url("/media/fonts/alegreyasc-bolditalic-webfont.woff") format("woff"), url("/media/fonts/alegreyasc-bolditalic-webfont.svg#alegreya_scbold_italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'alegreya_scitalic';
  src: url("/media/fonts/alegreyasc-italic-webfont.woff2") format("woff2"), url("/media/fonts/alegreyasc-italic-webfont.woff") format("woff"), url("/media/fonts/alegreyasc-italic-webfont.svg#alegreya_scitalic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'alegreya_scregular';
  src: url("/media/fonts/alegreyasc-regular-webfont.woff2") format("woff2"), url("/media/fonts/alegreyasc-regular-webfont.woff") format("woff"), url("/media/fonts/alegreyasc-regular-webfont.svg#alegreya_scregular") format("svg");
  font-weight: normal;
  font-style: normal; }

body {
  font-family: "OpenSans-Regular","Roboto","Helvetica Neue",Helvetica,Arial,sans-serif; }

.nopadding {
  padding-left: 0;
  padding-right: 0; }

.nomargin {
  margin-left: 0;
  margin-right: 0; }

#menu .dropdown-submenu {
  position: relative; }

a.button.btn {
  color: #FFF;
  background: #E94F53;
  font-size: 26px;
  border-radius: 55px;
  padding: 25px 40px;
  font-family: 'Lato';
  transition: all 0.3s; }

.transition {
  transition: all 0.5s; }

@media screen and (min-width: 1200px) {
  .lg-line-2 {
    -webkit-column-count: 2;
    column-count: 2; } }

.topSite .top {
  font-family: 'ChaparralPro-BoldIt';
  width: 100%;
  height: 860px;
  text-align: center;
  margin: auto;
  background-position: center;
  padding-top: 126px; }
  .topSite .top .offert {
    font-size: 36px;
    margin-bottom: 50px;
    color: #003030; }
    @media screen and (max-width: 992px) {
      .topSite .top .offert {
        font-size: 30px !important; } }
  .topSite .top .title {
    font-size: 70px;
    color: #003030;
    margin-top: 0;
    line-height: 60px; }
    @media screen and (max-width: 992px) {
      .topSite .top .title {
        font-size: 50px !important; } }
    @media screen and (max-width: 480px) {
      .topSite .top .title {
        font-size: 40px !important; } }
  .topSite .top .line {
    margin-top: 15px;
    width: 100px;
    height: 6px;
    background: #008AC2;
    margin: auto; }

ul.default li {
  list-style: url("/media/images/li.png");
  font-size: 20px;
  margin-bottom: 25px;
  color: #333; }

ul.default.white li {
  list-style: url("/media/images/liWhite.png");
  color: #FFF; }

.parallax-window {
  min-height: 330px;
  background: transparent;
  text-align: center; }
  .parallax-window a {
    font-family: 'Lato';
    background: #E94F53;
    color: #FFF;
    font-size: 25px;
    padding: 30px;
    border-radius: 50px;
    margin-top: 110px; }

.download .record {
  padding: 60px 0; }
  .download .record .title {
    background: url("/media/images/homepage/arrow_blue_box.png") no-repeat top left;
    padding-left: 100px;
    padding-bottom: 40px;
    font-family: 'FuturaLTPro-Book';
    color: #333;
    font-size: 36px;
    margin-top: 75px; }
  .download .record .txt {
    padding-left: 100px;
    font-size: 20px;
    color: #333;
    padding-bottom: 30px; }
  .download .record .button {
    margin-top: 130px; }

.download .record:nth-child(2n) {
  background: #EBEBEB; }

@media screen and (max-width: 768px) {
  .parallax-window {
    background: url("/media/images/offertParalaxa.jpg"); }
  .download .record {
    padding: 0 !important; }
    .download .record .title {
      background: none;
      padding-left: 0;
      font-size: 25px; }
    .download .record .txt {
      padding-left: 0px;
      font-size: 20px; }
    .download .record .button {
      margin-bottom: 50px;
      margin-top: 0px !important; } }

.glyphicon.glyphicon-menu-down {
  margin-left: 3px; }

.subactive {
  background-color: #E94F53 !important; }

.parallax-mirror {
  z-index: 1; }

a {
  background-color: transparent !important; }

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
  background-color: transparent !important; }

.shadow {
  box-shadow: 10px 10px 29px 0px rgba(0, 0, 0, 0.75); }

.shadow-on-map {
  box-shadow: 0px 10px 23px 0px rgba(0, 0, 0, 0.75);
  position: relative;
  z-index: 1; }

.biglogo {
  margin-left: -30px; }
  @media screen and (max-width: 1200px) {
    .biglogo {
      margin-left: 20px; } }
  @media screen and (max-width: 992px) {
    .biglogo {
      margin-left: 0px; } }
  @media screen and (max-width: 768px) {
    .biglogo {
      margin-left: -15px; } }

.topContainer {
  -webkit-box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.28);
  position: relative;
  z-index: 30; }
  .topContainer .top .sprite-logo_header {
    width: 400px; }
    @media screen and (max-width: 1854px) {
      .topContainer .top .sprite-logo_header {
        width: 300px; } }
    @media screen and (max-width: 992px) {
      .topContainer .top .sprite-logo_header {
        margin-left: 25px; } }
    @media screen and (max-width: 480px) {
      .topContainer .top .sprite-logo_header {
        width: 60px; } }
    @media screen and (max-width: 1854px) {
      .topContainer .top .sprite-logo_header .biglogo {
        width: 300px; } }

.checkerboard {
  background-color: #003030; }

.dropdown-menu {
  border-radius: none; }

.header-house-select-container-top {
  background-color: #A18A5B;
  -webkit-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.28);
  position: relative;
  z-index: 30; }

.header-house-select-container {
  background-color: #A18A5B;
  height: 183px;
  display: none; }
  @media screen and (max-width: 992px) {
    .header-house-select-container {
      display: none; } }
  .header-house-select-container .container {
    display: block; }
    .header-house-select-container .container .sub {
      font-family: 'OpenSans-Bold';
      font-size: 24px;
      color: #A28A5C;
      display: inline-block; }
  .header-house-select-container .house {
    cursor: pointer;
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
    color: white; }
    .header-house-select-container .house .blockB {
      background-image: url("/media/images/footerB_inactive.png");
      width: 110px;
      height: 157px;
      margin: 0 auto; }
      .header-house-select-container .house .blockB:hover, .header-house-select-container .house .blockB.active {
        background-image: url("/media/images/headerB_active.png"); }
    .header-house-select-container .house .blockA {
      background-image: url("/media/images/footerA_inactive.png");
      width: 82px;
      height: 158px;
      margin: 0 auto; }
      .header-house-select-container .house .blockA:hover, .header-house-select-container .house .blockA.active {
        background-image: url("/media/images/headerA_active.png");
        width: 86px; }
    .header-house-select-container .house .blockC {
      background-image: url("/media/images/footerC_inactive.png");
      width: 192px;
      height: 158px;
      margin: 0 auto; }
      .header-house-select-container .house .blockC:hover, .header-house-select-container .house .blockC.active {
        background-image: url("/media/images/headerC_active.png"); }
    @media screen and (max-width: 480px) {
      .header-house-select-container .house {
        display: block;
        margin-bottom: 50px; } }
    .header-house-select-container .house:hover {
      color: #A18A5B; }
    @media all and (max-width: 458px) {
      .header-house-select-container .house.houseC {
        margin-top: 17px; } }
  .header-house-select-container .text-center {
    position: relative;
    margin-top: 16px; }
    .header-house-select-container .text-center .spacer {
      width: 1px !important;
      height: 148px;
      background-color: #8A754F;
      display: inline-block;
      padding: 0; }
      @media screen and (max-width: 1200px) {
        .header-house-select-container .text-center .spacer {
          position: absolute;
          bottom: 0; } }
      @media screen and (max-width: 450px) {
        .header-house-select-container .text-center .spacer {
          display: none; } }

#gallery-container {
  background-color: #003030; }
  @media screen and (min-width: 1025px) {
    #gallery-container .hidden-owl-gallery {
      display: none !important; } }
  @media screen and (max-width: 1025px) {
    #gallery-container .hidden-owl-gallery {
      display: visible !important; } }
  @media screen and (max-width: 1024px) {
    #gallery-container .hidden-non-owl-gallery {
      display: none !important; } }
  @media screen and (min-width: 1024px) {
    #gallery-container .hidden-non-owl-gallery {
      display: visible !important; } }
  #gallery-container h2 {
    background-color: #003030;
    text-align: center;
    color: white;
    font-size: 70px;
    font-family: 'ChaparralPro';
    font-weight: bold;
    font-style: italic;
    margin: 0;
    padding-top: 120px; }
  #gallery-container h3 {
    background-color: #003030;
    text-align: center;
    color: #9F8A59;
    font-size: 40px;
    font-family: 'ChaparralPro';
    font-style: italic;
    margin: 0;
    padding-top: 30px;
    margin-bottom: 80px; }
  #gallery-container .gallery-button {
    transition: all 0.5s ease;
    display: inline-block;
    padding: 12px 15px;
    margin-bottom: 100px;
    line-height: 56px;
    font-family: 'OpenSans-Bold';
    font-size: 17px;
    cursor: pointer;
    height: 56px;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari*/
    -khtml-user-select: none;
    /* Konqueror */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently*/ }
    #gallery-container .gallery-button.folded {
      transition: all 0.2s ease;
      border: 2px solid white;
      background-color: #003030;
      color: white; }
      #gallery-container .gallery-button.folded:before {
        content: attr(data-fold);
        transition: all 0.5s ease;
        position: relative;
        top: -12px; }
      #gallery-container .gallery-button.folded:after {
        content: "›";
        display: inline-block;
        -webkit-transform: rotate(90deg) scale(1, 1.5) translate(0px, -5px);
        -moz-transform: rotate(90deg) scale(1, 1.5) translate(0px, -5px);
        -o-transform: rotate(90deg) scale(1, 1.5) translate(0px, -5px);
        -ms-transform: rotate(90deg) scale(1, 1.5) translate(0px, -5px);
        transform: rotate(90deg) scale(1, 1.5) translate(0px, -5px);
        transition: all 0.5s ease;
        position: relative;
        top: -12px; }
      #gallery-container .gallery-button.folded:hover {
        transition: all 0.2s ease;
        color: #003030;
        background-color: white; }
    #gallery-container .gallery-button.unfolded {
      transition: all 0.2s ease;
      border: 2px solid #A28A5C;
      background-color: #003030;
      color: #A28A5C; }
      #gallery-container .gallery-button.unfolded:before {
        content: attr(data-unfold);
        transition: all 0.5s ease;
        position: relative;
        top: -12px; }
      #gallery-container .gallery-button.unfolded:after {
        content: "›";
        display: inline-block;
        -webkit-transform: rotate(-90deg) scale(1, 1.5);
        -moz-transform: rotate(-90deg) scale(1, 1.5);
        -o-transform: rotate(-90deg) scale(1, 1.5);
        -ms-transform: rotate(-90deg) scale(1, 1.5);
        transform: rotate(-90deg) scale(1, 1.5);
        transition: all 0.5s ease;
        position: relative;
        top: -12px; }
      #gallery-container .gallery-button.unfolded:hover {
        transition: all 0.2s ease;
        color: #003030;
        background-color: #A28A5C; }
  #gallery-container .content2.folded {
    height: 500px;
    overflow: hidden;
    position: relative;
    transition: all 1s ease,height 1s ease; }
    #gallery-container .content2.folded .foreground {
      background: linear-gradient(rgba(0, 48, 48, 0), #003030);
      /* Standard syntax */
      transition: top 1s ease,opacity 1s ease;
      position: absolute;
      left: 0px;
      top: -100px;
      width: 100vw;
      height: 500px;
      opacity: 1;
      z-index: 500; }
    #gallery-container .content2.folded .foreground-filler {
      width: 100%;
      height: 100px;
      background: #003030;
      position: absolute;
      bottom: 0;
      left: 0;
      transition: top 1s ease,opacity 1s ease;
      opacity: 1; }
    #gallery-container .content2.folded .gallery-container {
      position: absolute;
      width: 100vw;
      height: auto; }
  #gallery-container .content2.unfolded {
    height: 1190px;
    overflow: hidden;
    position: relative;
    transition: all 1s ease,height 1s ease; }
    @media only screen and (max-width: 1556px) and (min-width: 1167px) {
      #gallery-container .content2.unfolded {
        height: 1500px !important; } }
    @media only screen and (max-width: 1167px) and (min-width: 778px) {
      #gallery-container .content2.unfolded {
        height: 1300px !important; } }
    #gallery-container .content2.unfolded .gallery-container {
      position: relative;
      width: 100vw;
      height: auto; }
    #gallery-container .content2.unfolded .foreground {
      pointer-events: none;
      background: linear-gradient(rgba(0, 48, 48, 0), #003030);
      /* Standard syntax */
      transition: top 1s ease,opacity 1s ease;
      position: absolute;
      opacity: 0;
      left: 0px;
      top: 690px;
      width: 100vw;
      height: 500px;
      z-index: 500; }
    #gallery-container .content2.unfolded .foreground-filler {
      pointer-events: none;
      transition: top 1s ease,opacity 1s ease;
      width: 100%;
      height: 100px;
      background: #003030;
      position: absolute;
      bottom: 0;
      left: 0;
      opacity: 0; }
  #gallery-container .content {
    overflow: initial;
    text-align: center;
    max-width: 100%;
    display: inline-block;
    -webkit-column-gap: 30px;
    -webkit-column-fill: auto;
    -moz-column-gap: 30px;
    -moz-column-fill: auto;
    column-gap: 30px;
    column-fill: auto; }
    @media only screen and (min-width: 1556px) {
      #gallery-container .content {
        column-count: 4;
        -moz-column-count: 4;
        -webkit-column-count: 4;
        height: 830px; } }
    @media only screen and (max-width: 1556px) and (min-width: 1167px) {
      #gallery-container .content {
        column-count: 3;
        -moz-column-count: 3;
        -webkit-column-count: 3;
        height: 990px; } }
    @media only screen and (max-width: 1167px) and (min-width: 778px) {
      #gallery-container .content {
        column-count: 2;
        -moz-column-count: 2;
        -webkit-column-count: 2;
        height: 1320px; } }
    @media only screen and (max-width: 389px) {
      #gallery-container .content {
        column-count: 1;
        -moz-column-count: 1;
        -webkit-column-count: 1; } }
    #gallery-container .content .img-container {
      display: inline-block;
      /* position: relative; */
      overflow: visible;
      margin-bottom: 65px;
      width: 389px;
      height: 260px; }
      @media only screen and (min-width: 1556px) {
        #gallery-container .content .img-container:nth-child(3), #gallery-container .content .img-container:nth-child(7) {
          margin-top: 170px; } }
      @media only screen and (max-width: 1556px) and (min-width: 1167px) {
        #gallery-container .content .img-container:nth-child(4) {
          margin-top: 170px; } }
      @media only screen and (max-width: 1167px) and (min-width: 778px) {
        #gallery-container .content .img-container:nth-child(6) {
          margin-top: 170px; }
        #gallery-container .content .img-container:nth-child(5) {
          display: none; } }
      #gallery-container .content .img-container .image {
        margin-left: -200px;
        width: 389px;
        height: 260px;
        transition: all 0.3s ease;
        position: absolute;
        /* left: 0; */
        z-index: initial; }
        #gallery-container .content .img-container .image:hover {
          margin-left: -208px;
          margin-top: 0px;
          transition: all 0.3s ease;
          width: 406px;
          height: 283px;
          z-index: 1;
          -webkit-box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.75);
          -moz-box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.75);
          box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.75); }
  #gallery-container .content2 .gallery-container {
    overflow: initial;
    text-align: center;
    max-width: 100%;
    -webkit-column-gap: 30px;
    -webkit-column-fill: auto;
    -moz-column-gap: 30px;
    -moz-column-fill: auto;
    column-gap: 30px;
    column-fill: auto; }
    @media only screen and (min-width: 1556px) {
      #gallery-container .content2 .gallery-container {
        column-count: 4;
        -moz-column-count: 4;
        -webkit-column-count: 4;
        height: 1200px !important; } }
    @media only screen and (max-width: 1556px) and (min-width: 1167px) {
      #gallery-container .content2 .gallery-container {
        column-count: 3;
        -moz-column-count: 3;
        -webkit-column-count: 3;
        height: 1500px !important; } }
    @media only screen and (max-width: 1167px) and (min-width: 778px) {
      #gallery-container .content2 .gallery-container {
        column-count: 2;
        -moz-column-count: 2;
        -webkit-column-count: 2;
        height: 1320px !important; } }
    @media only screen and (max-width: 389px) {
      #gallery-container .content2 .gallery-container {
        column-count: 1;
        -moz-column-count: 1;
        -webkit-column-count: 1; } }
    #gallery-container .content2 .gallery-container .img-container {
      display: inline-block;
      /* position: relative; */
      overflow: visible;
      margin-bottom: 65px;
      width: 389px;
      height: 260px; }
      @media only screen and (min-width: 1556px) {
        #gallery-container .content2 .gallery-container .img-container:nth-child(4), #gallery-container .content2 .gallery-container .img-container:nth-child(10) {
          margin-top: 170px; } }
      @media only screen and (max-width: 1556px) and (min-width: 1167px) {
        #gallery-container .content2 .gallery-container .img-container:nth-child(1), #gallery-container .content2 .gallery-container .img-container:nth-child(9) {
          margin-top: 170px; } }
      @media only screen and (max-width: 1167px) and (min-width: 778px) {
        #gallery-container .content2 .gallery-container .img-container:nth-child(1) {
          margin-top: 170px; } }
      #gallery-container .content2 .gallery-container .img-container .image {
        width: 389px;
        height: 260px;
        transition: all 0.3s ease;
        margin-left: -200px;
        position: absolute;
        /* left: 0; */
        z-index: initial; }
        #gallery-container .content2 .gallery-container .img-container .image:hover {
          margin-left: -208px;
          margin-top: 0px;
          transition: all 0.3s ease;
          width: 406px;
          height: 283px;
          z-index: 1;
          -webkit-box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.75);
          -moz-box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.75);
          box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.75); }

.caption1, .caption2, .caption3 {
  background-color: white;
  font-family: 'ChaparralPro-BoldIt';
  font-size: 43px;
  line-height: 110%;
  width: auto !important;
  padding: 20px 20px; }
  .caption1 .lead, .caption2 .lead, .caption3 .lead {
    font-weight: normal;
    font-style: italic;
    font-family: 'ChaparralPro';
    font-size: 33px;
    line-height: 80%;
    line-spacing: 0em; }
    @media screen and (max-width: 480px) {
      .caption1 .lead, .caption2 .lead, .caption3 .lead {
        font-size: 27px; } }

@media all and (max-width: 768px) {
  .caption1 {
    top: 0 !important; } }

.caption1:after, .caption2:after, .caption3:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-top-color: white;
  border-width: 30px;
  margin-left: -30px; }

body {
  overflow-x: hidden;
  overflow-y: scroll; }

.layout {
  background-image: url("/media/image/layout.png"); }

.blue-button-container {
  text-align: center; }
  .blue-button-container .blue-button {
    background-color: #2693FF;
    width: 311px;
    height: 56px;
    text-align: center;
    display: inline-block;
    font-size: 17px;
    color: white;
    font-family: 'OpenSans-Bold';
    line-height: 56px;
    cursor: pointer; }

.blur {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #003030;
  z-index: 50;
  top: 0;
  opacity: 0;
  transition: opacity 1s ease;
  display: none; }

.blur.active {
  opacity: 0.8;
  transition: opacity 1s ease;
  display: block; }

body.blurred {
  filter: blur(10px);
  transition: filter 1s ease; }

.non-blur {
  filter: none !important; }

.sprite-conMail {
  width: 44px;
  height: 32px;
  background: url("/media/images/mail.png"); }

.sprite-tel {
  width: 65px;
  height: 65px;
  background: url("/media/images/tel1.png"); }

header {
  position: relative; }
  header .phone_header {
    text-align: right;
    font-size: 25px;
    color: #003030;
    margin-right: 30px; }
    @media all and (max-width: 1200px) {
      header .phone_header {
        display: none; } }
    header .phone_header a {
      text-decoration: none;
      color: inherit; }
  header .transform {
    transition: all 0.5s; }
  header .float-left {
    float: left; }
  header .contact {
    font-size: 14px;
    position: fixed;
    right: 0;
    top: 40%;
    width: 72px;
    z-index: 999;
    background-image: url("/media/images/right_contact.png");
    height: 104px; }
    @media screen and (max-width: 1200px) {
      header .contact {
        top: 65%; } }
    @media screen and (max-width: 768px) {
      header .contact {
        top: 30%; } }
    header .contact .header {
      cursor: pointer;
      width: 84px;
      z-index: 5;
      height: 104px;
      position: absolute;
      left: 0; }
      header .contact .header span {
        color: #FFF;
        display: block;
        font-size: 1.15em;
        width: 230px;
        left: -60px;
        bottom: 100px;
        position: absolute; }
    header .contact .phonenumber {
      position: absolute;
      color: #D93600;
      font-size: 35px;
      font-family: 'OpenSans-ExtraBold';
      top: 26px;
      left: 96px;
      text-decoration: none; }
    header .contact .txt {
      background: #FFF;
      position: absolute;
      width: 243px;
      height: 233px;
      right: -243px;
      top: 13px; }
      header .contact .txt .top {
        height: 57px;
        background: #E94F53; }
        header .contact .txt .top .marketing {
          font-weight: 700;
          color: #FFF;
          font-size: 1.15em;
          text-align: center;
          padding-top: 18px; }
        header .contact .txt .top .who {
          margin-top: 10px; }
          header .contact .txt .top .who .photo {
            width: 73px;
            height: 73px;
            float: left;
            background-image: url("/media/images/marketing.png"); }
          header .contact .txt .top .who .name {
            color: #333;
            text-align: center; }
            header .contact .txt .top .who .name .el1 {
              font-size: 1.7em; }
            header .contact .txt .top .who .name .el2 {
              font-size: 2.5em;
              font-weight: 600;
              line-height: 20px; }
      header .contact .txt .contactUs {
        text-align: center;
        margin: auto; }
        header .contact .txt .contactUs .phone {
          margin-top: 17px; }
          header .contact .txt .contactUs .phone a {
            color: #333;
            font-size: 1.85em; }
            header .contact .txt .contactUs .phone a .el1 {
              font-weight: 100;
              display: inline; }
            header .contact .txt .contactUs .phone a .el2 {
              font-weight: 400;
              display: inline; }
            header .contact .txt .contactUs .phone a .el3 {
              font-weight: 900;
              display: inline; }
        header .contact .txt .contactUs .mail {
          text-align: center;
          padding-top: 15px;
          clear: both; }
          header .contact .txt .contactUs .mail a {
            color: #333;
            font-size: 1.15em;
            margin-top: -10px;
            font-weight: 800; }
  header .contact.active {
    filter: none !important;
    width: 315px; }
    header .contact.active .txt {
      right: 0;
      width: 243px; }
  header .topMenu, header .searchMenu {
    display: none; }
  header #menubutton {
    display: none;
    width: 37px;
    height: 36px;
    z-index: 999;
    background-image: url("/media/images/menu.png");
    background-repeat: no-repeat;
    background-position: -34px 0;
    -webkit-transform: translate3d(0, 0, 0); }
    @media all and (max-width: 1200px) {
      header #menubutton {
        display: block; } }
  header #menubutton.openIcon {
    background-position: 3px 0; }
  header .top {
    width: 100%;
    position: relative;
    z-index: 1; }
    header .top .sprite-logo_header {
      margin: 10px 40px;
      margin-right: 0;
      float: left; }
      header .top .sprite-logo_header .smalllogo {
        display: none;
        margin-left: -15px; }
        @media all and (max-width: 480px) {
          header .top .sprite-logo_header .smalllogo {
            display: block;
            margin-top: 20px;
            padding: 0; } }
        @media all and (max-width: 382px) {
          header .top .sprite-logo_header .smalllogo {
            width: 200px; } }
      @media all and (max-width: 480px) {
        header .top .sprite-logo_header .biglogo {
          display: none; } }
  header .right {
    position: absolute;
    right: 40px;
    top: 20px; }
    header .right .row {
      float: left;
      margin-left: 45px;
      cursor: pointer; }
  header #menu {
    background-color: #FFFFFF;
    width: 100%;
    height: 55px;
    position: relative; }
    header #menu .shadow-mobile {
      display: none;
      background-image: url("/media/images/shadow-mobile.png");
      width: 33px;
      height: 1725px;
      z-index: 999; }
      @media screen and (max-width: 1200px) {
        header #menu .shadow-mobile {
          position: absolute;
          display: block;
          top: 0;
          left: 0; } }
    header #menu .lang {
      display: none; }
    header #menu ul {
      float: right;
      font-size: 16px; }
      @media screen and (max-width: 1200px) {
        header #menu ul {
          font-size: 18px; } }
      @media screen and (max-width: 1373px) {
        header #menu ul {
          font-size: 13px; } }
      header #menu ul.dropdown-menu.domki {
        position: relative;
        height: 180px;
        width: 600px;
        margin-right: -220px;
        background-color: #A28A5C;
        display: none; }
        @media screen and (max-width: 1200px) {
          header #menu ul.dropdown-menu.domki {
            display: block; } }
      header #menu ul li {
        padding-top: 0px;
        padding-right: 0px; }
        header #menu ul li a {
          color: #A28A5C;
          font-weight: bold; }
          header #menu ul li a:hover {
            color: #003030; }
        header #menu ul li ul {
          padding: 0;
          border: none;
          background: #333333; }
          header #menu ul li ul li:hover {
            background-color: rgba(233, 79, 83, 0.3); }
          header #menu ul li ul li {
            background-color: #4D4C4C;
            padding-top: 8px;
            padding-bottom: 8px; }
            header #menu ul li ul li a {
              color: #FFF !important;
              font-size: 1em; }

.wood-footer-container {
  background: url("/media/images/woodfooter.jpg");
  height: 462px;
  text-align: center;
  padding: 45px 0;
  color: white;
  -webkit-box-shadow: inset 0px 210px 96px -178px rgba(0, 0, 0, 0.8), inset 0px -210px 96px -178px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: inset 0px 210px 96px -178px rgba(0, 0, 0, 0.8), inset 0px -210px 96px -178px rgba(0, 0, 0, 0.8);
  box-shadow: inset 0px 210px 96px -178px rgba(0, 0, 0, 0.8), inset 0px -210px 96px -178px rgba(0, 0, 0, 0.8); }
  .wood-footer-container img.wood-footer-upper-shadow {
    margin-top: -45px;
    position: absolute;
    left: 0; }
  @media screen and (max-width: 992px) {
    .wood-footer-container {
      height: 2000px; } }
  @media screen and (max-width: 480px) {
    .wood-footer-container .wood-logo {
      width: 277px; } }
  .wood-footer-container .wood-title {
    font-family: "ChaparralPro-Bold";
    font-size: 32px;
    font-style: italic;
    text-shadow: 0px 1px 7px #000000;
    line-height: 32px;
    margin-top: 32px;
    margin-bottom: 10px; }
  .wood-footer-container .address {
    font-family: "OpenSans-Bold";
    font-size: 20px;
    margin-bottom: 10px; }
  .wood-footer-container a, .wood-footer-container p {
    color: white;
    font-size: 16px;
    font-family: 'OpenSans-Bold'; }
    .wood-footer-container a.phone, .wood-footer-container p.phone {
      font-size: 20px; }
    .wood-footer-container a span, .wood-footer-container p span {
      font-size: 20px; }
  .wood-footer-container .mail-icon {
    margin-bottom: 26px; }
  .wood-footer-container .phone-icon {
    margin-bottom: 14px; }
  .wood-footer-container .contact-col {
    margin-top: 20px; }

footer {
  font-family: lato !important;
  position: relative; }
  footer .wood-footer-container {
    background: url("/media/images/woodfooter.jpg");
    height: 462px;
    text-align: center;
    padding: 45px 0;
    color: white; }
    footer .wood-footer-container img.wood-footer-upper-shadow {
      margin-top: -45px;
      position: absolute;
      left: 0; }
    @media screen and (max-width: 992px) {
      footer .wood-footer-container {
        height: auto; } }
    @media screen and (max-width: 480px) {
      footer .wood-footer-container .wood-logo {
        width: 277px; } }
    footer .wood-footer-container .title {
      font-family: "ChaparralPro-Bold";
      font-size: 32px;
      font-style: italic;
      text-shadow: 0px 1px 7px #000000;
      line-height: 32px;
      margin-top: 32px;
      margin-bottom: 10px; }
    footer .wood-footer-container .address {
      font-family: "OpenSans-Bold";
      font-size: 20px; }
    footer .wood-footer-container a, footer .wood-footer-container p {
      color: white;
      font-size: 16px;
      font-family: 'OpenSans-Bold'; }
    footer .wood-footer-container .mail-icon {
      margin-bottom: 26px; }
    footer .wood-footer-container .phone-icon {
      margin-bottom: 14px; }
    footer .wood-footer-container .contact-col {
      margin-top: 20px; }
  footer .house-select-container {
    background: #003030;
    height: 383px;
    border-bottom: solid #001818 2px;
    font-family: 'ChaparralPro-Bold';
    -webkit-box-shadow: 0px -16px 30px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px -16px 30px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px -16px 30px 0px rgba(0, 0, 0, 0.75); }
    @media screen and (max-width: 480px) {
      footer .house-select-container {
        height: auto; } }
    footer .house-select-container .container {
      display: block; }
      footer .house-select-container .container .sub {
        font-family: 'OpenSans-Bold';
        font-size: 24px;
        color: #A28A5C;
        display: inline-block; }
    footer .house-select-container .house-select-title {
      text-align: center;
      font-size: 45px;
      font-weight: bold;
      font-style: italic;
      color: #A18A5B;
      padding: 50px 30px; }
      @media screen and (max-width: 768px) {
        footer .house-select-container .house-select-title {
          font-size: 40px; } }
      @media screen and (max-width: 647px) {
        footer .house-select-container .house-select-title {
          font-size: 30px; } }
    footer .house-select-container .house {
      cursor: pointer;
      display: inline-block;
      padding-left: 15px;
      padding-right: 15px;
      color: white; }
      footer .house-select-container .house .blockB {
        background-image: url("/media/images/footerB_inactive.png");
        width: 110px;
        height: 157px;
        margin: 0 auto; }
        footer .house-select-container .house .blockB:hover, footer .house-select-container .house .blockB.active {
          background-image: url("/media/images/footerB_active.png"); }
      footer .house-select-container .house .blockA {
        background-image: url("/media/images/footerA_inactive.png");
        width: 82px;
        height: 158px;
        margin: 0 auto; }
        footer .house-select-container .house .blockA:hover, footer .house-select-container .house .blockA.active {
          background-image: url("/media/images/footerA_active.png"); }
      footer .house-select-container .house .blockC {
        background-image: url("/media/images/footerC_inactive.png");
        width: 192px;
        height: 158px;
        margin: 0 auto; }
        footer .house-select-container .house .blockC:hover, footer .house-select-container .house .blockC.active {
          background-image: url("/media/images/footerC_active.png"); }
      @media screen and (max-width: 480px) {
        footer .house-select-container .house {
          display: block;
          margin-bottom: 50px; } }
      footer .house-select-container .house:hover {
        color: #A18A5B; }
      @media all and (max-width: 458px) {
        footer .house-select-container .house.houseC {
          margin-top: 17px; } }
    footer .house-select-container .text-center {
      position: relative; }
      footer .house-select-container .text-center .spacer {
        width: 1px !important;
        height: 148px;
        background-color: black;
        display: inline-block;
        padding: 0; }
        @media screen and (max-width: 1200px) {
          footer .house-select-container .text-center .spacer {
            position: absolute;
            bottom: 0; } }
        @media screen and (max-width: 480px) {
          footer .house-select-container .text-center .spacer {
            display: none; } }
  footer .menu-footer-container {
    background: #003030;
    line-height: 119px;
    text-align: center; }
    @media screen and (max-width: 1200px) {
      footer .menu-footer-container {
        display: none; } }
    footer .menu-footer-container a {
      padding: 0 15px;
      font-family: 'OpenSans-Bold';
      font-size: 16px;
      color: #A28A5C; }
      footer .menu-footer-container a.active, footer .menu-footer-container a:hover {
        color: white; }
  footer .copyright-container {
    background: #001818;
    color: #225750;
    font-family: 'OpenSans-Regular';
    font-size: 16px;
    padding-top: 30px; }
    footer .copyright-container span {
      font-family: 'OpenSans-Bold'; }
    footer .copyright-container a {
      color: #225750;
      text-decoration: none; }
  footer .liniaFooter {
    clear: both;
    height: 25px;
    width: 100%;
    border-bottom: #33464D solid 1px;
    background: #001820; }
  footer .navigation {
    position: fixed;
    right: 30px;
    z-index: 999;
    opacity: 0.5;
    cursor: pointer; }
  footer #toHome {
    bottom: 20px; }
  footer #toTop {
    bottom: 20px;
    display: none; }
  footer .tooltip.top .tooltip-arrow {
    border-top-color: #E94F53; }
  footer .tooltip.top .tooltip-inner {
    color: #E94F53;
    background: #FFF;
    border: 1px solid #E94F53;
    border-radius: 0 !important; }
  footer .navigation:hover {
    opacity: 1; }
  footer #map2 {
    width: 100%;
    height: 550px;
    -webkit-box-shadow: inset 0px 13px 41px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: inset 0px 13px 41px 0px rgba(0, 0, 0, 0.75);
    box-shadow: inset 0px 13px 41px 0px rgba(0, 0, 0, 0.75); }
  footer .mapElement .txt {
    position: absolute;
    z-index: 10;
    background-image: url("/media/images/map.png");
    width: 639px;
    height: 240px;
    top: 200px;
    padding-left: 290px;
    padding-top: 30px; }
    footer .mapElement .txt .el1 {
      font-size: 36px;
      color: #E94F53;
      letter-spacing: -1px;
      font-family: 'FuturaLTPro-Book'; }
    footer .mapElement .txt .el2 {
      color: #444444;
      font-size: 20px;
      line-height: 30px; }
  footer .mapElementContact .txt {
    display: none;
    position: absolute;
    z-index: 10;
    background-image: url("/media/images/map_contact.png");
    width: 639px;
    height: 240px;
    top: 200px;
    padding-left: 290px;
    padding-top: 30px; }
    footer .mapElementContact .txt .el1 {
      font-size: 36px;
      color: #E94F53;
      letter-spacing: -1px; }
    footer .mapElementContact .txt .el2 {
      color: #444444;
      font-size: 20px;
      line-height: 30px; }
  footer #aboveMap {
    display: block; }
  footer #aboveMapContact {
    display: none; }
    footer #aboveMapContact a {
      display: inherit;
      margin: 0 auto; }
      footer #aboveMapContact a img {
        display: inherit;
        margin: 0 auto; }
        @media screen and (max-width: 480px) {
          footer #aboveMapContact a img {
            width: 100%; } }
  footer .content {
    width: 100%;
    background: #001820;
    text-align: center;
    margin: auto;
    padding-top: 120px; }
    footer .content .sprite-logo_footer {
      margin-top: 15px; }
    footer .content .row {
      margin-bottom: 40px; }
    footer .content .contact {
      text-align: left; }
      footer .content .contact .tel {
        float: left; }
        footer .content .contact .tel span {
          float: left; }
        footer .content .contact .tel a {
          font-size: 2.1em;
          color: #FFF;
          float: left;
          padding-left: 15px; }
      footer .content .contact .mail {
        clear: both;
        float: left;
        margin-top: 30px;
        margin-left: 15px; }
        footer .content .contact .mail span {
          float: left; }
        footer .content .contact .mail a {
          font-size: 1.4em;
          color: #FFF;
          padding-left: 15px; }
    footer .content .formContact a {
      margin-top: 15px;
      border: #E44E52 solid 1px;
      border-radius: 45px;
      color: #E44E52;
      font-size: 1.85em;
      padding: 25px;
      font-family: 'Lato'; }
    footer .content .formContact a:hover {
      color: #333;
      background: #E44E52; }
  footer .menuFooter {
    max-width: 1400px;
    width: 100%;
    margin: auto;
    margin-top: -120px;
    padding: 10px; }
    footer .menuFooter .head {
      color: #008AC1;
      font-size: 1.85em;
      text-align: left;
      font-family: 'FuturaLTPro-Book'; }
    footer .menuFooter .body {
      text-align: left;
      margin-top: 3px;
      margin-bottom: 50px;
      line-height: 40px; }
      footer .menuFooter .body a {
        display: inline;
        padding-right: 33px;
        color: #878787;
        word-wrap: inherit;
        white-space: nowrap;
        text-transform: uppercase; }
      footer .menuFooter .body a.active {
        color: #FFF; }
      footer .menuFooter .body a:hover {
        color: #FFF; }
  footer .Copyright {
    color: #878787;
    margin-top: 55px; }
    footer .Copyright a {
      color: #878787;
      display: inline;
      padding-left: 15px;
      margin-left: 15px;
      border-left: 1px solid #878787;
      font-size: 15px; }
    footer .Copyright div {
      display: inline;
      font-size: 15px; }
  footer .execution {
    margin-top: 40px;
    padding-bottom: 50px; }
    footer .execution a {
      display: inline-table; }
  footer #conMail {
    position: fixed;
    bottom: 150px;
    right: 10px;
    z-index: 10; }
    footer #conMail .head {
      display: none;
      width: 290px;
      height: 53px;
      background: #EBEBEB;
      position: absolute;
      right: 5px;
      top: 6px;
      border-radius: 85px;
      -webkit-box-shadow: 0px 0px 51px -2px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 0px 51px -2px rgba(0, 0, 0, 0.75);
      box-shadow: 0px 0px 51px -2px rgba(0, 0, 0, 0.75); }
    footer #conMail .sprit {
      position: absolute;
      right: -5px;
      top: -6px; }
    footer #conMail a {
      font-size: 16px;
      text-align: center;
      font-family: 'lato';
      font-weight: bold;
      color: #333;
      padding-left: 16px;
      line-height: 50px; }
  footer #conMail.active .head {
    display: block; }
  footer #phone {
    position: fixed;
    bottom: 80px;
    right: 10px;
    z-index: 10; }
    footer #phone .head {
      display: none;
      width: 290px;
      height: 53px;
      background: #EBEBEB;
      position: absolute;
      right: 5px;
      top: 6px;
      border-radius: 85px;
      -webkit-box-shadow: 0px 0px 51px -2px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 0px 51px -2px rgba(0, 0, 0, 0.75);
      box-shadow: 0px 0px 51px -2px rgba(0, 0, 0, 0.75); }
    footer #phone .sprit {
      position: absolute;
      right: -5px;
      top: -6px; }
    footer #phone a {
      font-size: 26px;
      text-align: center;
      font-family: 'lato';
      font-weight: bold;
      color: #333;
      padding-left: 55px;
      line-height: 50px; }
  footer #phone.active .head {
    display: block; }

.nav-pills > li.active > div.licontainer > a, .nav-pills > li.active > div.licontainer > a:hover, .nav-pills > li.active > div.licontainer > a:focus, .nav-pills li > a:hover {
  background: no-repeat !important;
  border: none !important;
  text-decoration: none !important;
  color: #003030 !important; }
  @media screen and (max-width: 1200px) {
    .nav-pills > li.active > div.licontainer > a, .nav-pills > li.active > div.licontainer > a:hover, .nav-pills > li.active > div.licontainer > a:focus, .nav-pills li > a:hover {
      color: white !important; } }

.nav-pills > li.active > a {
  font-weight: 600; }

.nav-pills li > a {
  border: none !important; }

@media screen and (min-width: 1200px) {
  header #menuHeight {
    height: 50px;
    margin-right: 15px; }
  header #search.fixed {
    position: fixed;
    top: 10px !important;
    z-index: 20;
    -webkit-box-shadow: 0px 7px 14px -5px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0px 7px 14px -5px rgba(0, 0, 0, 0.14);
    box-shadow: 0px 7px 14px -5px rgba(0, 0, 0, 0.14); }
  header #search {
    display: none;
    width: 100%;
    background: #FFF;
    position: relative;
    text-align: center;
    margin: auto;
    color: #333;
    font-size: 4.7em;
    margin-top: 40px; }
    header #search .closeSearch {
      position: absolute;
      right: 25px;
      top: 0;
      cursor: pointer; }
    header #search .closeSearch:hover {
      background-position: -259px -98px; }
    header #search input.txt {
      border: none;
      margin-top: 2px;
      background: transparent;
      width: 80%;
      color: #333;
      text-align: center; }
    header #search button {
      display: block;
      text-align: center;
      margin: auto;
      margin-top: 55px;
      width: 330px;
      height: 80px;
      background: #E94F53;
      color: #FFF;
      font-size: 0.4em;
      font-family: lato;
      border-radius: 85px;
      line-height: 1px; }
      header #search button span {
        font-size: 0.8em; }
    header #search div.txt {
      color: #333;
      font-size: 0.22em;
      margin-top: 15px;
      padding-bottom: 80px; }
  header .belka {
    display: none;
    position: fixed;
    background-color: #FFF;
    width: 100%;
    z-index: 999;
    top: 0;
    -webkit-box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.28);
    -moz-box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.28);
    box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.28); }
    header .belka .belkarow {
      margin-right: 15px; }
    header .belka .sprite-logo_header_small {
      width: inherit !important;
      display: inline-block !important; }
    header .belka .left {
      margin-top: 7px;
      margin-left: 60px;
      margin-bottom: 7px; }
      header .belka .left #menu2, header .belka .left ul, header .belka .left ul > li > a {
        display: inline-block;
        float: right;
        color: #A28A5C !important;
        font-size: 1em;
        font-weight: bold; }
      header .belka .left #menu2, header .belka .left ul, header .belka .left ul > li.active > a {
        display: inline-block;
        float: right;
        color: #001818 !important;
        font-size: 1em;
        font-weight: bold; }
    header .belka .right {
      top: 10px;
      right: 50px; }
    header .belka .dropdown-menu.domki {
      display: none; }
  header .belka.openMenu {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important; }
  header #menu.trans, header #menu2.trans {
    transition: all 0.5s; }
  header #menu.openMenu {
    position: fixed;
    top: 52px;
    z-index: 10;
    -webkit-box-shadow: 0px 7px 14px -5px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0px 7px 14px -5px rgba(0, 0, 0, 0.14);
    box-shadow: 0px 7px 14px -5px rgba(0, 0, 0, 0.14); } }

@media screen and (max-width: 767px) {
  .belka #menubutton.down.top {
    right: 0px; }
  .belka #menubutton.down.top.openMenu {
    right: 440px; }
  footer .Copyright a, footer .Copyright div {
    border: 0;
    display: block;
    margin-bottom: 10px; }
  footer #toHome {
    display: none; }
  footer #toTop {
    bottom: 10px;
    right: -30px;
    opacity: 1; }
  footer .contac {
    margin: 40px auto; }
    footer .contac .left {
      border-right: none !important;
      border-bottom: 1px solid #bbb;
      padding: 30px 0;
      text-align: center;
      padding-bottom: 70px !important;
      margin-bottom: 40px; }
      footer .contac .left .sprite {
        margin: auto; }
    footer .contac .right {
      padding-left: 0;
      text-align: center; } }

@media screen and (max-width: 1200px) {
  header #menu ul li ul.dropdown-menu.domki {
    border-radius: none; }
  header #search {
    display: none; }
  header .right {
    display: none; }
  header .top .sprite-logo_header {
    margin: 10px auto; }
  header .topMenu {
    display: block;
    color: #003030;
    font-size: 49px;
    margin-left: 49px;
    font-family: 'ChaparralPro-BoldIt'; }
  header .searchMenu {
    display: block;
    background-color: #F5F5F5;
    -webkit-box-shadow: inset 91px 3px 58px -74px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: inset 91px 3px 58px -74px rgba(0, 0, 0, 0.75);
    box-shadow: inset 91px 3px 58px -74px rgba(0, 0, 0, 0.75);
    position: relative; }
    header .searchMenu input {
      width: 300px;
      z-index: 0;
      border: none;
      height: 75px;
      font-size: 2em;
      background-color: #F5F5F5;
      margin-left: 75px; } }
    @media screen and (max-width: 1200px) and (max-width: 480px) {
      header .searchMenu input {
        width: 195px; } }

@media screen and (max-width: 1200px) {
    header .searchMenu span {
      position: absolute;
      top: 24px;
      left: 23px; }
  header .belka {
    display: hidden; }
    header .belka #menubutton {
      position: fixed;
      right: 40px;
      top: 25px; }
    header .belka #menubutton.down {
      width: 100px;
      height: 100px;
      background: url("/media/images/menu1.png"); }
    header .belka #menubutton.down.top {
      top: 10px; }
    header .belka .left {
      display: none; }
    header .belka .right {
      display: block; }
      header .belka .right .row {
        display: none; }
  header #menu {
    overflow: auto;
    overflow-x: hidden;
    position: fixed;
    background-color: #FFF;
    right: 0;
    top: 0;
    width: 0;
    height: 100%; }
    header #menu ul {
      float: left;
      padding-left: 0;
      width: 100%;
      width: 400px;
      /*dodane*/ }
      header #menu ul li {
        background-color: transparent !important;
        color: #333;
        width: 100%;
        border-top: 1px solid #EBEBEB; }
        header #menu ul li ul {
          margin-left: -35px;
          -webkit-box-shadow: inset 91px 3px 58px -74px rgba(0, 0, 0, 0.75);
          -moz-box-shadow: inset 91px 3px 58px -74px rgba(0, 0, 0, 0.75);
          box-shadow: inset 91px 3px 58px -74px rgba(0, 0, 0, 0.75);
          background-color: #F5F5F5;
          width: 400px;
          margin-bottom: 15px; }
          header #menu ul li ul.dropdown-menu.domki {
            position: relative;
            height: auto;
            z-index: 5;
            box-shadow: none;
            display: none; }
            header #menu ul li ul.dropdown-menu.domki .domek-side-menu {
              border-top: solid 1px white;
              padding: 20px 50px; } }
            @media screen and (max-width: 1200px) and (max-width: 1200px) {
              header #menu ul li ul.dropdown-menu.domki .domek-side-menu {
                padding: 20px 50px; } }

@media screen and (max-width: 1200px) {
              header #menu ul li ul.dropdown-menu.domki .domek-side-menu #blockB {
                background-image: url("/media/images/domekb_side_menu.png");
                width: 110px;
                height: 133px;
                margin-left: 24px; }
                header #menu ul li ul.dropdown-menu.domki .domek-side-menu #blockB:hover, header #menu ul li ul.dropdown-menu.domki .domek-side-menu #blockB.picked {
                  background-image: url("/media/images/domekb_side_menu_active.png");
                  width: 113px; }
              header #menu ul li ul.dropdown-menu.domki .domek-side-menu #blockA {
                background-image: url("/media/images/domeka_side_menu.png");
                width: 87px;
                height: 145px;
                margin-left: 24px; }
                header #menu ul li ul.dropdown-menu.domki .domek-side-menu #blockA:hover, header #menu ul li ul.dropdown-menu.domki .domek-side-menu #blockA.picked {
                  background-image: url("/media/images/domeka_side_menu_active.png"); }
              header #menu ul li ul.dropdown-menu.domki .domek-side-menu #blockC {
                background-image: url("/media/images/domekc_side_menu.png");
                width: 192px;
                height: 161px;
                margin-left: 24px; }
                header #menu ul li ul.dropdown-menu.domki .domek-side-menu #blockC:hover, header #menu ul li ul.dropdown-menu.domki .domek-side-menu #blockC.picked {
                  background-image: url("/media/images/domekc_side_menu_active.png");
                  width: 195px; }
          header #menu ul li ul li {
            margin-left: 40px; }
        header #menu ul li ul li a {
          color: #333 !important;
          width: 240px;
          padding-top: 10px;
          padding-bottom: 10px;
          font-size: 0.9em;
          white-space: normal; }
          header #menu ul li ul li a .glyphicon {
            display: none; }
        header #menu ul li ul li.subactive a {
          color: #FF4C4C !important; }
    header #menu .lang {
      float: right;
      display: block; }
      header #menu .lang div a {
        float: left;
        margin: 20px 10px; }
  header #menu.openMenu {
    width: 400px; }
  header #menubutton.openMenu {
    right: 440px; }
  header #menubutton.openIcon.top {
    background-image: url("/media/images/close1.png"); }
  body.openMenu {
    margin-left: -400px;
    overflow: hidden; }
  body {
    width: 100%; }
  footer .mapElement {
    display: none; }
  footer .mapElementContact {
    display: none; }
  footer .content .contact a {
    font-size: 30px !important; }
  footer .content .contact .mail a {
    font-size: 20px !important; }
  footer #toTop.openMenu, footer #toHome.openMenu, footer #phone.openMenu, footer #conMail.openMenu {
    right: 420px; }
  footer .contac {
    margin: 40px auto; }
    footer .contac .left {
      border-right: 1px solid #bbb;
      padding: 30px 0;
      text-align: center; }
      footer .contac .left .sprite {
        margin: auto; }
    footer .contac .right {
      padding-left: 10%; }
      footer .contac .right .el1 {
        font-size: 36px;
        color: #E94F53; }
      footer .contac .right .el2 {
        color: #333;
        font-size: 20px; } }

@media screen and (max-width: 480px) {
  header #menubutton.openMenu {
    left: -40px; }
  header .top .sprite-logo_header {
    margin: 10px 10px; }
  header #menu.openMenu {
    width: 222px; }
  header .belka #menubutton.down.top.openMenu {
    right: 330px; }
  header ul {
    padding-left: 5px; }
    header ul a {
      margin-right: -40px; }
    header ul li ul {
      margin-left: -5px;
      width: 300px; }
      header ul li ul li {
        margin-left: 10px; }
  footer #toTop.openMenu, footer #toHome.openMenu, footer #phone.openMenu, footer #conMail.openMenu {
    right: 310px; }
  footer .content .sprite-logo_footer {
    text-align: center;
    margin: auto; }
  footer .content .contact {
    margin: auto;
    text-align: center; }
  footer .formContact {
    padding: 0; }
  footer .menuFooter .body a {
    display: block !important;
    width: 100%;
    text-align: center; }
  footer .menuFooter .head {
    display: block !important;
    text-align: center; }
  body.openMenu {
    margin-left: -222px; } }

@media screen and (max-width: 365px) {
  footer .content .contact .tel a {
    font-size: 24px !important; }
  footer .content .contact .mail a {
    font-size: 18px !important; } }

@media screen and (max-width: 767px) {
  header .top {
    background: white;
    padding: 0; }
  footer .contac .right {
    padding: 0; } }

@media screen and (max-width: 992px) {
  footer .content .sprite-logo_footer {
    margin: auto; }
  footer .content .row {
    margin: 20px auto; } }

@media screen and (max-width: 1053px) and (min-width: 993px) {
  header #menu ul li {
    padding-right: 11px; } }

@media screen and (max-width: 480px) {
  footer .menuFooter .body a {
    padding-right: 0; } }

.rules {
  background-color: white;
  height: auto; }
  .rules .rules-title {
    color: #003030;
    font-size: 36px;
    font-family: 'ChaparralPro-BoldIt';
    text-align: center;
    margin-top: 56px; }
    @media screen and (max-width: 480px) {
      .rules .rules-title {
        font-size: 27px; } }
  .rules .rules-container {
    margin-top: 82px;
    margin-bottom: 100px;
    counter-reset: step-counter; }
    .rules .rules-container .rules-column {
      padding: 0 0; }
      .rules .rules-container .rules-column ol.custom-counter {
        margin: 0;
        padding: 0; }
        .rules .rules-container .rules-column ol.custom-counter li.custom-li {
          counter-increment: step-counter;
          margin-bottom: 5px;
          padding: 0 6%;
          padding-left: 60px;
          list-style: none;
          font-size: 20px;
          color: #003030;
          font-family: 'OpenSans-Regular';
          line-height: 21px;
          margin-top: 0;
          margin-bottom: 46px; }
          @media screen and (max-width: 480px) {
            .rules .rules-container .rules-column ol.custom-counter li.custom-li {
              padding-left: 45px; } }
          .rules .rules-container .rules-column ol.custom-counter li.custom-li::before {
            content: counter(step-counter) ".";
            margin-right: 20px;
            font-family: 'ChaparralPro-BoldIt';
            font-size: 48px;
            color: #2693FF;
            padding: 3px 8px;
            border-radius: 11px;
            float: left;
            height: 67px; }
          .rules .rules-container .rules-column ol.custom-counter li.custom-li.more-margin::before {
            content: counter(step-counter) ".";
            margin-right: 11px;
            font-family: 'ChaparralPro-BoldIt';
            font-size: 48px;
            color: #2693FF;
            padding: 3px 8px;
            border-radius: 11px;
            float: left;
            width: 83px;
            text-align: right; }
          .rules .rules-container .rules-column ol.custom-counter li.custom-li span {
            font-family: 'OpenSans-ExtraBold';
            color: #003030; }
          .rules .rules-container .rules-column ol.custom-counter li.custom-li ul {
            padding-left: 129px; }
            .rules .rules-container .rules-column ol.custom-counter li.custom-li ul li {
              padding-left: 0;
              font-size: 30px;
              color: #003030;
              margin: 17px 0;
              list-style: disc;
              line-height: 19px; }
              .rules .rules-container .rules-column ol.custom-counter li.custom-li ul li span.for-ul {
                padding-left: 0;
                font-size: 20px;
                color: #003030;
                margin: 20px 0;
                list-style: disc;
                line-height: 21px;
                font-family: 'OpenSans-Regular'; }
                .rules .rules-container .rules-column ol.custom-counter li.custom-li ul li span.for-ul span {
                  font-family: 'OpenSans-ExtraBold';
                  color: #003030; }
              .rules .rules-container .rules-column ol.custom-counter li.custom-li ul li::before {
                content: none; }
              .rules .rules-container .rules-column ol.custom-counter li.custom-li ul li span {
                font-family: 'OpenSans-Bold';
                color: #003030; }
      .rules .rules-container .rules-column .payment-info {
        border: solid 2px #003030;
        height: auto;
        margin: 0 8%;
        margin-top: -13px;
        padding: 42px 8%; }
        .rules .rules-container .rules-column .payment-info .no-top-margin {
          margin-top: 0; }
        .rules .rules-container .rules-column .payment-info .account-number {
          margin-top: 30px; }
        .rules .rules-container .rules-column .payment-info .iban {
          margin-top: 32px; }
        .rules .rules-container .rules-column .payment-info .foreign {
          margin-top: 54px; }
        .rules .rules-container .rules-column .payment-info .swift-code {
          margin-top: 26px; }
        .rules .rules-container .rules-column .payment-info h1 {
          font-family: 'OpenSans-ExtraBold';
          font-size: 20px;
          margin-bottom: 0px;
          color: #003030; }
        .rules .rules-container .rules-column .payment-info h2 {
          font-family: 'OpenSans-Regular';
          font-size: 20px;
          margin-bottom: 0px;
          color: #003030; }
          .rules .rules-container .rules-column .payment-info h2.more-space-above {
            margin-top: 50px; }

#booking-widget {
  font-size: 14px;
  position: fixed;
  right: -19px;
  top: 18.4%;
  width: 202px;
  height: 191px;
  z-index: 999;
  background-image: url("/media/images/booking-widget.png");
  color: white;
  font-family: 'OpenSans-Bold';
  text-align: center;
  cursor: pointer; }
  @media screen and (max-width: 768px) {
    #booking-widget {
      display: none; } }
  #booking-widget .grade {
    margin-top: 40px;
    margin-left: 41px;
    font-size: 49px; }
    #booking-widget .grade .gradeof {
      display: inline-block;
      font-size: 17px;
      color: #99DEF8; }
  #booking-widget .grade-text {
    font-size: 17px;
    font-family: 'OpenSans-ExtraBold';
    margin-left: 27px;
    margin-top: -8px; }
  #booking-widget .opinion-count {
    color: #99DEF8;
    font-size: 9px;
    margin-left: 26px; }

#bookingicon {
  font-size: 14px;
  top: -79px;
  left: 509px;
  width: 298px;
  height: 293px;
  z-index: 1;
  background-image: url("/media/images/bookingonproduct.png");
  color: white;
  font-family: 'OpenSans-Bold';
  text-align: center;
  position: absolute; }
  @media screen and (max-width: 768px) {
    #bookingicon {
      display: none; } }
  #bookingicon .grade {
    margin-top: 40px;
    margin-left: -39px;
    font-size: 73px; }
    #bookingicon .grade .gradeof {
      display: inline-block;
      font-size: 17px;
      color: #99DEF8; }
  #bookingicon .grade-text {
    font-size: 23px;
    font-family: 'OpenSans-ExtraBold';
    margin-left: -49px;
    margin-top: -8px; }
  #bookingicon .opinion-count {
    color: #99DEF8;
    font-size: 15px;
    margin-left: -47px; }

li .licontainer {
  margin: 0 20px;
  margin-top: 25px;
  margin-bottom: 25px; }
  @media screen and (max-width: 1854px) {
    li .licontainer {
      margin-left: 7px;
      margin-right: 7px;
      font-size: 12px; } }
  li .licontainer a {
    text-decoration: none !important; }

@media screen and (max-width: 1200px) {
  li .licontainer {
    margin: 0 0;
    background-color: transparent;
    padding: 10px 0px;
    margin-left: -35px;
    padding-left: 73px; }
  li.active .licontainer {
    background-color: #043333; }
    li.active .licontainer a {
      color: white !important; }
  .nav-stacked > li + li {
    margin-top: 0; } }

#menu2 .licontainer {
  margin: 0 11px;
  font-size: 13px;
  margin-top: 10px;
  margin-bottom: 17px; }
  #menu2 .licontainer a {
    color: #A28A5C; }
    #menu2 .licontainer a:hover {
      color: #003030; }

.sprite-go_to_top {
  background-image: url("/media/images/arrow_to_top.png");
  background-repeat: no-repeat;
  height: 131px;
  width: 131px; }

.newsletter-button {
  transition: all 0.5s ease;
  display: inline-block;
  padding: 12px 15px;
  line-height: 56px;
  font-family: 'OpenSans-Bold';
  font-size: 17px;
  cursor: pointer;
  height: 56px;
  margin-bottom: 30px; }
  .newsletter-button.folded {
    transition: all 0.2s ease;
    border: 2px solid #A28A5C;
    background-color: #001818;
    color: #A28A5C; }
    .newsletter-button.folded:before {
      content: attr(data-fold);
      transition: all 0.5s ease;
      position: relative;
      top: -12px; }
    .newsletter-button.folded:after {
      content: "›";
      display: inline-block;
      transition: all 0.5s ease;
      position: relative;
      top: -14px;
      -webkit-transform: scale(1, 1.5);
      -moz-transform: scale(1, 1.5);
      -o-transform: scale(1, 1.5);
      -ms-transform: scale(1, 1.5);
      transform: scale(1, 1.5); }
    .newsletter-button.folded:hover {
      transition: all 0.2s ease;
      color: #001818;
      background-color: #A28A5C; }
  .newsletter-button.unfolded {
    transition: all 0.2s ease;
    border: 2px solid #A28A5C;
    background-color: #A28A5C;
    color: #001818; }
    .newsletter-button.unfolded:before {
      content: attr(data-unfold);
      transition: all 0.5s ease;
      position: relative;
      top: -12px; }
    .newsletter-button.unfolded:after {
      content: "›";
      display: inline-block;
      -webkit-transform: scale(1, 1.5);
      -moz-transform: scale(1, 1.5);
      -o-transform: scale(1, 1.5);
      -ms-transform: scale(1, 1.5);
      transform: scale(1, 1.5);
      transition: all 0.5s ease;
      position: relative;
      top: -12px; }
    .newsletter-button.unfolded:hover {
      transition: all 0.2s ease;
      color: #001818;
      background-color: #A28A5C; }

.dropdown-backdrop {
  z-index: 1; }

#owl-carousel-galeria {
  padding-bottom: 40px; }

.sprite {
  background-image: url("/media/images/spritesheet.png");
  background-repeat: no-repeat;
  display: block; }

.sprite-optimal {
  width: 105px;
  height: 14px;
  background-image: url("/media/images/optimal.png") !important; }

.sprite-logo_header_small {
  width: 108px;
  height: 42px;
  background-image: url("/media/images/logo_header_small.png") !important; }

#categorry {
  font-family: 'Lato'; }
  #categorry .owl .item img {
    display: block;
    width: 100%;
    height: auto; }
  #categorry .line {
    width: 100px;
    height: 6px;
    background: #008AC2;
    margin: auto; }
  #categorry .top {
    width: 100%;
    max-width: 1920px;
    height: 338px;
    text-align: center;
    margin: auto;
    background-position: center; }
    #categorry .top .offert {
      font-size: 36px;
      color: #EBEBEB;
      padding-top: 70px; }
    #categorry .top .title {
      font-size: 66px;
      line-height: 63px;
      color: #FFF; }
    #categorry .top .line {
      margin-top: 25px; }
  #categorry .productsContainer {
    padding-top: 110px;
    max-width: 1500px;
    width: 100%;
    margin: auto; }
    #categorry .productsContainer .productsList {
      margin-bottom: 30px;
      font-size: 20px;
      color: #333;
      cursor: pointer;
      text-decoration: underline; }
      @media screen and (max-width: 992px) {
        #categorry .productsContainer .productsList {
          text-align: center; } }
    #categorry .productsContainer .productsList:hover {
      color: #008AC2; }
  #categorry .clear {
    clear: both; }
  #categorry h2 {
    font-family: 'FuturaLTPro-Book';
    font-weight: 100;
    text-align: center;
    margin-top: 60px;
    color: #333;
    font-size: 66px;
    margin-bottom: 50px; }
  #categorry #offertsList {
    margin-top: 100px; }
    #categorry #offertsList .product .background {
      width: 100%;
      height: 530px; }
      #categorry #offertsList .product .background .top {
        position: absolute;
        width: 100%;
        overflow: hidden;
        height: 100%; }
        #categorry #offertsList .product .background .top .back {
          position: absolute;
          width: 100%;
          height: 530px; }
        #categorry #offertsList .product .background .top img {
          position: absolute; }
      #categorry #offertsList .product .background .top.topLeft .back {
        background: url("/media/images/pasek-flip.png") center; }
      #categorry #offertsList .product .background .top.topLeft img {
        right: 0; }
      #categorry #offertsList .product .background .top.topRight .back {
        background: url("/media/images/pasek.png") center; }
      #categorry #offertsList .product .background .top.topRight img {
        left: 0; }
    #categorry #offertsList .product .background.noImage {
      background: #EBEBEB;
      height: 250px; }
      #categorry #offertsList .product .background.noImage .title {
        padding-top: 65px; }
      #categorry #offertsList .product .background.noImage .image img {
        margin-top: -40px; }
    #categorry #offertsList .product .title {
      padding-top: 125px;
      display: table;
      height: 60px; }
      #categorry #offertsList .product .title h3 {
        display: table-cell;
        vertical-align: middle;
        color: #333;
        font-size: 26px;
        padding-left: 25px;
        font-family: 'FuturaLTPro-Book'; }
    #categorry #offertsList .product .image img {
      width: 95%;
      max-height: 650px;
      position: absolute;
      z-index: 1;
      margin-top: 250px;
      -webkit-box-shadow: -21px 21px 52px 0px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: -21px 21px 52px 0px rgba(0, 0, 0, 0.75);
      box-shadow: -21px 21px 52px 0px rgba(0, 0, 0, 0.75); }
    #categorry #offertsList .product .description {
      width: 100%;
      background: #34495E;
      position: relative;
      min-height: 450px; }
      #categorry #offertsList .product .description .txt {
        padding: 60px 25px;
        font-size: 20px;
        color: #FFF; }
      #categorry #offertsList .product .description ul {
        list-style: none;
        /* Remove list bullets */
        padding: 0;
        margin: 0;
        margin-bottom: -20px;
        margin-left: 15px; }
      #categorry #offertsList .product .description li {
        padding-left: 5px;
        margin-bottom: 20px;
        list-style: url("/media/images/liWhite.png"); }
    #categorry #offertsList .product .longDescription {
      font-size: 18px;
      color: #333;
      padding-top: 20px;
      padding-bottom: 50px; }
      #categorry #offertsList .product .longDescription .paddingbox {
        padding-left: 40px;
        padding-right: 40px; }
      #categorry #offertsList .product .longDescription .scroll {
        overflow: scroll; }
      #categorry #offertsList .product .longDescription li {
        white-space: normal; }
      #categorry #offertsList .product .longDescription .table1 td {
        padding-right: 20px;
        padding-top: 10px;
        padding-bottom: 10px; }
      #categorry #offertsList .product .longDescription .table1 .border-bottom {
        border-bottom: 1px solid #BBB; }
      #categorry #offertsList .product .longDescription .tabdescription {
        text-align: center;
        color: #333;
        margin: 20px auto;
        font-size: 36px;
        font-family: 'FuturaLTPro-Book';
        padding: 0 10px; }
        @media screen and (max-width: 480px) {
          #categorry #offertsList .product .longDescription .tabdescription {
            font-size: 30px; } }
      #categorry #offertsList .product .longDescription table.table2 thead td {
        background: #008AC2;
        font-family: 'FuturaLTPro-Book';
        font-size: 36px;
        color: #FFF;
        text-align: center; }
      #categorry #offertsList .product .longDescription table.table2 thead td:nth-child(1) {
        background-color: #34495E; }
      #categorry #offertsList .product .longDescription table.table2 tbody td {
        background: #E5F3F9;
        padding: 40px 50px; }
      #categorry #offertsList .product .longDescription table.table2 tbody td:nth-child(1) {
        background: #EAECEF; }
      #categorry #offertsList .product .longDescription table.table2 tbody tr {
        border-bottom: 1px solid #BBB; }
      #categorry #offertsList .product .longDescription h4 {
        margin-top: 40px;
        font-size: 26px;
        color: #333;
        margin-bottom: 30px;
        font-weight: bold; }
      #categorry #offertsList .product .longDescription ul {
        list-style: none;
        /* Remove list bullets */
        padding: 0;
        margin: 0;
        margin-bottom: -10px;
        margin-left: 22px; }
      #categorry #offertsList .product .longDescription li {
        padding-left: 1px;
        margin-bottom: 10px;
        list-style: url("/media/images/li.png"); }
    #categorry #offertsList .product .gallery {
      background-color: #34495E; }
      #categorry #offertsList .product .gallery .title {
        color: #FFF;
        font-size: 36px;
        padding: 80px 10px;
        padding-bottom: 40px;
        font-family: 'FuturaLTPro-Book'; }
      @media screen and (min-width: 1500px) {
        #categorry #offertsList .product .gallery .photos {
          margin-left: 12px; } }
      #categorry #offertsList .product .gallery .photos img {
        padding-bottom: 50px;
        opacity: 0.5; }
      #categorry #offertsList .product .gallery .photos img:hover {
        opacity: 1; }
      #categorry #offertsList .product .gallery .photos .owl-buttons {
        margin-top: -30px;
        margin-bottom: 80px; }
        #categorry #offertsList .product .gallery .photos .owl-buttons .owl-prev, #categorry #offertsList .product .gallery .photos .owl-buttons .owl-next {
          font-size: 0;
          width: 53px;
          height: 53px;
          background: url("/media/images/navigation.png") no-repeat;
          padding: 0 40px;
          margin-top: -40px; }
        #categorry #offertsList .product .gallery .photos .owl-buttons .owl-next {
          -ms-transform: rotate(180deg);
          /* IE 9 */
          -webkit-transform: rotate(180deg);
          /* Chrome, Safari, Opera */
          transform: rotate(180deg); }
  #categorry .foot {
    width: 100%;
    background: url("/media/images/background/foot.jpg") top center;
    text-align: center;
    position: relative; }
    #categorry .foot .in {
      padding: 20vw 15px; }
      #categorry .foot .in a {
        background: #E94F53;
        padding: 25px;
        border-radius: 55px;
        font-size: 25px;
        color: #FFF; }
      #categorry .foot .in .txt {
        margin-bottom: 80px;
        font-family: 'FuturaLTPro-Book';
        font-size: 36px;
        color: #FFF; }
        @media screen and (max-width: 992px) {
          #categorry .foot .in .txt {
            font-size: 28px; } }

@media screen and (max-width: 992px) {
  #categorry .top .offert {
    font-size: 26px; }
  #categorry .top .title {
    font-size: 36px;
    line-height: 36px; }
  #categorry #offertsList .product .background.noImage .title {
    margin: auto; }
  #categorry #offertsList .product .image img {
    width: 100%;
    margin-top: 150px !important;
    position: relative !important; }
  #categorry #offertsList .product .description p {
    margin-bottom: 50px; }
  #categorry #offertsList .product .description .txt {
    padding-right: 50px;
    padding-top: 10px; }
    #categorry #offertsList .product .description .txt .paddingTop {
      margin-top: 100px; } }

@media screen and (max-width: 480px) {
  #categorry #offertsList .product .gallery .title {
    padding-bottom: 0; }
  .top .offert {
    font-size: 20px !important; }
  .top .title {
    font-size: 30px !important; }
  #categorry #offertsList .product .title h3 {
    display: block;
    text-align: center;
    padding: 0; }
  #categorry #offertsList .product .title .sprite {
    text-align: center;
    margin: auto;
    -ms-transform: rotate(90deg);
    /* IE 9 */
    -webkit-transform: rotate(90deg);
    /* Chrome, Safari, Opera */
    transform: rotate(90deg); }
  .gallery .container-fluid {
    padding: 0; }
  #categorry .foot .in a {
    font-size: 20px; } }

@media screen and (max-width: 768px) {
  #categorry #offertsList .product .gallery .photos img {
    opacity: 1; } }

@media screen and (min-width: 480px) {
  #categorry #offertsList .product .background.noImage .title {
    padding-top: 90px; } }

.descriptionInMap {
  max-width: 200px; }
  .descriptionInMap .nameOfMarker {
    font-size: 16px;
    font-family: 'OpenSans-ExtraBold';
    color: #003030;
    text-align: center;
    margin-bottom: 10px; }
  .descriptionInMap .descriptionOfMarker {
    font-size: 15px;
    font-family: 'OpenSans-Regular';
    color: #003030;
    text-align: center;
    margin-bottom: 10px; }
  .descriptionInMap p {
    text-align: center;
    margin-bottom: 0; }
    .descriptionInMap p .seeongoogle {
      text-decoration: underline;
      color: #2693FF;
      font-family: 'OpenSans-ExtraBold';
      font-size: 16px; }

.benefits-container {
  background-image: url("/media/images/homepage/benefits.jpg");
  background-size: cover;
  height: 970px;
  padding: 0 4%; }
  @media screen and (max-width: 1701px) {
    .benefits-container {
      padding: 0 1%; } }
  @media screen and (max-width: 1200px) {
    .benefits-container {
      padding: 0 5%; } }
  .benefits-container .blue-button-container {
    margin-top: 15px; }
  .benefits-container.in-product {
    height: 1369px; }
    @media screen and (max-width: 1200px) {
      .benefits-container.in-product {
        height: auto; } }
    .benefits-container.in-product .blue-button {
      margin-top: 100px; }
      @media screen and (max-width: 1200px) {
        .benefits-container.in-product .blue-button {
          margin-top: 50px;
          margin-bottom: 50px; } }
      @media screen and (max-width: 768px) {
        .benefits-container.in-product .blue-button {
          margin-top: 100px;
          margin-bottom: 0; } }
      @media screen and (max-width: 480px) {
        .benefits-container.in-product .blue-button {
          width: 290px; } }
    .benefits-container.in-product .title p {
      margin-top: 206px; }
      @media screen and (max-width: 1200px) {
        .benefits-container.in-product .title p {
          margin-top: 40px; } }
    .benefits-container.in-product .title .subtitle {
      margin-top: 0; }
  @media all and (max-width: 992px) {
    .benefits-container {
      height: auto; } }
  @media all and (max-width: 768px) {
    .benefits-container {
      height: auto;
      padding-bottom: 100px; } }
  @media screen and (max-width: 1200px) {
    .benefits-container .blue-button {
      margin-top: 50px;
      margin-bottom: 50px; } }
  @media screen and (max-width: 768px) {
    .benefits-container .blue-button {
      margin-top: 100px;
      margin-bottom: 0; } }
  .benefits-container .title {
    margin-top: 0px !important;
    text-align: center; }
    .benefits-container .title p {
      color: white;
      font-family: 'ChaparralPro-BoldIt';
      font-size: 63px;
      margin-top: 61px;
      margin-bottom: 0px; }
      @media screen and (max-width: 480px) {
        .benefits-container .title p {
          font-size: 50px; } }
    .benefits-container .title .subtitle {
      color: #A18A5B;
      font-size: 40px;
      font-family: 'ChaparralPro-Italic';
      line-height: 5px;
      font-weight: 100;
      margin-top: 0; }
  .benefits-container .benefit {
    margin-bottom: -61px;
    position: relative; }
    @media all and (max-width: 768px) {
      .benefits-container .benefit {
        display: none; } }
    .benefits-container .benefit .text {
      position: absolute;
      color: white;
      font-size: 20px;
      font-family: 'OpenSans-Light';
      text-align: center;
      width: 298px;
      left: 32%;
      top: 75px; }
      @media screen and (max-width: 1200px) {
        .benefits-container .benefit .text {
          left: 36%; } }
      @media screen and (max-width: 992px) {
        .benefits-container .benefit .text {
          left: 31%; } }
      @media screen and (min-width: 1200px) and (max-width: 1556px) {
        .benefits-container .benefit .text.left-text {
          left: 11%; } }
      @media screen and (min-width: 1200px) and (max-width: 1556px) {
        .benefits-container .benefit .text.right-text {
          left: 43%; } }
      .benefits-container .benefit .text .bold {
        font-family: 'OpenSans-Bold'; }
  .benefits-container .benefit-mobile {
    display: none;
    margin-top: 50px; }
    @media all and (max-width: 768px) {
      .benefits-container .benefit-mobile {
        display: block; } }
    .benefits-container .benefit-mobile .text {
      color: white;
      font-size: 20px;
      font-family: 'OpenSans-Light';
      text-align: center;
      padding: 0 5%; }
      .benefits-container .benefit-mobile .text .bold {
        font-family: 'OpenSans-Bold'; }

.nopadding {
  padding: 0; }

.nomargin {
  margin: 0; }

.button-arrow {
  vertical-align: middle; }

.padding404 {
  padding: 0px 22vw; }

.title404 {
  font-size: 4vw !important;
  line-height: 4.5vw; }
  @media screen and (max-width: 768px) {
    .title404 {
      font-size: 6vw !important;
      line-height: 6vw; } }

.blue-button-container {
  text-align: center; }
  .blue-button-container .blue-button {
    background-color: #2693FF;
    width: 311px;
    height: 56px;
    text-align: center;
    display: inline-block;
    font-size: 17px;
    color: white;
    font-family: 'OpenSans-Bold';
    line-height: 56px;
    cursor: pointer;
    transition: background-color 0.5s; }
    .blue-button-container .blue-button:hover {
      background-color: #46A3FF; }

.button-find-out-more {
  display: block !important;
  background: #E94F53;
  width: 330px;
  height: 78px;
  border-radius: 39px;
  margin: 0 auto;
  font-size: 26px;
  color: white;
  text-align: center;
  line-height: 70px;
  margin-bottom: 80px;
  transition: all 1s; }
  @media screen and (min-width: 1200px) {
    .button-find-out-more.rise-up:hover {
      margin-top: 60px;
      margin-bottom: 145px;
      -webkit-box-shadow: 0px 12px 5px 0px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 12px 5px 0px rgba(0, 0, 0, 0.75);
      box-shadow: 0px 12px 5px 0px rgba(0, 0, 0, 0.75); } }
  @media screen and (max-width: 480px) {
    .button-find-out-more {
      width: 280px; } }
  .button-find-out-more.meet-the-team {
    margin-top: 150px; }
    @media screen and (max-width: 1200px) {
      .button-find-out-more.meet-the-team {
        margin-top: 60px; } }
    @media screen and (max-width: 992px) {
      .button-find-out-more.meet-the-team {
        margin-top: 151px; } }
    @media screen and (min-width: 992px) {
      .button-find-out-more.meet-the-team {
        margin-top: 115px; } }
    @media screen and (max-width: 768px) {
      .button-find-out-more.meet-the-team {
        margin-top: 151px; } }

.line {
  background: #2693FF;
  height: 6px;
  margin-top: 33px; }

.line-in-offers {
  background: #2693FF;
  height: 6px;
  margin-top: 33px; }

.line-our-value {
  margin-right: 60%;
  margin-left: 30px;
  margin-top: 100px; }
  @media screen and (min-width: 480px) and (max-width: 767px) {
    .line-our-value {
      margin-top: 33px; } }
  @media screen and (min-width: 768px) {
    .line-our-value {
      margin-top: 120px; } }
  @media screen and (max-width: 480px) {
    .line-our-value {
      margin-top: 120px; } }
  @media screen and (max-width: 355px) {
    .line-our-value {
      margin-top: 166px; } }
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    .line-our-value {
      margin-top: 20px; } }

.margintop150 {
  margin-top: 150px; }
  @media screen and (min-width: 1200px) {
    .margintop150 {
      margin-top: 100px; } }
  @media screen and (max-width: 768px) {
    .margintop150 {
      margin-top: 190px; } }

#text-on-slider-container {
  font-family: 'FuturaLTPro-Book';
  position: relative;
  width: 100%; }
  @media screen and (max-width: 768px) {
    #text-on-slider-container {
      display: none; } }
  #text-on-slider-container #text-on-slider {
    position: absolute;
    font-size: 3.5vw;
    color: white;
    width: 69%;
    height: 300px;
    z-index: 1;
    top: 20vw;
    left: 16.6%; }

#scroll-down-button-container {
  position: relative;
  width: 100%;
  z-index: 2; }
  @media screen and (max-width: 992px) {
    #scroll-down-button-container {
      display: none; } }
  #scroll-down-button-container #scroll-down-button {
    position: absolute;
    top: -40px;
    left: 49.6%;
    cursor: pointer; }

/* Wobble Horizontal */
@-webkit-keyframes hvr-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px); }
  33.3% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px); }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px); }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px); }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes hvr-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px); }
  33.3% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px); }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px); }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px); }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

.hvr-wobble-horizontal {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale; }

.hvr-wobble-horizontal:hover, .hvr-wobble-horizontal:focus, .hvr-wobble-horizontal:active {
  -webkit-animation-name: hvr-wobble-horizontal;
  animation-name: hvr-wobble-horizontal;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1; }

/* Wobble Vertical */
@-webkit-keyframes hvr-wobble-vertical {
  16.65% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px); }
  33.3% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px); }
  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px); }
  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px); }
  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes hvr-wobble-vertical {
  16.65% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px); }
  33.3% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px); }
  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px); }
  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px); }
  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

.hvr-wobble-vertical {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale; }

.hvr-wobble-vertical:hover, .hvr-wobble-vertical:focus, .hvr-wobble-vertical:active {
  -webkit-animation-name: hvr-wobble-vertical;
  animation-name: hvr-wobble-vertical;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1; }

/* Float Shadow */
.hvr-float-shadow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  -webkit-transition-duration: 0.3s;
  -ms-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -ms-transform: transform; }

.hvr-float-shadow:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: '';
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 0;
  background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.35) 0%, transparent 80%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, transparent 80%);
  /* W3C */
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -ms-transform: 0.3s;
  -webkit-transition-property: transform, opacity;
  transition-property: transform, opacity;
  -ms-transform: transform, opacity; }

.hvr-float-shadow:hover, .hvr-float-shadow:focus, .hvr-float-shadow:active {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  /* move the element up by 5px */ }

.hvr-float-shadow:hover:before, .hvr-float-shadow:focus:before, .hvr-float-shadow:active:before {
  opacity: 1;
  -webkit-transform: translateY(5px);
  transform: translateY(5px);
  -ms-transform: translateY(-5px);
  /* move the element down by 5px (it will stay in place because it's attached to the element that also moves up 5px) */ }

.owl-caption {
  position: absolute;
  font-size: 44px;
  color: #003030;
  top: 20vw;
  left: 16.6%;
  width: 69%;
  font-family: 'ChaparralPro-BoldIt';
  text-align: center;
  padding: 32px 49px; }
  @media screen and (max-width: 768px) {
    .owl-caption {
      background-color: white;
      width: 100%;
      left: 0;
      position: relative;
      top: 0;
      height: 36vw;
      font-size: 6vw;
      padding: 4vw;
      text-align: center;
      left: 0 !important;
      top: 0 !important;
      height: auto; } }
  @media screen and (max-width: 480px) {
    .owl-caption {
      font-size: 32px; } }
  .owl-caption.caption1 {
    top: 12vw;
    left: 53.7%; }
    @media screen and (max-width: 1200px) {
      .owl-caption.caption1 {
        top: 3vw;
        left: 29.7%; } }
  .owl-caption.caption2 {
    top: 12vw;
    left: 12.5%; }
  .owl-caption.caption3 {
    top: 12vw;
    left: 34.6%;
    line-height: 41px; }
    @media screen and (max-width: 768px) {
      .owl-caption.caption3 {
        line-height: 27px; } }

.homepage .map-container {
  background-image: url("/media/images/homepage/map2.jpg");
  background-size: cover;
  height: 970px;
  background-position-x: 50%; }
  @media screen and (max-width: 1200px) and (min-width: 992px) {
    .homepage .map-container {
      background-size: 100% 1016px; } }
  .homepage .map-container .blue-button-container {
    margin-top: 85px; }
  .homepage .map-container .title {
    margin-top: 0px; }
    .homepage .map-container .title p {
      color: #231605;
      font-family: 'ChaparralPro-BoldIt';
      font-size: 70px;
      margin-top: 47px;
      margin-bottom: 0px; }
    .homepage .map-container .title .subtitle {
      color: white;
      font-size: 40px;
      font-family: 'ChaparralPro-Italic';
      line-height: 5px; }
      @media screen and (max-width: 418px) {
        .homepage .map-container .title .subtitle {
          font-size: 26px; } }
  .homepage .map-container .map-paper {
    background-image: url("/media/images/homepage/mappaper.png");
    background-repeat: no-repeat;
    height: 609px; }

.homepage .szczyrk-container {
  background-image: url("/media/images/homepage/szczyrk.jpg");
  height: 1180px; }
  @media screen and (max-width: 768px) {
    .homepage .szczyrk-container {
      height: auto; } }
  .homepage .szczyrk-container .info {
    -webkit-box-shadow: 10px 10px 29px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 29px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 10px 10px 29px 0px rgba(0, 0, 0, 0.75);
    background-image: url("/media/images/homepage/snowy-background.jpg");
    height: 882px;
    width: 661px;
    padding: 0 55px;
    margin-top: 153px;
    margin-left: 8.6%;
    margin-bottom: 84px;
    padding-top: 70px; }
    @media screen and (max-width: 768px) {
      .homepage .szczyrk-container .info {
        margin-left: 0;
        margin-top: 0px;
        width: inherit;
        background-size: cover;
        background-size: 100% 100%;
        height: auto;
        padding-bottom: 50px;
        margin-bottom: -1px;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none; } }
    @media screen and (max-width: 480px) {
      .homepage .szczyrk-container .info {
        background-image: none;
        background-color: white; } }
    .homepage .szczyrk-container .info .title {
      margin-top: 0px;
      margin-bottom: 83px; }
      .homepage .szczyrk-container .info .title p {
        color: #003030;
        font-family: 'ChaparralPro-BoldIt';
        font-size: 64px;
        margin-bottom: 0px; }
        @media all and (max-width: 393px) {
          .homepage .szczyrk-container .info .title p.szczyrk-title {
            font-size: 50px; } }
      .homepage .szczyrk-container .info .title .subtitle {
        color: #726140;
        font-size: 37px;
        font-family: 'ChaparralPro-Italic';
        line-height: 16px; }
        @media screen and (max-width: 768px) {
          .homepage .szczyrk-container .info .title .subtitle {
            font-size: 28px; } }
        @media screen and (max-width: 480px) {
          .homepage .szczyrk-container .info .title .subtitle {
            font-size: 32px;
            line-height: 30px; } }
      .homepage .szczyrk-container .info .title h1 {
        font-family: 'ChaparralPro-BoldIt';
        font-size: 28px;
        margin-top: 62px;
        color: #003030; }
        .homepage .szczyrk-container .info .title h1.wypoczynek {
          margin-top: 34px; }
        .homepage .szczyrk-container .info .title h1.tutaj {
          margin-top: 32px; }
      .homepage .szczyrk-container .info .title h2 {
        color: #726140;
        font-family: 'OpenSans-Regular';
        font-size: 20px;
        line-height: 30px;
        margin-top: -3px; }
    .homepage .szczyrk-container .info .blue-button {
      height: 59px;
      width: 226px; }
      @media all and (max-width: 480px) {
        .homepage .szczyrk-container .info .blue-button {
          width: 220px; } }

.homepage .nopadding {
  padding: 0; }

.homepage .nomargin {
  margin: 0; }

.homepage .link-for-button {
  text-decoration: none; }

.homepage .title {
  font-size: 5vw;
  text-align: center;
  margin-top: 100px;
  font-family: 'FuturaLTPro-Book';
  font-weight: 100; }
  @media screen and (max-width: 1200px) {
    .homepage .title {
      font-size: 66px; } }
  @media screen and (max-width: 480px) {
    .homepage .title {
      font-size: 16vw; } }

.homepage .slider {
  position: relative;
  background: url("/media/images/homepage/slider_image1.png");
  background-repeat: no-repeat;
  height: 944px; }

.homepage .blue-bar {
  height: 23px;
  background: #2693FF; }
  @media screen and (max-width: 768px) {
    .homepage .blue-bar {
      display: none; } }

.homepage .blue-bar-with-text {
  display: none;
  background: #2693FF;
  color: white;
  font-size: 25px;
  padding: 33px;
  text-align: center; }
  @media screen and (max-width: 768px) {
    .homepage .blue-bar-with-text {
      display: block; } }

.homepage .blue-triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 52px 56px 0 56px;
  border-color: #2693FF transparent transparent transparent;
  margin: 0 auto; }

.homepage .statistics-container {
  margin-left: 70px;
  margin-bottom: 100px;
  margin-top: 80px; }
  @media screen and (max-width: 768px) {
    .homepage .statistics-container {
      margin: 0;
      margin-bottom: 100px; } }
  .homepage .statistics-container .statistics {
    border-bottom: solid 8px #2693FF; }
    @media screen and (max-width: 992px) {
      .homepage .statistics-container .statistics {
        margin-top: 100px;
        margin-left: 33px; } }
    .homepage .statistics-container .statistics .over {
      font-size: 21px;
      color: #333333;
      white-space: nowrap;
      margin-bottom: 10px; }
      @media screen and (max-width: 992px) {
        .homepage .statistics-container .statistics .over {
          font-size: 5vw; } }
    .homepage .statistics-container .statistics .big-text {
      font-family: 'FuturaLTPro-Bold';
      color: #2693FF;
      font-weight: bolder;
      float: left;
      font-size: 8vw;
      margin-top: -30px; }
      @media screen and (max-width: 992px) {
        .homepage .statistics-container .statistics .big-text {
          font-size: 23vw;
          margin-top: -20px; } }
      @media screen and (min-width: 992px) {
        .homepage .statistics-container .statistics .big-text .small-text {
          font-size: 2.4vw; } }
      @media screen and (max-width: 992px) {
        .homepage .statistics-container .statistics .big-text .small-text {
          font-size: 11vw; } }
    .homepage .statistics-container .statistics .thin-text {
      font-size: 2.5vw;
      font-family: "Lato";
      color: #333333;
      font-weight: 100;
      line-height: 2.5vw;
      margin-top: -15px;
      margin-bottom: 2.5vw; }
      @media screen and (max-width: 992px) {
        .homepage .statistics-container .statistics .thin-text {
          font-size: 46px;
          line-height: 64px; } }
      @media screen and (max-width: 768px) {
        .homepage .statistics-container .statistics .thin-text {
          font-size: 9vw;
          line-height: 9vw; } }

.homepage .products-and-services {
  background-image: url("/media/images/homepage/productsandservices.jpg");
  background-color: #EBEBEB;
  background-size: 100% 100%;
  height: 1000px;
  color: #333333; }
  @media screen and (max-width: 1200px) {
    .homepage .products-and-services {
      background-image: url("/media/images/homepage/productsandservices_medium.jpg");
      height: 1650px; } }
  @media screen and (max-width: 767px) {
    .homepage .products-and-services {
      background-image: url("/media/images/homepage/productsandservices_medium.jpg");
      height: 3380px; } }
  @media screen and (max-width: 480px) {
    .homepage .products-and-services {
      background-image: url("/media/images/homepage/productsandservices_mobile_new.jpg");
      height: 3400px; } }
  .homepage .products-and-services .selected {
    opacity: 0.35;
    cursor: default !important; }
  @media screen and (width: 768px) {
    .homepage .products-and-services.error404 {
      height: 1830px; } }
  @media screen and (max-width: 767px) {
    .homepage .products-and-services.error404 {
      height: 3600px; } }
  @media screen and (max-width: 480px) {
    .homepage .products-and-services.error404 {
      height: 3400px; } }
  .homepage .products-and-services .clouds .cloud .products {
    height: 100px;
    background: url("/media/images/homepage/cloud_products.png");
    background-repeat: no-repeat;
    margin-left: -15px; }
    @media screen and (max-width: 480px) {
      .homepage .products-and-services .clouds .cloud .products {
        background: url("/media/images/homepage/cloud_products_mobile.png");
        height: 66px;
        width: 220px;
        margin-left: -16%; } }
  .homepage .products-and-services .clouds .cloud .services {
    height: 100px;
    background: url("/media/images/homepage/cloud_services.png");
    background-repeat: no-repeat;
    margin-left: -14px; }
    @media screen and (max-width: 480px) {
      .homepage .products-and-services .clouds .cloud .services {
        background: url("/media/images/homepage/cloud_services_mobile.png");
        height: 66px;
        width: 220px;
        margin-left: -16%; } }
  .homepage .products-and-services .margintop {
    margin-top: 112px; }
  .homepage .products-and-services .products {
    margin-left: 3%; }
    @media screen and (max-width: 480px) {
      .homepage .products-and-services .products {
        margin-left: -11%; } }
    .homepage .products-and-services .products .first-half-in-offers {
      padding: 0 11.3%; }
      @media screen and (max-width: 1200px) {
        .homepage .products-and-services .products .first-half-in-offers {
          padding: 0 21.3%; } }
      @media screen and (max-width: 768px) {
        .homepage .products-and-services .products .first-half-in-offers {
          padding: 0; } }
    .homepage .products-and-services .products .image {
      height: 234px;
      overflow: hidden; }
      .homepage .products-and-services .products .image img {
        width: 100%; }
        @media screen and (max-width: 768px) {
          .homepage .products-and-services .products .image img {
            max-width: 291px; } }
    .homepage .products-and-services .products .text {
      color: #333333;
      font-size: 25px;
      font-family: 'Lato';
      font-weight: 900;
      position: relative;
      height: 80px;
      margin-left: 15px; }
      @media screen and (max-width: 992px) {
        .homepage .products-and-services .products .text {
          font-size: 26px; } }
      @media screen and (max-width: 768px) {
        .homepage .products-and-services .products .text {
          font-size: 27px; } }
      @media screen and (max-width: 480px) {
        .homepage .products-and-services .products .text {
          font-size: 25px; } }
      .homepage .products-and-services .products .text .text-at-bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        transition: all 0.5s; }
    .homepage .products-and-services .products .product {
      height: 390px;
      border-bottom: solid 7px #333333;
      cursor: pointer;
      -webkit-transition: all 0.5s;
      transition: all 0.5s; }
      .homepage .products-and-services .products .product .grow {
        transition: all 0.5s ease-in-out; }
      .homepage .products-and-services .products .product:hover .grow {
        transform: scale(1.5); }
      .homepage .products-and-services .products .product:hover .text-at-bottom-hover {
        color: #2693FF; }
      .homepage .products-and-services .products .product:hover.hoverable {
        border-bottom: solid 7px #2693FF; }
      @media screen and (max-width: 768px) {
        .homepage .products-and-services .products .product {
          margin-top: 50px; } }
      .homepage .products-and-services .products .product .number {
        font-size: 36px;
        color: #2693FF;
        border: solid 3px #2693FF;
        background: #EBEBEB;
        margin-left: 15px;
        width: 64px; }
    .homepage .products-and-services .products .service {
      height: 390px;
      border-bottom: solid 7px #333333;
      cursor: pointer;
      -webkit-transition: all 0.5s;
      transition: all 0.5s; }
      .homepage .products-and-services .products .service .grow {
        transition: all 0.5s ease-in-out; }
      .homepage .products-and-services .products .service:hover .grow {
        transform: scale(1.5); }
      .homepage .products-and-services .products .service:hover .text-at-bottom-hover {
        color: #2693FF; }
      .homepage .products-and-services .products .service:hover.hoverable {
        border-bottom: solid 7px #2693FF; }
      @media screen and (max-width: 768px) {
        .homepage .products-and-services .products .service {
          margin-top: 50px; } }
      .homepage .products-and-services .products .service .number {
        font-size: 36px;
        color: #EBEBEB;
        border: solid 3px #2693FF;
        background: #2693FF;
        margin-left: 15px;
        width: 64px; }
    .homepage .products-and-services .products .secondhalf {
      margin-top: 0px; }
      @media screen and (max-width: 1200px) {
        .homepage .products-and-services .products .secondhalf {
          margin-top: 50px; } }

.homepage .our-values {
  background: url("/media/images/homepage/ourvalues.jpg");
  background-size: cover;
  height: 1140px;
  color: white;
  background-size: 100% 100%; }
  @media screen and (max-width: 1200px) {
    .homepage .our-values {
      height: 1940px; } }
  @media screen and (max-width: 992px) {
    .homepage .our-values {
      background-image: url("/media/images/homepage/ourvalues_tablet.jpg"); } }
  @media screen and (max-width: 767px) {
    .homepage .our-values {
      height: 3740px;
      background-image: url("/media/images/homepage/ourvalues_mobile.jpg"); } }
  @media screen and (max-width: 480px) {
    .homepage .our-values {
      height: 3518px; } }
  .homepage .our-values .our-values-container {
    margin-left: 2%;
    margin-top: 90px; }
    @media screen and (max-width: 767px) {
      .homepage .our-values .our-values-container {
        margin-top: 0; } }
    @media screen and (min-width: 768px) and (max-width: 992px) {
      .homepage .our-values .our-values-container {
        margin-left: 0; } }
    .homepage .our-values .our-values-container .our-value {
      height: 491px;
      -webkit-transition: margin-top 1s;
      transition: margin-top 1s; }
      @media screen and (min-width: 1200px) {
        .homepage .our-values .our-values-container .our-value.selected {
          margin-top: -58px; } }
      @media screen and (max-width: 767px) {
        .homepage .our-values .our-values-container .our-value {
          margin-top: 100px;
          margin-left: 4%; } }
      .homepage .our-values .our-values-container .our-value .title-our-value {
        font-family: 'FuturaLTPro-Book';
        float: left;
        font-size: 1.7vw;
        margin-left: 10px;
        margin-top: 7px;
        margin-bottom: 0;
        color: white; }
        @media screen and (max-width: 1200px) {
          .homepage .our-values .our-values-container .our-value .title-our-value {
            font-size: 24px; } }
        @media screen and (max-width: 767px) {
          .homepage .our-values .our-values-container .our-value .title-our-value {
            font-size: 32px; } }
        @media screen and (max-width: 480px) {
          .homepage .our-values .our-values-container .our-value .title-our-value {
            font-size: 20px; } }
      .homepage .our-values .our-values-container .our-value .image-our-value {
        width: 100%;
        -webkit-box-shadow: -13px 12px 32px 0px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: -13px 12px 32px 0px rgba(0, 0, 0, 0.75);
        box-shadow: -13px 12px 32px 0px rgba(0, 0, 0, 0.75); }
      .homepage .our-values .our-values-container .our-value .text-our-value-container {
        background: white;
        padding-bottom: 50px;
        height: 420px;
        position: relative;
        z-index: 1;
        margin-top: -1px;
        -webkit-box-shadow: -13px 12px 32px 0px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: -13px 12px 32px 0px rgba(0, 0, 0, 0.75);
        box-shadow: -13px 12px 32px 0px rgba(0, 0, 0, 0.75); }
        @media screen and (min-width: 1200px) {
          .homepage .our-values .our-values-container .our-value .text-our-value-container.selected2 {
            display: block;
            height: 414px;
            padding-bottom: 50px; } }
        @media screen and (min-width: 1200px) {
          .homepage .our-values .our-values-container .our-value .text-our-value-container {
            height: 0px;
            padding-bottom: 0px;
            overflow: hidden;
            -webkit-transition: height 1s, padding-bottom 1s;
            transition: height 1s, padding-bottom 1s; } }
        @media screen and (min-width: 992px) and (max-width: 1200px) {
          .homepage .our-values .our-values-container .our-value .text-our-value-container {
            height: 320px; } }
        @media screen and (max-width: 480px) {
          .homepage .our-values .our-values-container .our-value .text-our-value-container {
            height: 420px; } }
        @media screen and (max-width: 355px) {
          .homepage .our-values .our-values-container .our-value .text-our-value-container {
            height: 464px; } }
        .homepage .our-values .our-values-container .our-value .text-our-value-container .text-our-value {
          color: #333333;
          background: white;
          font-size: 20px;
          padding: 30px;
          height: 260px; }
          @media screen and (min-width: 1200px) {
            .homepage .our-values .our-values-container .our-value .text-our-value-container .text-our-value {
              font-size: 1.3vw; } }
          .homepage .our-values .our-values-container .our-value .text-our-value-container .text-our-value .text-bold {
            font-weight: bold; }
    @media screen and (max-width: 767px) {
      .homepage .our-values .our-values-container .our-value-second {
        margin-top: 320px; } }
    @media screen and (max-width: 480px) {
      .homepage .our-values .our-values-container .our-value-second {
        margin-top: 230px; } }
    .homepage .our-values .our-values-container .secondhalf {
      margin-top: 0; }
      @media screen and (max-width: 1200px) {
        .homepage .our-values .our-values-container .secondhalf {
          margin-top: 250px; } }
      @media screen and (max-width: 480px) {
        .homepage .our-values .our-values-container .secondhalf {
          margin-top: 145px; } }

#owl {
  position: relative;
  z-index: 2;
  -webkit-box-shadow: 0px 10px 18px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 18px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 18px 0px rgba(0, 0, 0, 0.75); }

.owl-theme .owl-controls {
  margin-top: 10px;
  text-align: center; }

/* Styling Next and Prev buttons */
.owl-theme .owl-controls .owl-buttons div {
  color: #FFF;
  display: inline-block;
  zoom: 1;
  *display: inline;
  /*IE7 life-saver */
  margin: 5px;
  padding: 3px 10px;
  font-size: 12px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background: #869791;
  filter: Alpha(Opacity=50);
  /*IE7 fix*/
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.5); }

/* Clickable class fix problem with hover on touch devices */
/* Use it for non-touch hover action */
.owl-theme .owl-controls.clickable .owl-buttons div:hover {
  filter: Alpha(Opacity=100);
  /*IE7 fix*/
  opacity: 1;
  text-decoration: none; }

/* Styling Pagination*/
.owl-theme .owl-controls .owl-page {
  display: inline-block;
  zoom: 1;
  *display: inline;
  /*IE7 life-saver */ }

.owl-theme .owl-controls .owl-page span {
  display: block;
  width: 12px;
  height: 12px;
  margin: 5px 7px;
  filter: Alpha(Opacity=50);
  /*IE7 fix*/
  opacity: 1;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  background: #869791; }

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span {
  filter: Alpha(Opacity=100);
  /*IE7 fix*/
  opacity: 1; }

/* If PaginationNumbers is true */
.owl-theme .owl-controls .owl-page span.owl-numbers {
  height: auto;
  width: auto;
  color: #FFF;
  padding: 2px 10px;
  font-size: 12px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px; }

#owl-demo .owl-item > div img {
  display: block;
  width: 100%;
  height: auto;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin-bottom: 4px; }

#owl-demo .owl-item > div {
  background: #42bdc2;
  text-align: center;
  padding: 50px 0px;
  margin: 3px;
  color: white;
  font-size: 32px;
  border: 1px white; }

.wrapper-with-margin {
  margin: 0px 50px; }

.owl-controls {
  margin-bottom: -10px; }

.owl-theme .owl-controls .owl-buttons div {
  position: absolute; }

.owl-theme .owl-controls .owl-buttons .owl-prev {
  left: 30px;
  top: 22vw;
  width: 82px;
  height: 82px;
  border-radius: 41px;
  font-size: 32px;
  opacity: 1;
  background: none; }

.owl-theme .owl-controls .owl-buttons .owl-next {
  right: 30px;
  top: 22vw;
  width: 82px;
  height: 82px;
  border-radius: 41px;
  font-size: 32px;
  opacity: 1;
  background: none; }

@media screen and (max-width: 992px) {
  .owl-theme .owl-controls .owl-buttons .owl-prev {
    display: none; }
  .owl-theme .owl-controls .owl-buttons .owl-next {
    display: none; } }

.newsletter-container {
  background: url("/media/images/newsletter.jpg");
  height: 1286px; }
  @media screen and (max-width: 768px) {
    .newsletter-container {
      height: auto; } }
  .newsletter-container img.newsletter-lower-shadow {
    margin-bottom: -49px;
    position: absolute;
    bottom: 0;
    /* z-index: 1000; */
    left: 0; }
  .newsletter-container .newsletter-box {
    border: solid 7px white;
    height: 775px;
    text-align: center;
    margin-top: 200px;
    width: 640px;
    margin: 0 auto;
    margin-top: 205px;
    padding: 0 2%; }
    @media screen and (max-width: 768px) {
      .newsletter-container .newsletter-box {
        width: 100%;
        margin-top: 0;
        height: auto;
        margin-bottom: 60px;
        padding: 0 5%; } }
    @media screen and (max-width: 480px) {
      .newsletter-container .newsletter-box {
        padding: 0 50px; } }
    .newsletter-container .newsletter-box .border-bottom {
      border-bottom: solid 7px white;
      position: absolute;
      display: block;
      height: 7px;
      margin-top: -28px; }
      .newsletter-container .newsletter-box .border-bottom.left {
        left: 0px;
        width: 25%;
        margin-left: -7px; }
      .newsletter-container .newsletter-box .border-bottom.right {
        right: 0px;
        width: 25%;
        margin-right: -7px; }
    .newsletter-container .newsletter-box.kek:after {
      content: '';
      width: 25%;
      margin-left: -7px;
      margin-top: -28px;
      display: block;
      /* height: 180px; */
      /* width: 100px; */
      position: absolute;
      left: 0px;
      border-bottom: solid 7px white; }
    @media screen and (max-width: 768px) {
      .newsletter-container .newsletter-box {
        border: none; } }
    .newsletter-container .newsletter-box .title {
      margin-top: 0px;
      text-align: center; }
      .newsletter-container .newsletter-box .title p {
        color: white;
        font-family: 'ChaparralPro-BoldIt';
        font-size: 124px;
        margin-top: 43px;
        margin-bottom: 0px;
        line-height: 124px; }
        @media screen and (max-width: 768px) {
          .newsletter-container .newsletter-box .title p {
            font-size: 90px; } }
        @media screen and (max-width: 480px) {
          .newsletter-container .newsletter-box .title p {
            font-size: 62px;
            line-height: 60px; } }
      .newsletter-container .newsletter-box .title .subtitle {
        color: white;
        font-size: 31px;
        font-family: 'ChaparralPro-BoldIt';
        line-height: 5px; }
        @media screen and (max-width: 768px) {
          .newsletter-container .newsletter-box .title .subtitle {
            font-size: 26px; } }
        @media screen and (max-width: 480px) {
          .newsletter-container .newsletter-box .title .subtitle {
            line-height: 30px; } }
      .newsletter-container .newsletter-box .title .blue-text {
        color: #2693FF;
        font-size: 20px;
        margin-bottom: 0;
        font-family: 'OpenSans-BoldItalic';
        line-height: 25px;
        margin-top: 35px; }
    .newsletter-container .newsletter-box .getyourmail {
      color: white;
      font-size: 25px;
      text-align: center;
      margin-top: 47px;
      font-size: 18px; }
    .newsletter-container .newsletter-box form {
      color: white; }
      .newsletter-container .newsletter-box form label {
        width: 67%;
        margin-top: 33px; }
        .newsletter-container .newsletter-box form label.second-label {
          margin-top: 3px; }
      .newsletter-container .newsletter-box form input[type=text] {
        padding: 8px 20px;
        margin: 8px 0;
        box-sizing: border-box;
        background: none;
        border: solid 2px white;
        font-family: 'OpenSans-Italic';
        font-size: 22px;
        width: 309px; }
        .newsletter-container .newsletter-box form input[type=text]:focus {
          outline-width: 0;
          border-color: #2693FF; }
        .newsletter-container .newsletter-box form input[type=text]::placeholder {
          color: white; }
        @media screen and (max-width: 480px) {
          .newsletter-container .newsletter-box form input[type=text] {
            width: 100%; } }
      .newsletter-container .newsletter-box form input[type=checkbox] {
        float: left; }
      .newsletter-container .newsletter-box form label {
        font-family: 'OpenSans-Regular';
        font-size: 10px;
        font-weight: 100; }
      .newsletter-container .newsletter-box form span {
        color: #E94F53;
        font-size: 11px; }
      .newsletter-container .newsletter-box form .blue-button {
        border: none;
        margin-top: 40px; }
        @media screen and (max-width: 480px) {
          .newsletter-container .newsletter-box form .blue-button {
            width: 170px;
            height: 71px;
            line-height: 36px; } }

.selection-container {
  background-color: white;
  height: auto; }
  .selection-container .title {
    margin-top: 0px;
    padding: 0 40px; }
    .selection-container .title p {
      color: #003030;
      font-family: 'ChaparralPro-BoldIt';
      font-size: 70px;
      margin-top: 47px;
      margin-bottom: 0px;
      text-align: center; }
      @media screen and (max-width: 768px) {
        .selection-container .title p {
          font-size: 50px;
          line-height: 55px; } }
    .selection-container .title .subtitle {
      color: #726140;
      font-size: 40px;
      font-family: 'ChaparralPro-Italic';
      line-height: 5px;
      text-align: center; }
      @media screen and (max-width: 768px) {
        .selection-container .title .subtitle {
          margin-top: 20px; } }
  .selection-container .house-container {
    margin-top: 100px; }
    .selection-container .house-container .house-b {
      background-image: url("/media/images/homepage/1a.png");
      background-repeat: no-repeat;
      height: 800px;
      position: relative;
      padding-top: 518px;
      transition: background-image 0.5s;
      -webkit-transition: background-image 0.5s;
      -moz-transition: background-image 0.5s;
      -o-transition: background-image 0.5s;
      -ms-transition: background-image 0.5s;
      background-position-x: 50%; }
      @media screen and (max-width: 1650px) and (min-width: 1200px) {
        .selection-container .house-container .house-b {
          background-size: 100%; } }
      @media screen and (max-width: 1200px) {
        .selection-container .house-container .house-b {
          background-image: url("/media/images/homepage/1b.png"); } }
      @media screen and (max-width: 480px) {
        .selection-container .house-container .house-b {
          background-size: 410px; } }
      .selection-container .house-container .house-b .blue-button {
        background-color: #2693FF;
        width: 250px;
        height: 56px;
        text-align: center;
        font-size: 17px;
        color: white;
        font-family: 'OpenSans-Bold';
        line-height: 56px;
        cursor: pointer;
        display: block;
        margin: 0 auto; }
        @media all and (max-width: 441px) {
          .selection-container .house-container .house-b .blue-button {
            width: 279px; } }
      .selection-container .house-container .house-b:hover {
        background-image: url("/media/images/homepage/1b.png"); }
    .selection-container .house-container .house-a {
      background-image: url("/media/images/homepage/2a.png");
      background-repeat: no-repeat;
      height: 800px;
      position: relative;
      padding-top: 518px;
      transition: background-image 0.5s;
      -webkit-transition: background-image 0.5s;
      -moz-transition: background-image 0.5s;
      -o-transition: background-image 0.5s;
      -ms-transition: background-image 0.5s;
      background-position-x: 50%; }
      @media screen and (max-width: 1400px) and (min-width: 1200px) {
        .selection-container .house-container .house-a {
          background-size: 100%; } }
      @media screen and (max-width: 1200px) {
        .selection-container .house-container .house-a {
          background-image: url("/media/images/homepage/2b.png"); } }
      @media screen and (max-width: 480px) {
        .selection-container .house-container .house-a {
          background-size: 410px; } }
      .selection-container .house-container .house-a .blue-button {
        background-color: #2693FF;
        width: 250px;
        height: 56px;
        text-align: center;
        font-size: 17px;
        color: white;
        font-family: 'OpenSans-Bold';
        line-height: 56px;
        cursor: pointer;
        display: block;
        margin: 0 auto;
        display: block;
        margin: 0 auto; }
        @media all and (max-width: 441px) {
          .selection-container .house-container .house-a .blue-button {
            width: 279px; } }
      .selection-container .house-container .house-a:hover {
        background-image: url("/media/images/homepage/2b.png"); }
    .selection-container .house-container .house-c {
      background-image: url("/media/images/homepage/3a.png");
      background-repeat: no-repeat;
      height: 800px;
      position: relative;
      padding-top: 518px;
      transition: background-image 0.5s;
      -webkit-transition: background-image 0.5s;
      -moz-transition: background-image 0.5s;
      -o-transition: background-image 0.5s;
      -ms-transition: background-image 0.5s;
      background-position-x: 50%; }
      @media screen and (max-width: 1400px) and (min-width: 1200px) {
        .selection-container .house-container .house-c {
          background-size: 100%; } }
      @media screen and (max-width: 1200px) {
        .selection-container .house-container .house-c {
          background-image: url("/media/images/homepage/3b.png"); } }
      @media screen and (max-width: 480px) {
        .selection-container .house-container .house-c {
          background-size: 410px; } }
      .selection-container .house-container .house-c .blue-button {
        background-color: #2693FF;
        width: 250px;
        height: 56px;
        text-align: center;
        font-size: 17px;
        color: white;
        font-family: 'OpenSans-Bold';
        line-height: 56px;
        cursor: pointer;
        display: block;
        margin: 0 auto;
        display: block;
        margin: 0 auto; }
        @media all and (max-width: 441px) {
          .selection-container .house-container .house-c .blue-button {
            width: 279px; } }
      .selection-container .house-container .house-c:hover {
        background-image: url("/media/images/homepage/3b.png"); }

.mapaRealizacji {
  margin-bottom: -10px; }
  .mapaRealizacji .featureTextContainer {
    background-color: white;
    text-align: center; }
    .mapaRealizacji .featureTextContainer .featureText {
      padding-top: 5vw;
      font-size: 66px; }
      .mapaRealizacji .featureTextContainer .featureText strong {
        color: #008AC2; }
  .mapaRealizacji .mapContainer {
    padding: 0;
    height: 60vw; }
    .mapaRealizacji .mapContainer .mapTitle {
      background-color: #008AC2;
      width: 100%;
      height: 10vw;
      line-height: 10vw;
      text-align: center; }
      .mapaRealizacji .mapContainer .mapTitle p {
        font-size: 2vw;
        color: white;
        margin: 0;
        font-family: 'FuturaLTPro-Book'; }
  .mapaRealizacji ul {
    margin: 100px 0; }
    .mapaRealizacji ul li {
      font-size: 30px; }

@media screen and (max-width: 768px) {
  .mapaRealizacji .featureTextContainer .featureText {
    font-size: 20px !important; } }

@media screen and (max-width: 992px) {
  .mapaRealizacji .featureTextContainer .featureText {
    font-size: 40px !important; } }

.svg-flex-inner {
  position: relative;
  padding-top: 100%;
  background-color: #008AC2; }

.svg-flex svg {
  position: absolute;
  margin-top: -100%;
  width: 100%;
  height: 100%; }

.clear {
  clear: both; }

#house {
  clear: both; }
  #house .zdj .photo1 {
    position: relative; }
    #house .zdj .photo1 .price {
      position: absolute;
      bottom: 1.9vw;
      right: 2.8vw;
      font-family: 'Open Sans'; }
      #house .zdj .photo1 .price .el1 {
        background: #FFF;
        color: #D93600;
        font-size: 1em;
        float: right;
        padding: 5px 7px;
        font-size: 1.5vw; }
        @media screen and (max-width: 992px) {
          #house .zdj .photo1 .price .el1 {
            font-size: 3.5vw; } }
      #house .zdj .photo1 .price .el2 {
        clear: both;
        font-weight: 800;
        color: #FFF;
        background: #D93600;
        font-size: 2.3vw;
        padding: 0 10px; }
        @media screen and (max-width: 992px) {
          #house .zdj .photo1 .price .el2 {
            font-size: 4.5vw; } }
      @media screen and (max-width: 992px) {
        #house .zdj .photo1 .price {
          bottom: 3.9vw;
          right: 5.7vw; } }
  #house .title1 {
    background: #003030;
    color: #A18A5B; }
  #house .title {
    margin: auto;
    text-align: center;
    font-size: 40px;
    height: 110px;
    font-family: 'Open Sans';
    font-weight: 800;
    vertical-align: middle;
    width: 100%;
    display: table; }
    @media screen and (max-width: 1216px) {
      #house .title {
        font-size: 30px; } }
    #house .title div {
      display: table-cell;
      vertical-align: middle; }
  #house .title2 {
    color: #003030;
    background: #A28A5C; }
  #house .descript .left {
    background-position: center;
    background-repeat: no-repeat; }
    #house .descript .left .content {
      max-width: 750px;
      width: 80%;
      margin: auto; }
      #house .descript .left .content h2 {
        font-family: 'ChaparralPro-BoldIt';
        font-weight: 800;
        text-align: center;
        color: #003030;
        font-size: 70px;
        margin: 70px auto; }
      #house .descript .left .content ul li {
        background: url("/media/images/css/check.png") no-repeat center left;
        padding-left: 60px;
        list-style: none;
        font-size: 20px;
        color: #726140;
        margin: 20px 0; }
        #house .descript .left .content ul li strong {
          color: #003030;
          display: block;
          font-size: 28px;
          font-family: 'ChaparralPro-BoldIt'; }
      #house .descript .left .content .alert {
        border: 2px solid #003030;
        width: 100%;
        margin: 120px 0;
        border-radius: 0;
        padding: 0;
        position: relative; }
        #house .descript .left .content .alert .exclamation {
          position: absolute;
          background: url("/media/images/css/alert.png") no-repeat;
          height: 138px;
          width: 40px;
          right: 30px;
          top: -80px; }
        #house .descript .left .content .alert p {
          padding: 70px 30px;
          color: #2692FF;
          font-family: 'ChaparralPro-BoldIt';
          font-size: 26px;
          text-align: center; }
  #house .headPrice {
    margin-top: 50px;
    margin-bottom: 35px;
    font-family: 'ChaparralPro-BoldIt'; }
    #house .headPrice h2 {
      color: #003030;
      font-size: 70px;
      text-align: center; }
    #house .headPrice .content {
      font-size: 28px;
      color: #003030;
      text-align: center;
      padding: 0 20px; }
    #house .headPrice p {
      text-align: center;
      color: #726241;
      font-size: 40px;
      margin-bottom: 30px;
      font-family: 'ChaparralPro-Italic'; }
    #house .headPrice img {
      margin: auto; }
  #house .box-offert-container {
    height: auto;
    padding: 90px 0; }
    @media screen and (max-width: 992px) {
      #house .box-offert-container {
        padding: 90px 20%; } }
    @media screen and (max-width: 768px) {
      #house .box-offert-container {
        padding: 90px 10%; } }
    @media screen and (max-width: 480px) {
      #house .box-offert-container {
        padding: 90px 0; } }
    @media screen and (max-width: 480px) {
      #house .box-offert-container .col-offer, #house .box-offert-container .container-fluid-offer {
        padding-left: 0;
        padding-right: 0; } }
    @media screen and (max-width: 992px) {
      #house .box-offert-container .offer .col-img {
        height: 39vw; } }
    @media screen and (max-width: 768px) {
      #house .box-offert-container .offer .col-img {
        height: 50vw; } }
    @media screen and (max-width: 480px) {
      #house .box-offert-container .offer .col-img {
        height: 70vw; } }
    @media screen and (min-width: 993px) and (max-width: 1200px) {
      #house .box-offert-container .offer {
        margin-right: 50px !important; } }
    #house .box-offert-container .offer .box {
      background: none;
      margin: auto;
      height: 307px; }
      #house .box-offert-container .offer .box img {
        float: right;
        height: 307px; }
        @media screen and (max-width: 992px) {
          #house .box-offert-container .offer .box img {
            width: 100%;
            height: auto; } }
    #house .box-offert-container .offer .box-text {
      background: white;
      height: 307px;
      padding-top: 50px;
      padding-left: 30px;
      padding-right: 30px; }
      #house .box-offert-container .offer .box-text h1 {
        margin-top: 0;
        color: #003030;
        font-size: 50px;
        font-family: 'ChaparralPro-BoldIt'; }
        @media screen and (max-width: 1445px) {
          #house .box-offert-container .offer .box-text h1 {
            font-size: 40px; } }
        @media screen and (max-width: 338px) {
          #house .box-offert-container .offer .box-text h1 {
            font-size: 38px; } }
      #house .box-offert-container .offer .box-text h2 {
        color: #003030;
        font-family: 'OpenSans-Regular';
        font-size: 16px; }
        @media screen and (max-width: 1445px) {
          #house .box-offert-container .offer .box-text h2 {
            font-size: 14px; } }
        #house .box-offert-container .offer .box-text h2 span {
          font-family: 'OpenSans-ExtraBold'; }
      #house .box-offert-container .offer .box-text a {
        text-decoration: underline;
        color: #2693FF;
        font-size: 16px; }
    #house .box-offert-container .offer.offer-right {
      margin-right: 5%; }
      @media screen and (max-width: 1200px) {
        #house .box-offert-container .offer.offer-right {
          margin-right: 0;
          margin-top: 50px !important; } }

#usp {
  clear: both; }
  #usp .style1 {
    background: #A28A5C;
    color: #003030; }
  #usp .style2 {
    background: #003030;
    color: #A28A5C; }
  #usp .descript {
    font-family: 'ChaparralPro-BoldIt';
    font-size: 22px;
    height: 13vw;
    display: table; }
    @media screen and (max-width: 992px) {
      #usp .descript {
        height: 19.3vw; } }
    #usp .descript p {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      padding: 0 20px; }
      @media screen and (max-width: 1215px) and (min-width: 992px) {
        #usp .descript p {
          font-size: 18px; } }
      @media screen and (max-width: 810px) and (min-width: 767px) {
        #usp .descript p {
          font-size: 18px; } }

#normalPrice th {
  color: #003030; }

.collapsePriceGroup {
  margin-bottom: 0; }
  .collapsePriceGroup .panel {
    border-radius: 0;
    margin-top: 0 !important;
    border-width: 0px;
    border-bottom-width: 0;
    margin-bottom: 2px; }
  .collapsePriceGroup .panel-heading {
    border-radius: 0;
    text-align: center;
    background: #726241; }
    .collapsePriceGroup .panel-heading .panel-title {
      text-transform: none;
      padding: 25px 0;
      padding-top: 10px; }
      .collapsePriceGroup .panel-heading .panel-title .info {
        padding-top: 15px; }
      .collapsePriceGroup .panel-heading .panel-title .title-price {
        position: relative; }
        .collapsePriceGroup .panel-heading .panel-title .title-price p {
          color: #2692FF;
          font-size: 20px;
          font-family: 'Open Sans';
          font-weight: 800;
          margin: 0;
          padding: 0;
          margin-top: 5px; }
      .collapsePriceGroup .panel-heading .panel-title a {
        background: url("/media/images/css/up.png") no-repeat bottom;
        width: 64px;
        height: 86px;
        display: block;
        position: absolute;
        right: 10vw;
        top: 0; }
        @media screen and (max-width: 820px) {
          .collapsePriceGroup .panel-heading .panel-title a {
            right: 1vw; } }
        @media screen and (max-width: 700px) {
          .collapsePriceGroup .panel-heading .panel-title a {
            position: relative;
            margin: auto;
            margin-top: 30px;
            margin-bottom: -30px; } }
      .collapsePriceGroup .panel-heading .panel-title a.collapsed {
        background: url("/media/images/css/down.png") no-repeat; }
      .collapsePriceGroup .panel-heading .panel-title .info {
        color: #003030;
        margin: auto;
        font-family: 'Open Sans';
        font-weight: 800;
        font-size: 20px;
        padding-bottom: 10px; }
      .collapsePriceGroup .panel-heading .panel-title .date {
        font-family: 'Open Sans';
        color: #FFF;
        font-weight: 800;
        font-size: 40px;
        line-height: 30px; }
        .collapsePriceGroup .panel-heading .panel-title .date span {
          color: #BBAB8A;
          font-size: 20px;
          text-transform: lowercase; }
  .collapsePriceGroup .collapse {
    margin: 0;
    padding: 0; }
    .collapsePriceGroup .collapse th {
      color: #003030; }
  .collapsePriceGroup table.panel-body {
    width: 100%;
    line-height: normal;
    margin-top: -2px;
    border-top-color: #726241 !important; }
    .collapsePriceGroup table.panel-body th.groupHouse {
      padding: 10px 25px;
      background: #B4A483 !important; }
    .collapsePriceGroup table.panel-body th {
      background: #BCAB8A;
      font-family: 'ChaparralPro-BoldIt';
      font-size: 28px;
      text-align: center;
      padding: 20px 0; }
      @media screen and (max-width: 450px) {
        .collapsePriceGroup table.panel-body th {
          font-size: 22px; } }
      @media screen and (max-width: 330px) {
        .collapsePriceGroup table.panel-body th {
          font-size: 20px; } }
      .collapsePriceGroup table.panel-body th div {
        text-align: center; }
    .collapsePriceGroup table.panel-body td {
      font-family: 'Open Sans';
      color: #726241;
      font-size: 20px;
      font-weight: 800;
      text-align: center;
      padding: 20px 0; }
    .collapsePriceGroup table.panel-body td.price {
      color: #D83400;
      background: #F8F7F5; }
    .collapsePriceGroup table.panel-body th:nth-child(3), .collapsePriceGroup table.panel-body th:nth-child(4) {
      background: #978665; }
    .collapsePriceGroup table.panel-body tr:nth-child(2n+1) {
      background: #EEEBE3; }
      .collapsePriceGroup table.panel-body tr:nth-child(2n+1) td.price {
        background: #E7E4DA; }
    .collapsePriceGroup table.panel-body .empty {
      width: 30%; }
      @media screen and (max-width: 1000px) {
        .collapsePriceGroup table.panel-body .empty {
          width: 0; } }
    .collapsePriceGroup table.panel-body .notempty {
      width: 20%; }
      @media screen and (max-width: 1000px) {
        .collapsePriceGroup table.panel-body .notempty {
          width: 50%; } }
  .collapsePriceGroup .after_table {
    text-align: center;
    padding: 30px 0;
    color: #D93600;
    font-size: 20px;
    font-weight: 800;
    font-family: 'Open Sans'; }

#specialPrice .panel-heading {
  background: #003030; }
  #specialPrice .panel-heading .panel-title .info {
    color: #2692FF; }
  #specialPrice .panel-heading .panel-title p {
    color: #FFF; }

#specialPrice table.panel-body {
  border-top-color: #003030 !important; }
  #specialPrice table.panel-body th {
    background: #245E56;
    color: #BBAB8A; }
  #specialPrice table.panel-body th.groupHouse {
    background: #225750 !important; }
  #specialPrice table.panel-body th:nth-child(3), #specialPrice table.panel-body th:nth-child(4) {
    background: #1B433F; }
  #specialPrice table.panel-body td {
    color: #003030; }
  #specialPrice table.panel-body td.price {
    background: #F0F4F4;
    color: #D83400; }
  #specialPrice table.panel-body tr:nth-child(2n+1) {
    background: #EEEBE3; }
    #specialPrice table.panel-body tr:nth-child(2n+1) td.price {
      background: #D7E3E3; }
  #specialPrice table.panel-body tr:nth-child(2n+1) {
    background: #E1EAEA; }

#gallery {
  clear: both;
  background: #003030;
  padding-top: 70px; }
  #gallery h2 {
    color: #A18A5B;
    font-family: 'ChaparralPro-BoldIt';
    font-size: 70px;
    text-align: center; }
  #gallery .content {
    column-count: 4;
    column-gap: 100px;
    column-break-inside: avoid; }
    #gallery .content img {
      margin-bottom: 20px; }

.specialDates {
  width: 100%;
  background: #2693FF;
  padding: 40px 0;
  font-family: 'ChaparralPro-BoldIt';
  color: #FFF;
  font-size: 40px;
  text-align: center;
  position: relative;
  margin-top: -2px; }
  .specialDates .down {
    background: url("/media/images/css/arrow-down.png") no-repeat center;
    position: absolute;
    width: 100%;
    height: 24px;
    bottom: -24px;
    z-index: 100; }

#cennik {
  clear: both;
  background: url("/media/images/css/cennik-background.jpg") center;
  min-height: 440px;
  width: 100%;
  margin-bottom: -2px; }
  #cennik h1 {
    font-family: 'ChaparralPro-BoldIt';
    font-size: 70px;
    color: #FFF;
    text-align: center;
    padding-top: 120px;
    margin-top: 0px; }
  #cennik p {
    color: #978565;
    font-size: 28px;
    font-family: 'ChaparralPro-BoldIt';
    text-align: center;
    max-width: 900px;
    margin: auto;
    padding: 30px; }

.contact-container .contact-left {
  background-color: #EEEBE3;
  height: auto; }
  .contact-container .contact-left .wood-footer-container-contact {
    background-color: #EEEBE3;
    height: 640px;
    text-align: center;
    padding: 130px 0;
    color: #003030;
    margin: 0; }
    @media screen and (max-width: 992px) {
      .contact-container .contact-left .wood-footer-container-contact {
        padding: 45px 0; } }
    .contact-container .contact-left .wood-footer-container-contact img.wood-footer-upper-shadow {
      margin-top: -45px;
      position: absolute;
      left: 0; }
    @media screen and (max-width: 992px) {
      .contact-container .contact-left .wood-footer-container-contact {
        height: auto; } }
    @media screen and (max-width: 480px) {
      .contact-container .contact-left .wood-footer-container-contact .wood-logo {
        width: 277px; } }
    .contact-container .contact-left .wood-footer-container-contact .title {
      font-family: "ChaparralPro-Bold";
      font-size: 32px;
      font-style: italic;
      line-height: 32px;
      margin-top: 32px;
      margin-bottom: 10px; }
    .contact-container .contact-left .wood-footer-container-contact .address {
      font-family: "OpenSans-ExtraBold";
      font-size: 20px; }
      @media screen and (max-width: 1200px) and (min-width: 1024px) {
        .contact-container .contact-left .wood-footer-container-contact .address {
          margin-bottom: -4px; } }
    .contact-container .contact-left .wood-footer-container-contact a, .contact-container .contact-left .wood-footer-container-contact p {
      color: #003030;
      font-size: 16px;
      font-family: 'OpenSans-ExtraBold';
      text-decoration: none; }
      .contact-container .contact-left .wood-footer-container-contact a.phone, .contact-container .contact-left .wood-footer-container-contact p.phone {
        font-size: 20px; }
      .contact-container .contact-left .wood-footer-container-contact a span, .contact-container .contact-left .wood-footer-container-contact p span {
        font-size: 20px; }
    .contact-container .contact-left .wood-footer-container-contact .mail-icon {
      margin-bottom: 26px; }
    .contact-container .contact-left .wood-footer-container-contact .phone-icon {
      margin-bottom: 14px; }
    .contact-container .contact-left .wood-footer-container-contact .contact-col {
      margin-top: 20px; }
  .contact-container .contact-left::after {
    top: 50%;
    left: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-left-color: #EEEBE3;
    border-width: 51px;
    margin-top: -51px;
    z-index: 1; }
    @media screen and (max-width: 992px) {
      .contact-container .contact-left::after {
        top: 107%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(255, 255, 255, 0);
        border-top-color: #EEEBE3;
        border-width: 51px;
        margin-left: -51px; } }
    @media screen and (max-width: 379px) {
      .contact-container .contact-left::after {
        top: 105%; } }

.contact-container .contact-right {
  background-image: url("/media/images/contact/kontakt-right.jpg");
  height: 640px; }
  @media screen and (max-width: 480px) {
    .contact-container .contact-right {
      background-position-y: 134px; } }
  .contact-container .contact-right .white-container {
    width: 48%;
    height: auto;
    position: absolute;
    top: 92px;
    left: 37%;
    background-color: white;
    color: #003030;
    padding: 25px; }
    @media screen and (max-width: 480px) {
      .contact-container .contact-right .white-container {
        width: 100%;
        left: 0;
        top: 0;
        padding-top: 70px; } }
    .contact-container .contact-right .white-container .title {
      text-align: center; }
      .contact-container .contact-right .white-container .title p {
        font-family: 'ChaparralPro-BoldIt';
        font-size: 33px;
        margin: 0; }
      .contact-container .contact-right .white-container .title .contact-content {
        font-family: 'OpenSans-Regular';
        font-size: 20px;
        line-height: 22px; }
    .contact-container .contact-right .white-container::after {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(255, 255, 255, 0);
      border-top-color: white;
      border-width: 30px;
      margin-left: -30px; }

.reasons {
  background-color: white;
  padding: 0 20px;
  height: auto; }
  .reasons .reasons-title {
    color: #003030;
    font-size: 40px;
    font-family: 'ChaparralPro-BoldIt';
    text-align: center;
    margin-top: 56px; }
    @media screen and (max-width: 480px) {
      .reasons .reasons-title {
        font-size: 32px; } }
  .reasons .reasons-container {
    margin-top: 40px;
    margin-bottom: 100px; }
    .reasons .reasons-container .reasons-column {
      padding: 0 100px; }
      @media screen and (max-width: 480px) {
        .reasons .reasons-container .reasons-column {
          padding: 0 42px; } }
      .reasons .reasons-container .reasons-column ul li {
        background: url(/media/images/css/check.png) no-repeat center left;
        padding-left: 60px;
        list-style: none;
        font-size: 22px;
        color: #72603F;
        margin: 40px 0;
        font-family: 'ChaparralPro-Italic'; }
        @media screen and (max-width: 480px) {
          .reasons .reasons-container .reasons-column ul li {
            font-size: 17px;
            line-height: 22px; } }
        .reasons .reasons-container .reasons-column ul li span {
          font-size: 28px;
          font-family: 'ChaparralPro-BoldIt';
          color: #003030; }
          @media screen and (max-width: 480px) {
            .reasons .reasons-container .reasons-column ul li span {
              font-size: 20px; } }

.why-domki-container .film-container {
  background: url("/media/images/woodfooter.jpg");
  height: 620px;
  -webkit-box-shadow: inset 0px 210px 96px -178px rgba(0, 0, 0, 0.8), inset 0px -210px 96px -178px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: inset 0px 210px 96px -178px rgba(0, 0, 0, 0.8), inset 0px -210px 96px -178px rgba(0, 0, 0, 0.8);
  box-shadow: inset 0px 210px 96px -178px rgba(0, 0, 0, 0.8), inset 0px -210px 96px -178px rgba(0, 0, 0, 0.8); }
  @media screen and (max-width: 1200px) {
    .why-domki-container .film-container {
      height: auto; } }
  .why-domki-container .film-container .film-row {
    margin-top: 103px; }
    @media screen and (max-width: 1200px) {
      .why-domki-container .film-container .film-row {
        margin-top: 0px; } }
    .why-domki-container .film-container .film-row .film-left {
      background: url("/media/images/why-domki/zobacz-film.jpg");
      background-size: cover;
      height: 399px; }
      @media screen and (max-width: 1200px) {
        .why-domki-container .film-container .film-row .film-left {
          height: 498px;
          margin: 0 109px; } }
      @media screen and (max-width: 992px) {
        .why-domki-container .film-container .film-row .film-left {
          margin: 0 0; } }
      @media screen and (max-width: 768px) {
        .why-domki-container .film-container .film-row .film-left {
          background-position: 50%; } }
      @media screen and (max-width: 480px) {
        .why-domki-container .film-container .film-row .film-left {
          height: 360px; } }
    .why-domki-container .film-container .film-row .film-right {
      background: url("/media/images/why-domki/film-placeholder.jpg");
      background-size: cover;
      background-position: 50%;
      height: 399px; }
      @media screen and (max-width: 1200px) {
        .why-domki-container .film-container .film-row .film-right {
          height: 498px;
          margin: 0 109px; } }
      @media screen and (max-width: 992px) {
        .why-domki-container .film-container .film-row .film-right {
          margin: 0 0; } }
      @media screen and (max-width: 768px) {
        .why-domki-container .film-container .film-row .film-right {
          background-position: 50%; } }

.why-domki-container .cytat-container {
  background-image: url("/media/images/why-domki/cytat-background2.jpg");
  background-size: cover;
  height: 1138px;
  background-position-x: 50%; }
  @media screen and (max-width: 1460px) {
    .why-domki-container .cytat-container {
      background-image: url("/media/images/why-domki/cytat-background2-md.jpg"); } }
  @media screen and (max-width: 768px) {
    .why-domki-container .cytat-container {
      background-image: url("/media/images/why-domki/cytat-background2-sm.jpg"); } }
  .why-domki-container .cytat-container .cytat {
    color: white;
    font-family: "ChaparralPro-BoldIt";
    font-size: 36px;
    width: 974px;
    margin: 0 auto;
    line-height: 46px;
    padding-top: 406px; }
    @media screen and (max-width: 1460px) {
      .why-domki-container .cytat-container .cytat {
        width: 628px;
        padding-top: 319px;
        line-height: 44px; } }
    @media screen and (max-width: 768px) {
      .why-domki-container .cytat-container .cytat {
        line-height: 29px;
        width: 249px;
        padding-top: 251px;
        font-size: 24px;
        text-align: center; } }
    .why-domki-container .cytat-container .cytat .landlords {
      color: white;
      font-family: 'OpenSans-ExtraBold';
      font-size: 20px;
      text-align: right;
      line-height: 34px;
      margin-top: 10px; }
      @media screen and (max-width: 1460px) {
        .why-domki-container .cytat-container .cytat .landlords {
          margin-top: 56px; } }
      @media screen and (max-width: 768px) {
        .why-domki-container .cytat-container .cytat .landlords {
          width: 251px;
          text-align: center; } }
      .why-domki-container .cytat-container .cytat .landlords span {
        font-family: 'OpenSans-Regular'; }

.why-domki-container .szczyrk-container {
  background-image: url("/media/images/why-domki/opinie-background.jpg");
  height: 1265px; }
  @media screen and (max-width: 768px) {
    .why-domki-container .szczyrk-container {
      height: auto; } }
  .why-domki-container .szczyrk-container .info {
    -webkit-box-shadow: 10px 10px 29px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 29px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 10px 10px 29px 0px rgba(0, 0, 0, 0.75);
    background-image: url("/media/images/why-domki/white-box.jpg");
    height: 973px;
    width: 661px;
    padding: 0 66px;
    margin-top: 150px;
    margin-right: 9%;
    float: right; }
    @media screen and (max-width: 992px) {
      .why-domki-container .szczyrk-container .info {
        padding-top: 1px; } }
    @media screen and (max-width: 768px) {
      .why-domki-container .szczyrk-container .info {
        margin-top: 0px;
        width: inherit;
        background-size: cover;
        background-size: 100% 100%;
        height: auto;
        padding-bottom: 50px;
        margin-bottom: -1px;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        margin-right: 0; } }
    @media screen and (max-width: 480px) {
      .why-domki-container .szczyrk-container .info {
        background-image: none;
        background-color: white;
        padding-top: 50px; } }
    .why-domki-container .szczyrk-container .info .title {
      margin-top: 0px; }
      .why-domki-container .szczyrk-container .info .title p {
        color: #003030;
        font-family: 'ChaparralPro-BoldIt';
        font-size: 64px;
        margin-top: 70px;
        margin-bottom: 0px;
        text-align: center; }
        @media screen and (max-width: 768px) {
          .why-domki-container .szczyrk-container .info .title p.szczyrk-title {
            font-size: 50px; } }
        @media screen and (max-width: 480px) {
          .why-domki-container .szczyrk-container .info .title p.szczyrk-title {
            line-height: 30px;
            margin-top: 0;
            font-size: 34px; } }
      .why-domki-container .szczyrk-container .info .title .subtitle {
        color: #72603F;
        font-size: 36px;
        font-family: 'ChaparralPro-Italic';
        line-height: 29px;
        text-align: center; }
        @media screen and (max-width: 480px) {
          .why-domki-container .szczyrk-container .info .title .subtitle {
            font-size: 18px; } }
      .why-domki-container .szczyrk-container .info .title h1 {
        font-family: 'ChaparralPro-Italic';
        font-size: 28px;
        margin-top: 45px;
        color: #003030; }
        @media screen and (max-width: 480px) {
          .why-domki-container .szczyrk-container .info .title h1 {
            font-size: 20px; } }
      .why-domki-container .szczyrk-container .info .title h2 {
        color: #003030;
        font-family: 'OpenSans-ExtraBold';
        font-size: 20px;
        line-height: 30px;
        text-align: right;
        margin-top: -11px; }
        @media screen and (max-width: 480px) {
          .why-domki-container .szczyrk-container .info .title h2 {
            font-size: 16px; } }
      .why-domki-container .szczyrk-container .info .title h3 {
        color: #003030;
        font-family: 'ChaparralPro-BoldIt';
        font-size: 40px;
        text-align: center; }
        @media screen and (max-width: 480px) {
          .why-domki-container .szczyrk-container .info .title h3 {
            font-size: 28px; } }
      .why-domki-container .szczyrk-container .info .title a {
        text-align: center;
        color: #2693FF;
        font-family: 'OpenSans-ExtraBold';
        font-size: 17px;
        text-decoration: underline;
        display: block;
        line-height: 28px;
        margin-bottom: 10px; }
    @media all and (max-width: 480px) {
      .why-domki-container .szczyrk-container .info .blue-button {
        width: 220px; } }

.szczyrk-page-container .szczyrk-container {
  background-image: url("/media/images/szczyrk/szczyrk-background.jpg");
  height: 1000px;
  background-size: cover;
  position: relative; }
  @media screen and (max-width: 1585px) {
    .szczyrk-page-container .szczyrk-container {
      height: 1350px; } }
  @media screen and (max-width: 768px) {
    .szczyrk-page-container .szczyrk-container {
      height: auto;
      background-image: url("/media/images/szczyrk/szczyrk-background-mobile.jpg");
      background-size: 100% 700px;
      background-repeat: no-repeat; } }
  @media screen and (max-width: 480px) {
    .szczyrk-page-container .szczyrk-container {
      background-size: 100% 530px; } }
  .szczyrk-page-container .szczyrk-container .left-text {
    position: absolute;
    top: 30px;
    left: 228px;
    background: none;
    width: 564px; }
    @media screen and (max-width: 1585px) {
      .szczyrk-page-container .szczyrk-container .left-text {
        left: 24%;
        width: 55%; } }
    @media screen and (max-width: 480px) {
      .szczyrk-page-container .szczyrk-container .left-text {
        left: 22%;
        width: 53%; } }
    .szczyrk-page-container .szczyrk-container .left-text h1 {
      color: #003030;
      text-align: center;
      font-family: 'ChaparralPro-BoldIt';
      font-size: 70px; }
      @media screen and (max-width: 480px) {
        .szczyrk-page-container .szczyrk-container .left-text h1 {
          font-size: 48px; } }
    .szczyrk-page-container .szczyrk-container .left-text p {
      color: #003030;
      text-align: center;
      font-family: 'ChaparralPro-BoldIt';
      font-size: 28px;
      line-height: 30px; }
      @media screen and (max-width: 480px) {
        .szczyrk-page-container .szczyrk-container .left-text p {
          line-height: 22px;
          font-size: 18px; } }
  .szczyrk-page-container .szczyrk-container .info {
    -webkit-box-shadow: 10px 10px 29px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 29px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 10px 10px 29px 0px rgba(0, 0, 0, 0.75);
    background-image: url("/media/images/homepage/snowy-background.jpg");
    height: 805px;
    width: 661px;
    padding: 0 66px;
    margin-top: 83px;
    margin-right: 9%;
    float: right; }
    @media screen and (max-width: 1585px) {
      .szczyrk-page-container .szczyrk-container .info {
        margin-top: 450px;
        float: none;
        margin-left: auto;
        margin-right: auto;
        padding-top: 1px; } }
    @media screen and (max-width: 1200px) {
      .szczyrk-page-container .szczyrk-container .info {
        margin-left: auto;
        margin-right: auto;
        padding-top: 1px;
        float: none; } }
    @media screen and (max-width: 768px) {
      .szczyrk-page-container .szczyrk-container .info {
        width: inherit;
        background-size: cover;
        background-size: 100% 100%;
        height: auto;
        padding-bottom: 50px;
        margin-bottom: -1px;
        margin-right: 0;
        margin-top: 700px;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none; } }
    @media screen and (max-width: 480px) {
      .szczyrk-page-container .szczyrk-container .info {
        margin-top: 530px;
        background-image: none;
        background-color: white;
        padding-left: 30px;
        padding-right: 30px; } }
    @media screen and (max-width: 390px) {
      .szczyrk-page-container .szczyrk-container .info {
        margin-top: 530px; } }
    .szczyrk-page-container .szczyrk-container .info .title {
      margin-top: 0px; }
      .szczyrk-page-container .szczyrk-container .info .title p {
        color: #A18A5B;
        font-family: 'ChaparralPro-BoldIt';
        font-size: 48px;
        margin-top: 60px;
        margin-bottom: 0px;
        text-align: center; }
        @media all and (max-width: 393px) {
          .szczyrk-page-container .szczyrk-container .info .title p.szczyrk-title {
            font-size: 40px; } }
      .szczyrk-page-container .szczyrk-container .info .title .subtitle {
        color: #003030;
        font-size: 35px;
        font-family: 'ChaparralPro-Italic';
        line-height: 35px;
        text-align: center;
        margin-top: -16px; }
      .szczyrk-page-container .szczyrk-container .info .title h1 {
        font-family: 'ChaparralPro-BoldIt';
        font-size: 28px;
        margin-top: 50px;
        color: #003030;
        text-align: center; }
        .szczyrk-page-container .szczyrk-container .info .title h1.small-margin {
          margin-top: 5px; }
      .szczyrk-page-container .szczyrk-container .info .title h2 {
        color: #003030;
        font-family: 'OpenSans-Regular';
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        margin-top: 47px; }
        .szczyrk-page-container .szczyrk-container .info .title h2 span {
          font-family: 'OpenSans-ExtraBold'; }
      .szczyrk-page-container .szczyrk-container .info .title h3 {
        color: #003030;
        font-family: 'ChaparralPro-BoldIt';
        font-size: 40px;
        text-align: center; }
      .szczyrk-page-container .szczyrk-container .info .title a {
        text-align: center;
        color: #2693FF;
        font-family: 'OpenSans-ExtraBold';
        font-size: 20px;
        text-decoration: underline;
        display: block;
        line-height: 28px; }
      .szczyrk-page-container .szczyrk-container .info .title img {
        display: block;
        margin: 0 auto;
        margin-top: 30px; }
    @media all and (max-width: 480px) {
      .szczyrk-page-container .szczyrk-container .info .blue-button {
        width: 220px; } }

.check-calendar {
  background-image: url("/media/images/szczyrk/check-szczyrk-background.jpg");
  height: 974px;
  background-size: cover; }
  @media screen and (max-width: 1044px) {
    .check-calendar {
      height: auto; } }
  .check-calendar h1 {
    color: white;
    font-family: 'ChaparralPro-BoldIt';
    font-size: 40px;
    text-align: center;
    margin-top: 80px;
    padding: 0 40px; }
    @media screen and (max-width: 520px) {
      .check-calendar h1 {
        font-size: 30px;
        margin-top: 19px; } }
  .check-calendar h2 {
    color: #003030;
    font-family: 'ChaparralPro-Italic';
    font-size: 40px;
    text-align: center;
    padding: 0 40px; }
    @media screen and (max-width: 520px) {
      .check-calendar h2 {
        font-size: 24px; } }
  .check-calendar img {
    display: block;
    margin: 0 auto;
    position: relative;
    margin-top: 50px; }
    @media screen and (max-width: 520px) {
      .check-calendar img {
        margin-top: 10px; } }
  .check-calendar .calendar {
    background: url("/media/images/szczyrk/calendar.jpg");
    position: relative;
    width: 1027px;
    height: 513px;
    margin: 0 auto;
    margin-top: 28px; }
    @media screen and (max-width: 1044px) {
      .check-calendar .calendar {
        background: url("/media/images/szczyrk/calendar-sm.jpg");
        width: 512px;
        height: 1025px;
        margin-bottom: 100px; } }
    @media screen and (max-width: 520px) {
      .check-calendar .calendar {
        background: url("/media/images/szczyrk/calendar-xs.jpg");
        width: 303px;
        height: 609px; } }
    .check-calendar .calendar .blue-button {
      position: absolute;
      width: 131px;
      height: 58px;
      top: 266px; }
      .check-calendar .calendar .blue-button.left-button {
        left: 191px; }
        @media screen and (max-width: 520px) {
          .check-calendar .calendar .blue-button.left-button {
            top: 173px;
            left: 87px; } }
      .check-calendar .calendar .blue-button.right-button {
        right: 191px; }
        @media screen and (max-width: 1044px) {
          .check-calendar .calendar .blue-button.right-button {
            top: 778px; } }
        @media screen and (max-width: 520px) {
          .check-calendar .calendar .blue-button.right-button {
            top: 479px;
            right: 87px; } }

.meet-the-szczyrk {
  background: url("/media/images/szczyrk/meet-the-szczyrk-background.jpg");
  height: 352px; }
  .meet-the-szczyrk .meet-the-szczyrk-box {
    margin: 0 auto;
    width: 807px;
    height: 152px;
    border: solid 2px white;
    text-align: center;
    font-family: 'ChaparralPro-BoldIt';
    font-size: 40px;
    color: white;
    vertical-align: center;
    padding-top: 45px;
    margin-top: 90px; }
    @media screen and (max-width: 847px) {
      .meet-the-szczyrk .meet-the-szczyrk-box {
        width: 87%; } }
    @media screen and (max-width: 480px) {
      .meet-the-szczyrk .meet-the-szczyrk-box {
        font-size: 30px; } }
    .meet-the-szczyrk .meet-the-szczyrk-box .blue-button {
      width: 200px;
      margin-top: 18px; }
      @media screen and (max-width: 480px) {
        .meet-the-szczyrk .meet-the-szczyrk-box .blue-button {
          margin-top: 33px; } }

.kids-container .szczyrk-container {
  background-image: url("/media/images/kids/kids-background.jpg");
  height: 1020px;
  background-position-x: 50%; }
  @media screen and (max-width: 768px) {
    .kids-container .szczyrk-container {
      height: auto;
      background-size: 100%; } }
  .kids-container .szczyrk-container .gufik {
    position: absolute;
    top: -26px;
    left: 545px; }
    @media screen and (max-width: 768px) {
      .kids-container .szczyrk-container .gufik {
        top: -82px;
        left: 34%; } }
    @media screen and (max-width: 480px) {
      .kids-container .szczyrk-container .gufik {
        left: 27%; } }
    @media screen and (max-width: 362px) {
      .kids-container .szczyrk-container .gufik {
        left: 19%; } }
  .kids-container .szczyrk-container .info {
    -webkit-box-shadow: 10px 10px 29px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 29px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 10px 10px 29px 0px rgba(0, 0, 0, 0.75);
    background-image: url("/media/images/kids/kids-white-box.jpg");
    height: 805px;
    width: 661px;
    padding: 0 66px;
    margin-top: 150px;
    margin-left: 10%; }
    @media screen and (max-width: 992px) {
      .kids-container .szczyrk-container .info {
        padding-top: 1px; } }
    @media screen and (max-width: 768px) {
      .kids-container .szczyrk-container .info {
        margin-top: 52vw;
        width: inherit;
        background-size: cover;
        background-size: 100% 100%;
        height: auto;
        padding-bottom: 50px;
        margin-bottom: -1px;
        margin-left: 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none; } }
    @media screen and (max-width: 480px) {
      .kids-container .szczyrk-container .info {
        background-image: none;
        background-color: white; } }
    .kids-container .szczyrk-container .info .title {
      margin-top: 0; }
      @media screen and (max-width: 768px) {
        .kids-container .szczyrk-container .info .title {
          margin-top: 253px; } }
      .kids-container .szczyrk-container .info .title p {
        color: #003030;
        font-family: 'ChaparralPro-BoldIt';
        font-size: 70px;
        margin-bottom: 0px;
        text-align: center;
        line-height: 66px;
        margin-top: 20px; }
        @media screen and (max-width: 480px) {
          .kids-container .szczyrk-container .info .title p.szczyrk-title {
            font-size: 43px;
            line-height: 40px; } }
      .kids-container .szczyrk-container .info .title .subtitle {
        color: #726241;
        font-size: 40px;
        font-family: 'ChaparralPro-Italic';
        line-height: 35px;
        text-align: center;
        margin-top: 70px; }
      .kids-container .szczyrk-container .info .title h1 {
        color: #725F3F;
        font-family: 'ChaparralPro-Italic';
        font-size: 22px;
        margin-top: 28px;
        margin-bottom: 20px;
        text-align: center; }
        .kids-container .szczyrk-container .info .title h1 span {
          font-family: 'ChaparralPro-BoldIt';
          color: #003030;
          font-size: 28px; }
      .kids-container .szczyrk-container .info .title h2 {
        color: #72603F;
        font-family: 'OpenSans-Regular';
        font-size: 20px;
        line-height: 30px;
        text-align: center; }
    .kids-container .szczyrk-container .info .blue-button {
      width: 130px; }
      @media all and (max-width: 480px) {
        .kids-container .szczyrk-container .info .blue-button {
          width: 130px; } }

.kids-container .benefits-row {
  background-image: url("/media/images/kids/kids-benefits-background.jpg");
  background-size: cover;
  background-position-x: 50%; }
  @media screen and (max-width: 1200px) {
    .kids-container .benefits-row {
      padding: 0 60px; } }
  @media screen and (max-width: 480px) {
    .kids-container .benefits-row {
      padding: 0 30px; } }
  @media screen and (max-width: 992px) {
    .kids-container .benefits-row .benefit .text {
      left: 28%; } }
  .kids-container .benefits-row .benefit .text span {
    font-size: 25px; }
  .kids-container .benefits-row .title {
    margin-top: 0px !important;
    text-align: center; }
    .kids-container .benefits-row .title p {
      color: white;
      font-family: 'ChaparralPro-BoldIt';
      font-size: 70px;
      margin-top: 47px;
      margin-bottom: 0px;
      padding: 0 40px; }
      @media screen and (max-width: 768px) {
        .kids-container .benefits-row .title p {
          line-height: 77px; } }
      @media screen and (max-width: 480px) {
        .kids-container .benefits-row .title p {
          font-size: 45px;
          line-height: 45px; } }
    .kids-container .benefits-row .title .subtitle {
      color: #A18A5B;
      font-size: 40px;
      font-family: 'ChaparralPro-Italic';
      font-weight: 100;
      margin-top: 0; }
  .kids-container .benefits-row .benefits-outer-container {
    margin-top: -15px;
    margin-bottom: 100px; }
    .kids-container .benefits-row .benefits-outer-container .benefits-container {
      background-image: none;
      height: 840px; }
      @media screen and (max-width: 1200px) {
        .kids-container .benefits-row .benefits-outer-container .benefits-container {
          height: auto; } }
      @media screen and (max-width: 768px) {
        .kids-container .benefits-row .benefits-outer-container .benefits-container.second-container {
          margin-top: -100px; } }

.kids-container .reasons {
  background-color: white;
  height: auto; }
  .kids-container .reasons .reasons-title {
    color: #003030;
    font-size: 28px;
    font-family: 'ChaparralPro-BoldIt';
    text-align: center;
    margin-top: 56px;
    margin-left: 28%;
    margin-right: 28%; }
    @media screen and (max-width: 768px) {
      .kids-container .reasons .reasons-title {
        margin-left: 14%;
        margin-right: 14%; } }
    @media screen and (max-width: 480px) {
      .kids-container .reasons .reasons-title {
        font-size: 21px;
        line-height: 21px; } }
  .kids-container .reasons .reasons-container {
    margin-top: 100px;
    margin-bottom: 100px; }
    @media screen and (max-width: 1200px) {
      .kids-container .reasons .reasons-container {
        margin-top: 50px; } }
    .kids-container .reasons .reasons-container .reasons-column {
      padding: 0 100px;
      padding-left: 15%; }
      @media screen and (max-width: 480px) {
        .kids-container .reasons .reasons-container .reasons-column {
          padding: 0 42px; } }
      .kids-container .reasons .reasons-container .reasons-column ul li {
        background: url(/media/images/css/check.png) no-repeat center left;
        padding-left: 60px;
        list-style: none;
        font-size: 20px;
        color: #003030;
        margin: 20px 0;
        font-family: 'OpenSans-ExtraBold';
        line-height: 40px; }
        @media screen and (max-width: 480px) {
          .kids-container .reasons .reasons-container .reasons-column ul li {
            font-size: 16px;
            height: 50px;
            line-height: 25px; } }
        .kids-container .reasons .reasons-container .reasons-column ul li span {
          font-size: 28px;
          font-family: 'ChaparralPro-BoldIt';
          color: #003030; }
    .kids-container .reasons .reasons-container .kids-reasons-column {
      border: solid 2px #003030;
      margin-left: -90px;
      padding: 85px 6%;
      position: relative;
      padding-bottom: 30px; }
      @media screen and (max-width: 1200px) {
        .kids-container .reasons .reasons-container .kids-reasons-column {
          padding: 70px 4%;
          margin: 100px 16%; } }
      .kids-container .reasons .reasons-container .kids-reasons-column img {
        position: absolute;
        top: -73px;
        left: 89%; }
      .kids-container .reasons .reasons-container .kids-reasons-column p {
        color: #2693FF;
        font-family: 'ChaparralPro-BoldIt';
        font-size: 28px;
        text-align: center;
        line-height: 33px;
        margin-bottom: 20px; }

.map-page-container {
  background: url("/media/images/map/machon.jpg");
  height: auto;
  overflow: hidden;
  position: relative; }
  @media screen and (max-width: 992px) {
    .map-page-container {
      height: auto; } }
  .map-page-container .map-heading {
    position: relative;
    overflow: hidden; }
    .map-page-container .map-heading h1 {
      font-size: 66px;
      font-family: 'ChaparralPro-BoldIt';
      color: #231605;
      text-align: center;
      margin-top: 0;
      padding-top: 37px; }
    .map-page-container .map-heading h2 {
      color: white;
      font-size: 37px;
      font-family: 'ChaparralPro-Italic';
      text-align: center;
      line-height: 9px;
      margin-top: 0; }
      @media screen and (max-width: 480px) {
        .map-page-container .map-heading h2 {
          font-size: 25px; } }
    .map-page-container .map-heading .redpin {
      position: absolute;
      top: 35px;
      left: 30.7%; }
      @media screen and (max-width: 1016px) {
        .map-page-container .map-heading .redpin {
          left: 15%; } }
      @media screen and (max-width: 480px) {
        .map-page-container .map-heading .redpin {
          left: 3%; } }
    .map-page-container .map-heading .photo {
      position: absolute;
      top: 56px;
      left: 1.9%; }
      @media screen and (max-width: 1668px) {
        .map-page-container .map-heading .photo {
          left: -12.1%; } }
      @media screen and (max-width: 1348px) {
        .map-page-container .map-heading .photo {
          left: -20.1%; } }
      @media screen and (max-width: 1115px) {
        .map-page-container .map-heading .photo {
          display: none; } }
    .map-page-container .map-heading .coffee {
      position: absolute;
      top: 108px;
      left: 75.2%; }
      @media screen and (max-width: 1747px) {
        .map-page-container .map-heading .coffee {
          left: 89.2%; } }
      @media screen and (max-width: 1129px) {
        .map-page-container .map-heading .coffee {
          display: none; } }
    .map-page-container .map-heading .paper {
      color: #72603F;
      font-family: 'ChaparralPro-Italic';
      font-size: 28px;
      background-image: url("/media/images/map/paper.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      width: 866px;
      padding: 48px 45px;
      text-align: center;
      line-height: 36px;
      margin: 0 auto;
      margin-top: 39px;
      position: relative; }
      @media screen and (max-width: 992px) {
        .map-page-container .map-heading .paper {
          width: 90%; } }
      .map-page-container .map-heading .paper .greenpin1 {
        position: absolute;
        top: 0;
        left: -17px; }
      .map-page-container .map-heading .paper .greenpin2 {
        position: absolute;
        top: -8px;
        right: -34px; }
    .map-page-container .map-heading h3 {
      font-size: 40px;
      font-family: 'ChaparralPro-BoldIt';
      text-align: center;
      color: white;
      text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.75);
      margin-top: 40px; }
  .map-page-container .pen {
    position: absolute;
    top: 744px;
    left: 88%;
    z-index: 1; }
    @media screen and (max-width: 1500px) {
      .map-page-container .pen {
        display: none; } }
  .map-page-container .map-papers {
    background-image: url("/media/images/map/map-papers.png");
    position: absolute;
    top: 526px;
    left: 13.9%;
    width: 1460px;
    height: 1500px;
    background-repeat: no-repeat; }
    @media screen and (max-width: 1208px) {
      .map-page-container .map-papers {
        left: 0.9%; } }
    @media screen and (max-width: 962px) {
      .map-page-container .map-papers {
        top: 580px; } }
    @media screen and (max-width: 920px) {
      .map-page-container .map-papers {
        left: -10%; } }
    @media screen and (max-width: 768px) {
      .map-page-container .map-papers {
        display: none; } }
  .map-page-container .map-attraction-container {
    margin-top: 101px;
    padding: 0 28%;
    margin-bottom: -100px;
    position: relative; }
    @media screen and (max-width: 1660px) {
      .map-page-container .map-attraction-container {
        padding: 0 20%; } }
    @media screen and (max-width: 1208px) {
      .map-page-container .map-attraction-container {
        padding: 0 10%; } }
    @media screen and (max-width: 920px) {
      .map-page-container .map-attraction-container {
        padding: 0 0; } }
    @media screen and (max-width: 768px) {
      .map-page-container .map-attraction-container {
        background: #F0F4F4;
        padding-bottom: 50px; } }
    @media screen and (max-width: 768px) {
      .map-page-container .map-attraction-container {
        margin-top: 0;
        margin-bottom: 0; } }
    .map-page-container .map-attraction-container .redpin {
      position: absolute;
      top: -53px;
      left: 20.1%; }
      @media screen and (max-width: 1660px) {
        .map-page-container .map-attraction-container .redpin {
          left: 13.1%; } }
      @media screen and (max-width: 1208px) {
        .map-page-container .map-attraction-container .redpin {
          left: 3.1%; } }
      @media screen and (max-width: 992px) {
        .map-page-container .map-attraction-container .redpin {
          display: none; } }
    .map-page-container .map-attraction-container .greenpin {
      position: absolute;
      top: 78px;
      right: 17.5%; }
      @media screen and (max-width: 768px) {
        .map-page-container .map-attraction-container .greenpin {
          display: none; } }
    .map-page-container .map-attraction-container .mapClickCol {
      text-align: center;
      cursor: pointer; }
      @media screen and (max-width: 768px) {
        .map-page-container .map-attraction-container .mapClickCol {
          margin-top: 60px; } }
      .map-page-container .map-attraction-container .mapClickCol .mapClick {
        color: #2693FF;
        font-size: 17px;
        font-family: 'OpenSans-ExtraBold';
        border: solid 2px #2693FF;
        padding: 17px 19px;
        background-color: #F0F4F4 !important;
        text-decoration: none;
        cursor: pointer; }
        @media screen and (max-width: 1900px) {
          .map-page-container .map-attraction-container .mapClickCol .mapClick {
            font-size: 14px; } }
        .map-page-container .map-attraction-container .mapClickCol .mapClick:hover {
          background-color: #2693FF !important;
          color: white; }
        .map-page-container .map-attraction-container .mapClickCol .mapClick.mapButtonActive {
          background-color: #2693FF !important;
          color: white; }

.clear {
  clear: both; }

.black-wood {
  background-image: url("/media/images/map/black-wood.jpg");
  width: 100%;
  height: 433px; }
  @media screen and (max-width: 768px) {
    .black-wood {
      height: auto;
      padding-bottom: 1px; } }
  .black-wood h1 {
    margin-top: 0;
    text-align: center;
    font-family: 'ChaparralPro-BoldIt';
    font-size: 40px;
    color: white;
    padding-top: 68px;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.75);
    margin-bottom: 59px;
    padding-left: 20px;
    padding-right: 20px; }
  .black-wood h2 {
    text-align: center;
    font-family: 'OpenSans-ExtraBold';
    color: white;
    font-size: 20px;
    margin-bottom: 35px;
    padding: 0 26px; }
    .black-wood h2 img {
      margin-right: 6px; }
    .black-wood h2 a {
      color: #2693FF !important;
      text-decoration: none; }

.endpapers {
  background-image: url("/media/images/map/map-papers-bottom.png");
  background-repeat: no-repeat;
  background-position-x: 55%;
  height: 140px; }
  @media screen and (max-width: 768px) {
    .endpapers {
      display: none; } }

.booking-box {
  height: 1170px; }
  @media screen and (max-width: 768px) {
    .booking-box {
      height: auto;
      background-image: url(/pliki/domek/domekajpg.jpg);
      background-size: 100%;
      background-repeat: no-repeat; } }
  .booking-box .info {
    -webkit-box-shadow: 10px 10px 29px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 29px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 10px 10px 29px 0px rgba(0, 0, 0, 0.75);
    background-image: url("/media/images/products/white-box.jpg");
    height: 726px;
    width: 661px;
    padding: 0 66px;
    position: relative; }
    .booking-box .info.left-info {
      margin-top: 265px;
      margin-left: 8.7%;
      float: left; }
      @media screen and (max-width: 768px) {
        .booking-box .info.left-info {
          margin-left: 0;
          margin-top: 63.3vw; } }
    .booking-box .info.right-info {
      margin-top: 265px;
      margin-right: 8.7%;
      float: right; }
      @media screen and (max-width: 768px) {
        .booking-box .info.right-info {
          margin-right: 0;
          margin-top: 63.3vw; } }
    @media screen and (max-width: 992px) {
      .booking-box .info {
        padding-top: 1px; } }
    @media screen and (max-width: 768px) {
      .booking-box .info {
        margin-top: 90px;
        width: inherit;
        background-size: cover;
        background-size: 100% 100%;
        height: auto;
        padding-bottom: 50px;
        margin-bottom: -1px;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none; } }
    @media screen and (max-width: 480px) {
      .booking-box .info {
        background-image: none;
        background-color: white;
        width: 100%; } }
    .booking-box .info img.bookingicon {
      position: absolute;
      top: -79px;
      left: 509px; }
      @media screen and (max-width: 768px) {
        .booking-box .info img.bookingicon {
          display: none;
          top: -82px;
          left: 34%; } }
      @media screen and (max-width: 480px) {
        .booking-box .info img.bookingicon {
          left: 27%; } }
      @media screen and (max-width: 362px) {
        .booking-box .info img.bookingicon {
          left: 19%; } }
    .booking-box .info .title {
      margin-top: 0; }
      .booking-box .info .title p {
        color: #003030;
        font-family: 'ChaparralPro-BoldIt';
        font-size: 70px;
        margin-bottom: 0px;
        text-align: center;
        line-height: 66px;
        margin-top: 20px; }
        @media all and (max-width: 393px) {
          .booking-box .info .title p.szczyrk-title {
            font-size: 43px; } }
      .booking-box .info .title .opinions {
        margin-top: 45px; }
      .booking-box .info .title img.bookinglogo {
        display: block;
        margin: 0 auto;
        margin-top: 114px; }
        @media screen and (max-width: 480px) {
          .booking-box .info .title img.bookinglogo {
            width: 218px;
            margin-top: 40px; } }
      .booking-box .info .title .subtitle {
        color: #A18A5B;
        font-size: 37px;
        font-family: 'ChaparralPro-Italic';
        line-height: 35px;
        text-align: center;
        margin-top: 14px; }
      .booking-box .info .title h1 {
        color: #2693FF;
        font-family: 'ChaparralPro-Italic';
        font-size: 22px;
        margin-top: 28px;
        margin-bottom: 20px;
        text-align: center; }
        .booking-box .info .title h1 span {
          font-family: 'ChaparralPro-BoldIt';
          color: #003030;
          font-size: 28px; }
      .booking-box .info .title h2 {
        text-align: center;
        font-family: 'ChaparralPro-BoldIt';
        color: #003030;
        font-size: 28px;
        margin-bottom: 35px;
        padding: 0 26px; }
        .booking-box .info .title h2 img {
          margin: 0;
          margin-right: 6px;
          display: inline-block;
          float: none; }
    .booking-box .info .blue-button {
      width: 226px;
      margin-top: 41px; }

.reservations-container {
  background-size: cover;
  height: 875px;
  padding-top: 200px; }
  @media screen and (max-width: 768px) {
    .reservations-container > div {
      width: 100%; } }
  @media screen and (max-width: 1200px) {
    .reservations-container {
      padding-top: 50px;
      height: 1124px; } }
  @media screen and (max-width: 768px) {
    .reservations-container {
      padding-top: 0px;
      height: auto; } }
  @media screen and (max-width: 480px) {
    .reservations-container {
      padding-top: 0px;
      height: auto; } }
  @media screen and (max-width: 1200px) {
    .reservations-container .reservation-row {
      padding: 0 24%; } }
  @media screen and (max-width: 992px) {
    .reservations-container .reservation-row {
      padding: 0 16%; } }
  @media screen and (max-width: 768px) {
    .reservations-container .reservation-row {
      padding: 0; } }
  .reservations-container .reservations-image-block {
    height: auto; }
    .reservations-container .reservations-image-block img {
      width: 100%;
      height: 513px; }
      @media screen and (max-width: 768px) {
        .reservations-container .reservations-image-block img {
          height: auto; } }
  .reservations-container .reservations-white-block {
    background: white;
    height: 513px;
    padding-top: 91px !important;
    text-align: center; }
    @media screen and (max-width: 480px) {
      .reservations-container .reservations-white-block {
        height: auto; } }
    @media screen and (max-width: 480px) {
      .reservations-container .reservations-white-block {
        padding: 19px;
        padding-top: 35px !important; } }
    .reservations-container .reservations-white-block .brown-text {
      color: #72603F;
      font-size: 24px;
      margin-bottom: 0;
      font-family: 'OpenSans-Regular'; }
      @media screen and (max-width: 480px) {
        .reservations-container .reservations-white-block .brown-text {
          font-size: 23px; } }
      .reservations-container .reservations-white-block .brown-text.preemail {
        margin-top: 25px; }
      .reservations-container .reservations-white-block .brown-text.email a {
        font-size: 28px;
        font-weight: bold;
        color: #003030;
        font-family: 'OpenSans-ExtraBold';
        text-decoration: none; }
    .reservations-container .reservations-white-block .email {
      font-family: 'OpenSans-ExtraBold';
      font-size: 22px;
      color: #003030;
      margin-top: 3px;
      margin-bottom: 0; }
      .reservations-container .reservations-white-block .email a {
        text-decoration: none;
        color: inherit; }
    .reservations-container .reservations-white-block .blue-text {
      color: #2693FF;
      font-size: 21px;
      margin-bottom: 0;
      font-family: 'OpenSans-BoldItalic';
      margin-top: 26px; }
    .reservations-container .reservations-white-block .big-text {
      font-size: 52px;
      font-weight: bolder;
      color: #003030;
      margin-bottom: 0;
      font-family: 'OpenSans-ExtraBold'; }
      @media screen and (max-width: 480px) {
        .reservations-container .reservations-white-block .big-text {
          font-size: 44px; } }
      .reservations-container .reservations-white-block .big-text a {
        color: inherit;
        text-decoration: none; }
    .reservations-container .reservations-white-block .parenthesis-text {
      font-size: 16px;
      color: #003030;
      margin-bottom: 0;
      font-family: 'OpenSans-Bold'; }

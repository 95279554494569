.sprite {
    background-image: url("/media/images/spritesheet.png");
    background-repeat: no-repeat;
    display: block;
}

.sprite-optimal {
    width: 105px;
    height: 14px;
    background-image: url('/media/images/optimal.png')!important;
}

.sprite-logo_header_small {
    width: 108px;
    height: 42px;
    background-image: url('/media/images/logo_header_small.png')!important;
}
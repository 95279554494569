#cennik {
	clear: both;
	background: url('/media/images/css/cennik-background.jpg') center;
	min-height: 440px;
	width: 100%;
	margin-bottom: -2px;
	h1 {
		font-family: 'ChaparralPro-BoldIt';
		font-size: 70px;
		color: #FFF;
		text-align: center;
		padding-top: 120px;
		margin-top: 0px;
	}
	p {
		color: #978565;
		font-size: 28px;
		font-family: 'ChaparralPro-BoldIt';
		text-align: center;
		max-width: 900px;
		margin: auto;
		padding: 30px;
	}
}

$black: #333333;
$darkgreen: #003030;
$verydarkgreen: #001818;
$blue: #2693FF;
$red: #FF4C4C;
$red2: #D93600;
$brown: #726140;
$verydarkbrown: #231605;
$lightbrown: #A18A5B;

$iphone-break: 382px;
$break-house-footer: 458px;
$break-xs: 480px;
$break-sm: 768px;
$break-md: 992px;
$break-lg: 1200px;
$column-count-4-break-point : 1556px;
$column-count-3-break-point : 1167px;
$column-count-2-break-point : 778px;
$column-count-1-break-point : 389px;
.parallax-mirror {
    z-index:1
}

a{
    background-color: transparent !important;
}

.nav .open>a, .nav .open>a:hover, .nav .open>a:focus{
    background-color: transparent !important;
}

.shadow{
    box-shadow: 10px 10px 29px 0px rgba(0, 0, 0, 0.75);
}

.shadow-on-map{
    box-shadow: 0px 10px 23px 0px rgba(0,0,0,0.75);
    position: relative;
    z-index: 1;
}

.biglogo{
    margin-left: -30px;

    @media screen and (max-width: $break-lg){
        margin-left: 20px;
    }

    @media screen and (max-width: $break-md){
        margin-left: 0px;
    }

    @media screen and (max-width: $break-sm){
        margin-left: -15px;
    }
}

.topContainer{
    -webkit-box-shadow: 0px 0px 4px 4px rgba(0,0,0,0.28);
    -moz-box-shadow: 0px 0px 4px 4px rgba(0,0,0,0.28);
    box-shadow: 0px 0px 4px 4px rgba(0,0,0,0.28);

    position: relative;
    z-index: 30;

    .top{
        .sprite-logo_header{
            width: 400px;

            @media screen and (max-width: 1854px){
                width: 300px;
            }

            @media screen and (max-width: $break-md){
                margin-left: 25px;
            }

            @media screen and (max-width: $break-xs){
                width: 60px;
            }

            .biglogo{
                @media screen and (max-width: 1854px){
                    width: 300px;
                }
            }
        }
    }
}

.checkerboard{
    background-color: $darkgreen;
}

.dropdown-menu{
    border-radius: none;
}

.header-house-select-container-top {
    background-color: $lightbrown;
    -webkit-box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.28);
    -moz-box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.28);
    box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.28);
    position: relative;
    z-index: 30;

}
.header-house-select-container{
    background-color: $lightbrown;
    height: 183px;
    display: none;

    @media screen and (max-width: $break-md){
        display: none;
    }


    .container
        {
            display:block;
            .sub
            {
                font-family: 'OpenSans-Bold';
                font-size:24px;
                color:#A28A5C;

                display:inline-block;
            }



        }
        .house{
            cursor: pointer;



            .blockB{
                background-image: url('/media/images/footerB_inactive.png');
                width: 110px;
                height: 157px;
                margin: 0 auto;

                &:hover, &.active{
                    background-image: url('/media/images/headerB_active.png');
                }
            }

            .blockA{
                background-image: url('/media/images/footerA_inactive.png');
                width: 82px;
                height: 158px;
                margin: 0 auto;

                &:hover, &.active{
                    background-image: url('/media/images/headerA_active.png');
                    width: 86px;
                }
            }

            .blockC{
                background-image: url('/media/images/footerC_inactive.png');
                width: 192px;
                height: 158px;
                margin: 0 auto;

                &:hover, &.active{
                    background-image: url('/media/images/headerC_active.png');
                }
            }

            display: inline-block;
            padding-left: 15px;
            padding-right: 15px;
            @media screen and (max-width: $break-xs){
                display: block;
                margin-bottom: 50px;
            }

            &:hover
            {
                color: #A18A5B;
            }
                color: white;

            &.houseC{
                @media all and (max-width: $break-house-footer){
                    margin-top: 17px;
                }
            }
        }

        .text-center{
                position: relative;
                margin-top: 16px;

                .spacer
                {
                    width: 1px !important;
                    height: 148px;
                    background-color: #8A754F;
                    display: inline-block;
                    padding: 0;

                    @media screen and (max-width: $break-lg){
                        position: absolute;
                        bottom: 0;
                    }

                    @media screen and (max-width: 450px){
                        display: none;
                    }
                }
            }


}
#gallery-container
{
    
    .hidden-owl-gallery
    {
      @media screen and (min-width: 1025px)
      {
        display:none !important;
      }
      @media screen and (max-width: 1025px)
      {
        display:visible !important;
      }
    }
    .hidden-non-owl-gallery
    {
      @media screen and (max-width: 1024px)
      {
        display:none !important;
      }
      @media screen and (min-width: 1024px)
      {
        display:visible !important;
      }
    }
    background-color:#003030;

    h2
    {
        background-color:#003030;
        text-align:center;
        color:white;
        font-size:70px;
        font-family: 'ChaparralPro';
        font-weight:bold;
        font-style:italic;
        margin:0;
        padding-top:120px;
    }
    h3
    {
        background-color:#003030;
        text-align:center;
        color:#9F8A59;
        font-size:40px;
        font-family: 'ChaparralPro';
        font-style:italic;
        margin:0;
        padding-top:30px;
        margin-bottom:80px;
    }
    .gallery-button
    {
        transition:all 0.5s ease;
        display:inline-block;
        padding: 12px 15px;
        margin-bottom:100px;
        line-height: 56px;
        font-family: 'OpenSans-Bold';
        font-size:17px;
        cursor:pointer;
        height: 56px;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari*/
        -khtml-user-select: none; /* Konqueror */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently*/
        &.folded
        {
            &:before
            {
                content:attr(data-fold);
                transition:all 0.5s ease;
                position: relative;
                top: -12px;
            }
            &:after
            {
                content: "›";
                display: inline-block;
                -webkit-transform: rotate(90deg) scale(1,1.5) translate(0px, -5px);
                -moz-transform: rotate(90deg) scale(1,1.5) translate(0px, -5px);
                -o-transform: rotate(90deg) scale(1,1.5) translate(0px, -5px);
                -ms-transform: rotate(90deg) scale(1,1.5) translate(0px, -5px);
                transform: rotate(90deg) scale(1,1.5) translate(0px, -5px);
                transition:all 0.5s ease;
                position: relative;
                top: -12px;
            }
            transition:all 0.2s ease;
            border: 2px solid white;
            background-color:#003030;
            color:white;
            &:hover
            {
            transition:all 0.2s ease;
                color:#003030;
                background-color:white;
            }

        }
        &.unfolded
        {
            &:before
            {
                content:attr(data-unfold);
                transition:all 0.5s ease;
                position: relative;
                top: -12px;
            }
            &:after
            {
                content: "›";
                display: inline-block;
                -webkit-transform: rotate(-90deg) scale(1,1.5);
                -moz-transform: rotate(-90deg) scale(1,1.5);
                -o-transform: rotate(-90deg) scale(1,1.5);
                -ms-transform: rotate(-90deg) scale(1,1.5);
                transform: rotate(-90deg) scale(1,1.5);
                transition:all 0.5s ease;
                position: relative;
                top: -12px;
            }
            transition:all 0.2s ease;
            border: 2px solid #A28A5C;
            background-color:#003030;
            color:#A28A5C;
            &:hover
            {
                transition:all 0.2s ease;
                color:#003030;
                background-color:#A28A5C;
            }
        }
    }
    .content2.folded
    {
        height:500px ;
        overflow:hidden;
        position :relative;
        transition:all 1s ease,height 1s ease;
        .foreground
        {
            background: linear-gradient(rgba(0,48,48,0), rgba(0,48,48,1)); /* Standard syntax */
            transition:top 1s ease,opacity 1s ease;
            position:absolute;
            left:0px;
            top:-100px;
            width:100vw;
            height:500px;
            opacity:1;
            z-index:500;
        }
        .foreground-filler
        {
            width:100%;
            height:100px;
            background:#003030;
            position:absolute;
            bottom:0;
            left:0;
            transition: top 1s ease,opacity 1s ease;

            opacity:1;
        }
        .gallery-container
        {
            position:absolute;
            width:100vw;
            height:auto;
        }

    }
    .content2.unfolded
    {
        height: 1190px;
        overflow:hidden;
        position :relative;
        transition:all 1s ease,height 1s ease;
        @media only screen and (max-width: $column-count-4-break-point) and (min-width: $column-count-3-break-point) {
            height:1500px !important;
        }
        @media only screen and (max-width: $column-count-3-break-point) and (min-width: $column-count-2-break-point) {
            height:1300px !important;
        }
        .gallery-container
        {
            position:relative;
            width:100vw;
            height:auto;
        }
        .foreground
        {
            pointer-events: none;
            background: linear-gradient(rgba(0,48,48,0), rgba(0,48,48,1)); /* Standard syntax */
            transition: top 1s ease,opacity 1s ease;
            position:absolute;
            opacity:0;
            left:0px;
            top:690px;
            width: 100vw;
            height: 500px;
            z-index:500;
        }
        .foreground-filler
        {
            pointer-events: none;
            transition: top 1s ease,opacity 1s ease;
            width:100%;
            height:100px;
            background:#003030;
            position:absolute;
            bottom:0;
            left:0;
            opacity:0;
        }
    }
    .content
    {
        overflow: initial;
        text-align:center;
        max-width: 100%;
        display:inline-block;
        @media only screen and (min-width: $column-count-4-break-point) {
            column-count: 4;
            -moz-column-count: 4;
            -webkit-column-count: 4;
            height:830px;
        }
        @media only screen and (max-width: $column-count-4-break-point) and (min-width: $column-count-3-break-point) {
            column-count: 3;
            -moz-column-count: 3;
            -webkit-column-count: 3;
            height:990px;
        }
        @media only screen and (max-width: $column-count-3-break-point) and (min-width: $column-count-2-break-point) {
            column-count: 2;
            -moz-column-count: 2;
            -webkit-column-count: 2;
            height:1320px;
        }
        @media only screen and (max-width: $column-count-1-break-point){
            column-count: 1;
            -moz-column-count: 1;
            -webkit-column-count: 1;

        }
        -webkit-column-gap: 30px;
        -webkit-column-fill: auto;
        -moz-column-gap: 30px;
        -moz-column-fill: auto;
        column-gap: 30px;
        column-fill: auto;
        .img-container
        {
            display: inline-block;
            /* position: relative; */
            overflow: visible;
            @media only screen and (min-width: $column-count-4-break-point) {
                &:nth-child(3),&:nth-child(7)
                {
                    margin-top:170px;
                }
            }
            @media only screen and (max-width: $column-count-4-break-point) and (min-width: $column-count-3-break-point) {
                &:nth-child(4)
                {
                    margin-top:170px;
                }

            }
            @media only screen and (max-width: $column-count-3-break-point) and (min-width: $column-count-2-break-point) {
                &:nth-child(6)
                {
                    margin-top:170px;
                }
                &:nth-child(5)
                {
                    display:none;
                }

            }
            @media only screen and (max-width: $column-count-1-break-point){

            }
            .image
            {
                margin-left: -200px;
                width:389px;
                height:260px;
                transition:all 0.3s ease;
                &:hover
                {    margin-left: -208px;
                    margin-top: 0px;
                    transition:all 0.3s ease;
                    width:406px;
                    height:283px;
                    z-index: 1;
                    -webkit-box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.75);
                    -moz-box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.75);
                    box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.75);

                }
                position: absolute;
                /* left: 0; */
                z-index: initial;
            }
            margin-bottom:65px;
            width:389px;
            height:260px;
        }
    }
    .content2 .gallery-container
    {
        overflow: initial;
        text-align:center;
        max-width: 100%;
        @media only screen and (min-width: $column-count-4-break-point) {
            column-count: 4;
            -moz-column-count: 4;
            -webkit-column-count: 4;
            height: 1200px !important;
        }
        @media only screen and (max-width: $column-count-4-break-point) and (min-width: $column-count-3-break-point) {
            column-count: 3;
            -moz-column-count: 3;
            -webkit-column-count: 3;
            height:1500px !important;
        }
        @media only screen and (max-width: $column-count-3-break-point) and (min-width: $column-count-2-break-point) {
            column-count: 2;
            -moz-column-count: 2;
            -webkit-column-count: 2;
            height:1320px !important;
        }
        @media only screen and (max-width: $column-count-1-break-point){
            column-count: 1;
            -moz-column-count: 1;
            -webkit-column-count: 1;

        }
        -webkit-column-gap: 30px;
        -webkit-column-fill: auto;
        -moz-column-gap: 30px;
        -moz-column-fill: auto;
        column-gap: 30px;
        column-fill: auto;
        .img-container
        {
            display: inline-block;
            /* position: relative; */
            overflow: visible;
            @media only screen and (min-width: $column-count-4-break-point) {
                &:nth-child(4),&:nth-child(10)
                {
                    margin-top:170px;
                }
            }
            @media only screen and (max-width: $column-count-4-break-point) and (min-width: $column-count-3-break-point) {
                &:nth-child(1),&:nth-child(9)
                {
                    margin-top:170px;
                }

            }
            @media only screen and (max-width: $column-count-3-break-point) and (min-width: $column-count-2-break-point) {
                &:nth-child(1)
                {
                    margin-top:170px;
                }

            }
            @media only screen and (max-width: $column-count-1-break-point){

            }
            .image
            {
                width:389px;
                height:260px;
                transition:all 0.3s ease;
                margin-left: -200px;
                &:hover
                {    margin-left: -208px;
                    margin-top: 0px;
                    transition:all 0.3s ease;
                    width:406px;
                    height:283px;
                    z-index:1;
                    -webkit-box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.75);
                    -moz-box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.75);
                    box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.75);

                }
                position: absolute;
                /* left: 0; */
                z-index: initial;
            }
            margin-bottom:65px;
            width:389px;
            height:260px;
        }
    }
}
.caption1,.caption2,.caption3
{
    background-color:white;
    font-family: 'ChaparralPro-BoldIt';
    font-size:43px;
    line-height: 110%;
    width: auto !important;
    padding:20px 20px;
    .lead
    {
        font-weight: normal;
        font-style: italic;
        font-family: 'ChaparralPro';
        font-size: 33px;
        line-height: 80%;
        line-spacing: 0em;

        @media screen and (max-width: $break-xs){
            font-size: 27px;
        }
    }
}

.caption1{

    @media all and (max-width: $break-sm){
        top: 0 !important;
    }
}

.caption1:after,.caption2:after,.caption3:after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-top-color: white;
    border-width: 30px;
    margin-left: -30px;
}
body{
    overflow-x: hidden;
    overflow-y: scroll;
}

.layout {
    background-image: url('/media/image/layout.png');
}

.blue-button-container{
  text-align: center;

  .blue-button{
    background-color: $blue;
    width: 311px;
    height: 56px;
    text-align: center;
    display: inline-block;
    font-size: 17px;
    color: white;
    font-family: 'OpenSans-Bold';
    line-height: 56px;
    cursor: pointer;
  }
}

.blur {
    position: fixed;
    width: 100%;
    height: 100%;
    background: $darkgreen;
    z-index: 50;
    top: 0;
    opacity: 0;
    transition: opacity 1s ease;
    display: none;
}
.blur.active {
    opacity: 0.8;
    transition: opacity 1s ease;
    display: block;
}
body.blurred
{
    filter: blur(10px);
    transition: filter 1s ease;
}
.non-blur
{
    filter : none !important;
}

.sprite-conMail {
    width: 44px;
    height: 32px;
    background: url('/media/images/mail.png');
}

.sprite-tel {
    width: 65px;
    height: 65px;
    background: url('/media/images/tel1.png');
}

header {
    position: relative;
    .phone_header{
        text-align: right;
        font-size: 25px;
        color:#003030;
        margin-right: 30px;

        @media all and (max-width: $break-lg){
            display: none;
        }

        a{
            text-decoration: none;
            color: inherit;
        }
    }
    .transform {
        transition: all 0.5s;
    }
    .float-left {
        float: left;
    }
    .contact {
        font-size: 14px;
        position: fixed;
        right: 0;
        top: 40%;
        width: 72px;
        z-index: 999;
        background-image: url('/media/images/right_contact.png');
        height: 104px;

        @media screen and (max-width: $break-lg){
            top: 65%;
        }

        @media screen and (max-width: $break-sm){
            top: 30%;
        }

        .header {
            cursor: pointer;
            width: 84px;
            z-index: 5;
            height: 104px;
            position: absolute;
            left: 0;
            span {
                color: #FFF;
                display: block;
                font-size: 1.15em;
                width: 230px;
                left: -60px;
                bottom: 100px;
                position: absolute;
                // -ms-transform: rotate(-90deg); /* IE 9 */
                // -webkit-transform: rotate(-90deg); /* Chrome, Safari, Opera */
                // transform: rotate(-90deg);
            }
        }
        .phonenumber{
            position: absolute;
            color: $red2;
            font-size: 35px;
            font-family: 'OpenSans-ExtraBold';
            top: 26px;
            left: 96px;
            text-decoration: none;
        }
        .txt {
            background: #FFF;
            position: absolute;
            width: 243px;
            height: 233px;
            right: -243px;
            top: 13px;
            .top {
                height: 57px;
                background: #E94F53;
                .marketing {
                    font-weight: 700;
                    color: #FFF;
                    font-size: 1.15em;
                    text-align: center;
                    padding-top: 18px;
                }
                .who {
                    margin-top: 10px;
                    .photo {
                        width: 73px;
                        height: 73px;
                        float: left;
                        background-image: url('/media/images/marketing.png');
                    }
                    .name {
                        color: #333;
                        text-align: center;
                        .el1 {
                            font-size: 1.7em;
                        }
                        .el2 {
                            font-size: 2.5em;
                            font-weight: 600;
                            line-height: 20px;
                        }
                    }
                }


            }
            .contactUs {
                    text-align: center;
                    margin: auto;
                .phone {
                    margin-top: 17px;
                    a {
                        color: #333;
                        font-size: 1.85em;
                        .el1 {
                            font-weight: 100;
                            display: inline;
                        }
                        .el2 {
                            font-weight: 400;
                            display: inline;
                        }
                        .el3 {
                            font-weight: 900;
                            display: inline;
                        }
                    }
                }
                .mail {
                    text-align: center;
                    padding-top: 15px;
                    clear: both;
                    a {
                        color: #333;
                        font-size: 1.15em;
                        margin-top: -10px;
                        font-weight: 800;
                    }
                }
            }
        }
    }
    .contact.active {
        filter: none!important;
        width: 315px;
        .txt {
                right: 0;
                width: 243px;
            .top {

            }
        }
    }
    .topMenu, .searchMenu {
        display: none;
    }
    #menubutton {
        display: none;
        width: 37px;
        height: 36px;
        z-index: 999;
        background-image: url('/media/images/menu.png');
        background-repeat: no-repeat;
        background-position: -34px 0;
        -webkit-transform: translate3d(0,0,0);

        @media all and (max-width: $break-lg){
            display: block;
        }
    }
    #menubutton.openIcon {
        background-position: 3px 0;
    }
    .top {
        width: 100%;
        position: relative;
        z-index: 1;
        .sprite-logo_header {
            margin: 10px 40px;
            margin-right: 0;
            float: left;

            .smalllogo{
                display: none;
                margin-left: -15px;

                @media all and (max-width: $break-xs){
                    display: block;
                    margin-top: 20px;
                    padding: 0;
                }

                @media all and (max-width: $iphone-break){
                    width: 200px;

                }
            }

            .biglogo{
                

                @media all and (max-width: $break-xs){
                    display: none;
                }
            }
        }


    }
    .right {
        position: absolute;
        right: 40px;
        top: 20px;
        .row {
            float: left;
            margin-left: 45px;
            cursor: pointer;
        }
    }
    #menu {
        background-color: #FFFFFF;
        width: 100%;
        height: 55px;
        position: relative;

        .shadow-mobile{
            display: none;
            background-image: url('/media/images/shadow-mobile.png');
            width: 33px;
            height: 1725px;
            z-index: 999;
            @media screen and (max-width: $break-lg){
                position: absolute;
                display: block;
                top: 0;
                left: 0;
            }
        }

        .lang {
            display: none;
        }
        ul {
            float: right;
            font-size: 16px;
            @media screen and (max-width: $break-lg){
                font-size: 18px;
            }
            @media screen and (max-width: 1373px){
                font-size: 13px;
            }
            &.dropdown-menu.domki {
                position: relative;
                height: 180px;
                width:600px;
                margin-right:-220px;
                background-color:#A28A5C;
                display: none;

                @media screen and (max-width: $break-lg){
                    display: block;
                }
            }
            li {
                padding-top: 0px;
                padding-right: 0px;
                a {
                    color: #A28A5C;
                    font-weight:bold;

                    &:hover{
                        color: $darkgreen;
                    }
                }
                ul {
                    padding: 0;
                    border: none;
                    background: $black;
                    li:hover {
                        background-color: rgba(233,79,83,0.3);
                    }
                    li {
                        background-color: #4D4C4C;
                        padding-top: 8px;
                        padding-bottom: 8px;
                        a {
                            color: #FFF!important;
                            font-size: 1em;
                        }
                    }
                }
            }
        }
    }
}


    .wood-footer-container {
        background: url('/media/images/woodfooter.jpg');
        height: 462px;
        text-align: center;
        padding: 45px 0;
        color: white;
        -webkit-box-shadow: inset 0px 210px 96px -178px rgba(0, 0, 0, 0.8),inset 0px -210px 96px -178px rgba(0, 0, 0, 0.8);
        -moz-box-shadow: inset 0px 210px 96px -178px rgba(0, 0, 0, 0.8),inset 0px -210px 96px -178px rgba(0, 0, 0, 0.8);
        box-shadow: inset 0px 210px 96px -178px rgba(0, 0, 0, 0.8),inset 0px -210px 96px -178px rgba(0, 0, 0, 0.8);

        img.wood-footer-upper-shadow
        {
            margin-top: -45px;
            position: absolute;
            left: 0;
        }

        @media screen and (max-width: $break-md){
            height: 2000px;
        }

        .wood-logo{
            @media screen and (max-width: $break-xs){
                width: 277px;
            }
        }

        .wood-title{
            font-family: "ChaparralPro-Bold";
            font-size: 32px;
            font-style: italic;
            text-shadow: 0px 1px 7px #000000;
            line-height: 32px;
            margin-top: 32px;
            // margin-left: 30vw;
            // margin-right: 30vw;
            margin-bottom: 10px;
        }

        .address{
            font-family: "OpenSans-Bold";
            font-size: 20px;
            margin-bottom: 10px;

        }

        a, p{
            color: white;
            font-size: 16px;
            font-family: 'OpenSans-Bold';

            &.phone{
                font-size: 20px;
            }

            span{
                font-size: 20px;
            }
        }

        .mail-icon{
            margin-bottom: 26px;
        }

        .phone-icon{
            margin-bottom: 14px;
        }

        .marker-icon{

        }

        .contact-col{
            margin-top: 20px;
        }



    }

footer {
    font-family: lato!important;
    position: relative;

    .wood-footer-container{
        background: url('/media/images/woodfooter.jpg');
        height: 462px;
        text-align: center;
        padding: 45px 0;
        color: white;

        img.wood-footer-upper-shadow
        {
            margin-top: -45px;
            position: absolute;
            left: 0;
        }

        @media screen and (max-width: $break-md){
            height: auto;
        }

        .wood-logo{
            @media screen and (max-width: $break-xs){
                width: 277px;
            }
        }

        .title{
            font-family: "ChaparralPro-Bold";
            font-size: 32px;
            font-style: italic;
            text-shadow: 0px 1px 7px #000000;
            line-height: 32px;
            margin-top: 32px;
            // margin-left: 30vw;
            // margin-right: 30vw;
            margin-bottom: 10px;
        }

        .address{
            font-family: "OpenSans-Bold";
            font-size: 20px;

        }

        a, p{
            color: white;
            font-size: 16px;
            font-family: 'OpenSans-Bold';
        }

        .mail-icon{
            margin-bottom: 26px;
        }

        .phone-icon{
            margin-bottom: 14px;
        }

        .marker-icon{

        }

        .contact-col{
            margin-top: 20px;
        }

    }

    .house-select-container{
        background: $darkgreen;
        height: 383px;
        border-bottom: solid #001818 2px;
        font-family: 'ChaparralPro-Bold' ;

        -webkit-box-shadow: 0px -16px 30px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px -16px 30px 0px rgba(0,0,0,0.75);
        box-shadow: 0px -16px 30px 0px rgba(0,0,0,0.75);

        @media screen and (max-width: $break-xs){
            height: auto;
        }

        .container
        {
            display:block;
            .sub
            {
                font-family: 'OpenSans-Bold';
                font-size:24px;
                color:#A28A5C;

                display:inline-block;
            }
        }
        .house-select-title
        {
            text-align:center;
            font-size:45px;
            font-weight:bold;
            font-style:italic;
            color:#A18A5B;
            padding: 50px 30px;

            @media screen and (max-width: $break-sm){
                font-size: 40px;
            }

            @media screen and (max-width: 647px){
                font-size: 30px;
            }
        }
        .house{
            cursor: pointer;



            .blockB{
                background-image: url('/media/images/footerB_inactive.png');
                width: 110px;
                height: 157px;
                margin: 0 auto;

                &:hover, &.active{
                    background-image: url('/media/images/footerB_active.png');
                }
            }

            .blockA{
                background-image: url('/media/images/footerA_inactive.png');
                width: 82px;
                height: 158px;
                margin: 0 auto;

                &:hover, &.active{
                    background-image: url('/media/images/footerA_active.png');
                }
            }

            .blockC{
                background-image: url('/media/images/footerC_inactive.png');
                width: 192px;
                height: 158px;
                margin: 0 auto;

                &:hover, &.active{
                    background-image: url('/media/images/footerC_active.png');
                }
            }

            display: inline-block;
            padding-left: 15px;
            padding-right: 15px;
            @media screen and (max-width: $break-xs){
                display: block;
                margin-bottom: 50px;
            }

            &:hover
            {
                color: #A18A5B;
            }
                color: white;

            &.houseC{
                @media all and (max-width: $break-house-footer){
                    margin-top: 17px;
                }
            }
        }
        .text-center{
            position: relative;

            .spacer
            {
                width: 1px !important;
                height: 148px;
                background-color: black;
                display: inline-block;
                padding: 0;

                @media screen and (max-width: $break-lg){
                    position: absolute;
                    bottom: 0;
                }

                @media screen and (max-width: $break-xs){
                    display: none;
                }
            }
        }



    }

    .menu-footer-container{
        background: $darkgreen;

        line-height:119px;
        text-align:center;

        @media screen and (max-width: $break-lg){
            display: none;
        }
        a
        {
            padding: 0 15px;
            font-family: 'OpenSans-Bold';
            font-size:16px;
            color: #A28A5C;

            &.active, &:hover{
                color: white;
            }


        }


    }

    .copyright-container{
        background: $verydarkgreen;
        color: #225750;
        font-family: 'OpenSans-Regular';
        font-size:16px;
        padding-top:30px;

        span{
            font-family: 'OpenSans-Bold';
        }

        a{
            color: #225750;
            text-decoration: none;
        }
    }


    .liniaFooter {
        clear: both;
        height: 25px;
        width: 100%;
        border-bottom: #33464D solid 1px;
        background: #001820;
    }
    .navigation {
        position: fixed;
        right: 30px;
        z-index: 999;
        opacity: 0.5;
        cursor: pointer;
    }
    #toHome {
        bottom: 20px;
    }
    #toTop {
        bottom: 20px;
        display: none;
    }
    .tooltip.top {
        .tooltip-arrow {
            border-top-color: #E94F53;
        }
        .tooltip-inner{
            color: #E94F53;
            background: #FFF;
            border: 1px solid #E94F53;
            border-radius: 0!important;
        }
    }
    .navigation:hover {
        opacity: 1;
    }
    #map2 {
        width: 100%;
        height: 550px;

        -webkit-box-shadow: inset 0px 13px 41px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: inset 0px 13px 41px 0px rgba(0,0,0,0.75);
        box-shadow: inset 0px 13px 41px 0px rgba(0,0,0,0.75);
    }
    .mapElement {
        .txt {
            position: absolute;
            z-index: 10;
            background-image: url('/media/images/map.png');
            width: 639px;
            height: 240px;
            top: 200px;
            padding-left: 290px;
            padding-top: 30px;
            .el1 {
                font-size: 36px;
                color: #E94F53;
                letter-spacing: -1px;
                font-family: 'FuturaLTPro-Book';
            }
            .el2 {
                color: #444444;
                font-size: 20px;
                line-height: 30px;
            }
        }
    }

    .mapElementContact {
        .txt {
            display: none;
            position: absolute;
            z-index: 10;
            background-image: url('/media/images/map_contact.png');
            width: 639px;
            height: 240px;
            top: 200px;
            padding-left: 290px;
            padding-top: 30px;
            .el1 {
                font-size: 36px;
                color: #E94F53;
                letter-spacing: -1px;
            }
            .el2 {
                color: #444444;
                font-size: 20px;
                line-height: 30px;
            }
        }
    }

    #aboveMap{
        display: block;
    }

    #aboveMapContact{
        display: none;

        a{
            display: inherit;
            margin: 0 auto;
            img {
                display: inherit;
                margin: 0 auto;
                @media screen and (max-width: 480px) {
                    width: 100%;
                }
            }
        }
    }


    .content {
        width: 100%;
        background: #001820;
        text-align: center;
        margin: auto;
        padding-top: 120px;
        .sprite-logo_footer {
            margin-top: 15px;
        }
        .row {
            margin-bottom: 40px;
        }
        .contact {
            text-align: left;
            .tel {
                float: left;
                span {
                    float: left;
                }
                a {
                    font-size: 2.1em;
                    color: #FFF;
                    float: left;
                    padding-left: 15px;
                }
            }
            .mail {
                clear: both;
                float: left;
                margin-top: 30px;
                margin-left: 15px;
                span {
                    float: left;
                }
                a {
                    font-size: 1.4em;
                    color: #FFF;
                    padding-left: 15px;
                }
            }
        }
        .formContact {
            a {
                margin-top: 15px;
                border: #E44E52 solid 1px;
                border-radius: 45px;
                color: #E44E52;
                font-size: 1.85em;
                padding: 25px;
                font-family: 'Lato';
            }
            a:hover {
                color: #333;
                background: #E44E52;
            }
        }
    }
    .menuFooter {
        max-width: 1400px;
        width: 100%;
        margin: auto;
        margin-top: -120px;
        padding: 10px;
        .head {
            color: #008AC1;
            font-size: 1.85em;
            text-align: left;
            font-family: 'FuturaLTPro-Book';
        }
        .body {
            text-align: left;
            margin-top: 3px;
            margin-bottom: 50px;
            line-height: 40px;
            a {
                display: inline;
                padding-right: 33px;
                color: #878787;
                word-wrap: inherit;
                white-space: nowrap;
                text-transform: uppercase;
            }
            a.active {
                color: #FFF;
            }
            a:hover {
                color: #FFF;
            }
        }
    }
    .Copyright {
        color: #878787;
        margin-top: 55px;
        a {
            color: #878787;
            display: inline;
            padding-left: 15px;
            margin-left: 15px;
            border-left: 1px solid #878787;
            font-size: 15px;
        }
        div {
            display: inline;
            font-size: 15px;
        }
    }
    .execution {
        margin-top: 40px;
        padding-bottom: 50px;
        a {
            display: inline-table;
        }
    }
    #conMail {
        position: fixed;
        bottom: 150px;
        right: 10px;
        z-index: 10;
        .head {
            display: none;
            width: 290px;
            height: 53px;
            background: #EBEBEB;
            position: absolute;
            right: 5px;
            top: 6px;
            border-radius: 85px;
            -webkit-box-shadow: 0px 0px 51px -2px rgba(0,0,0,0.75);
            -moz-box-shadow: 0px 0px 51px -2px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 51px -2px rgba(0,0,0,0.75);

        }
        .sprit {
            position: absolute;
            right: -5px;
            top: -6px;
        }
        a {
            font-size: 16px;
            text-align: center;
            font-family: 'lato';
            font-weight: bold;
            color: #333;
            padding-left: 16px;
            line-height: 50px;
        }
    }
    #conMail.active .head {
        display: block;
    }
    #phone {
        position: fixed;
        bottom: 80px;
        right: 10px;
        z-index: 10;
        .head {
            display: none;
            width: 290px;
            height: 53px;
            background: #EBEBEB;
            position: absolute;
            right: 5px;
            top: 6px;
            border-radius: 85px;
            -webkit-box-shadow: 0px 0px 51px -2px rgba(0,0,0,0.75);
            -moz-box-shadow: 0px 0px 51px -2px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 51px -2px rgba(0,0,0,0.75);
        }
        .sprit {
            position: absolute;
            right: -5px;
            top: -6px;
        }
        a {
            font-size: 26px;
            text-align: center;
            font-family: 'lato';
            font-weight: bold;
            color: #333;
            padding-left: 55px;
            line-height: 50px;
        }
    }
    #phone.active .head {
        display: block;
    }
}
.nav-pills>li.active>div.licontainer>a, .nav-pills>li.active>div.licontainer>a:hover, .nav-pills>li.active>div.licontainer>a:focus, .nav-pills li>a:hover {
    background: no-repeat!important;
    border: none!important;
    text-decoration: none!important;
    color: $darkgreen !important;

    @media screen and (max-width: $break-lg){
        color: white !important;
    }
}
.nav-pills>li.active>a {
    font-weight: 600;
}
.nav-pills li>a {
    border: none!important;
}
header #menu ul li ul li:hover {
}

@media screen and (min-width: $break-lg) {
    header {


        #menuHeight {
            height: 50px;
            margin-right: 15px;
        }
        #search.fixed {
            position: fixed;
            top: 10px!important;
            z-index: 20;
            -webkit-box-shadow: 0px 7px 14px -5px rgba(0,0,0,0.14);
            -moz-box-shadow: 0px 7px 14px -5px rgba(0,0,0,0.14);
            box-shadow: 0px 7px 14px -5px rgba(0,0,0,0.14);
        }
        #search {
            display: none;
            width: 100%;
            background: #FFF;
            position: relative;
            text-align: center;
            margin: auto;
            color: #333;
            font-size: 4.7em;
            margin-top: 40px;
            .closeSearch {
                position: absolute;
                right: 25px;
                top: 0;
                cursor: pointer;
            }
            .closeSearch:hover {
                background-position: -259px -98px;
            }
            input.txt {
                border: none;
                margin-top: 2px;
                background: transparent;
                width: 80%;
                color: #333;
                text-align: center;
            }
            button {
                display: block;
                text-align: center;
                margin: auto;
                margin-top: 55px;
                width: 330px;
                height: 80px;
                background: #E94F53;
                color: #FFF;
                font-size: 0.4em;
                font-family: lato;
                border-radius: 85px;
                line-height: 1px;
                span {
                    font-size: 0.8em;
                }
            }
            div.txt {
                color: #333;
                font-size: 0.22em;
                margin-top: 15px;
                padding-bottom: 80px;
            }
        }
        .belka {
            display: none;
            position: fixed;
            background-color: #FFF;
            //height: 52px;
            width: 100%;
            z-index: 999;
            top: 0;
            -webkit-box-shadow: 0px 0px 4px 4px rgba(0,0,0,0.28);
            -moz-box-shadow: 0px 0px 4px 4px rgba(0,0,0,0.28);
            box-shadow: 0px 0px 4px 4px rgba(0,0,0,0.28);

            .belkarow{
                margin-right: 15px;
            }

            .sprite-logo_header_small
            {
                width:inherit !important;
                display:inline-block !important;
            }
            .left {
                margin-top: 7px;
                margin-left: 60px;
                margin-bottom: 7px;
                #menu2,ul,ul > li > a
                {
                    display:inline-block;
                    float: right;
                    color: #A28A5C !important;
                    font-size: 1em;
                    font-weight:bold;
                }

                #menu2,ul,ul > li.active > a
                {
                    display:inline-block;
                    float: right;
                    color: #001818 !important;
                    font-size: 1em;
                    font-weight:bold;
                }

            }
            .right {
                top: 10px;
                right: 50px;
            }
            .dropdown-menu.domki{
                display: none;
            }
        }
        .belka.openMenu {
            -webkit-box-shadow: none!important;
            -moz-box-shadow: none!important;
            box-shadow: none!important;
        }
        #menu.trans,#menu2.trans {
            transition: all 0.5s;
        }
        #menu.openMenu {
            position: fixed;
            top: 52px;
            z-index: 10;
            -webkit-box-shadow: 0px 7px 14px -5px rgba(0,0,0,0.14);
            -moz-box-shadow: 0px 7px 14px -5px rgba(0,0,0,0.14);
            box-shadow: 0px 7px 14px -5px rgba(0,0,0,0.14);
        }
    }
}
@media screen and (max-width: 767px) {

        .belka #menubutton.down.top {
            right: 0px;
        }
        .belka #menubutton.down.top.openMenu {
            right: 440px;
        }
    footer {
        .Copyright {
            a, div {
                border: 0;
                display: block;
                margin-bottom: 10px;
            }
        }
        #toHome {
            display: none;
        }
        #toTop {
            bottom: 10px;
            right: -30px;
            opacity: 1;
        }
        .contac {
                margin: 40px auto;
            .left {
                border-right: none!important;
                border-bottom: 1px solid #bbb;
                padding: 30px 0;
                text-align: center;
                padding-bottom: 70px!important;
                margin-bottom: 40px;
                .sprite {
                    margin: auto;
                }
            }
            .right {
                padding-left: 0;
                text-align: center;
            }
        }
    }
}
@media screen and (max-width: $break-lg) {
    header {
        #menu{
            ul{
                li{
                    ul.dropdown-menu.domki{
                        border-radius: none;
                    }

                }
            }
        }

        #search {
            display: none;
        }
        .right {
            display: none;
        }
        .top .sprite-logo_header {
            margin: 10px auto;
        }
        .topMenu {
            display: block;
            color: $darkgreen;
            font-size: 49px;
            margin-left: 49px;
            font-family: 'ChaparralPro-BoldIt';
        }
        .searchMenu {
            display: block;
            background-color: #F5F5F5;
            -webkit-box-shadow: inset 91px 3px 58px -74px rgba(0,0,0,0.75);
            -moz-box-shadow: inset 91px 3px 58px -74px rgba(0,0,0,0.75);
            box-shadow: inset 91px 3px 58px -74px rgba(0,0,0,0.75);
            position: relative;
            input {
                width: 300px;
                z-index: 0;
                border: none;
                height: 75px;
                font-size: 2em;
                background-color: #F5F5F5;
                margin-left: 75px;

                @media screen and (max-width: $break-xs){
                    width: 195px;
                }

            }
            span {
                position: absolute;
                top: 24px;
                left: 23px;
            }

        }
        .belka {
            display: hidden;
            #menubutton {
                position: fixed;
                right: 40px;
                top: 25px;
            }
            #menubutton.down {
                width: 100px;
                height: 100px;
                background: url('/media/images/menu1.png');
            }
            #menubutton.down.top {
                top: 10px;
            }
            .left {
                display: none;
            }
            .right {
                display: block;
                .row {
                    display: none;
                }
            }
        }
        #menu {
            overflow: auto;
            overflow-x: hidden;
            position: fixed;
            background-color: #FFF;
            right: 0;
            top: 0;
            width: 0;

            height: 100%;
            // -webkit-box-shadow: inset 91px 3px 58px -74px rgba(0,0,0,0.75);
            // -moz-box-shadow: inset 91px 3px 58px -74px rgba(0,0,0,0.75);
            // box-shadow: inset 91px 3px 58px -74px rgba(0,0,0,0.75);
            ul {
                float: left;
                padding-left: 0;
                width: 100%;
                width: 400px; /*dodane*/
                li {
                    background-color: transparent!important;
                    color: #333;
                    width: 100%;
                    border-top: 1px solid #EBEBEB;
                    ul {
                        &.dropdown-menu.domki {
                            position: relative;
                            height: auto;
                            z-index: 5;
                            box-shadow: none;
                            display: none;

                            .domek-side-menu{
                                border-top: solid 1px white;
                                padding: 20px 50px;

                                @media screen and (max-width: $break-lg){
                                    padding: 20px 50px;
                                }

                                

                                #blockB{
                                    background-image: url('/media/images/domekb_side_menu.png');
                                    width: 110px;
                                    height: 133px;
                                    margin-left: 24px;

                                    &:hover, &.picked{
                                        background-image: url('/media/images/domekb_side_menu_active.png');
                                        width: 113px;
                                    }
                                }

                                #blockA{
                                    background-image: url('/media/images/domeka_side_menu.png');
                                    width: 87px;
                                    height: 145px;
                                    margin-left: 24px;

                                    &:hover, &.picked{
                                        background-image: url('/media/images/domeka_side_menu_active.png');
                                    }
                                }

                                #blockC{
                                    background-image: url('/media/images/domekc_side_menu.png');
                                    width: 192px;
                                    height: 161px;
                                    margin-left: 24px;

                                    &:hover, &.picked{
                                        background-image: url('/media/images/domekc_side_menu_active.png');
                                        width: 195px;
                                    }
                                }
                            }
                        }
                        margin-left: -35px;
                        -webkit-box-shadow: inset 91px 3px 58px -74px rgba(0,0,0,0.75);
                        -moz-box-shadow: inset 91px 3px 58px -74px rgba(0,0,0,0.75);
                        box-shadow: inset 91px 3px 58px -74px rgba(0,0,0,0.75);
                        background-color: #F5F5F5;
                        width: 400px;
                        margin-bottom: 15px;
                        li {
                            margin-left: 40px;
                        }
                    }
                    ul li a {
                        color: #333!important;
                        width: 240px;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        font-size: 0.9em;
                        white-space: normal;
                        .glyphicon {
                            display: none;
                        }
                    }
                    ul li.subactive a  {
                        color: $red !important;
                    }
                }
            }
            .lang {
                float: right;
                display: block;
                div {
                    a {
                        float: left;
                        margin: 20px 10px;
                    }
                }
            }
        }
        #menu.openMenu {

            width: 400px;
            .lang {
            }
        }
        #menubutton.openMenu {
            right: 440px;
        }
        #menubutton.openIcon.top {
            background-image: url('/media/images/close1.png');
        }
    }
    body.openMenu {
        margin-left: -400px;
        overflow: hidden;
    }
    body {
        width: 100%;
    }
    footer {
        .mapElement {
            display: none;
        }
        .mapElementContact {
            display: none;
        }
        .content .contact a {
            font-size: 30px!important;
        }
        .content .contact .mail a {
            font-size: 20px!important;
        }

        #toTop.openMenu, #toHome.openMenu, #phone.openMenu, #conMail.openMenu  {
            right: 420px;
        }
        .contac {
                margin: 40px auto;
            .left {
                border-right: 1px solid #bbb;
                padding: 30px 0;
                text-align: center;
                .sprite {
                    margin: auto;
                }
            }
            .right {
                padding-left: 10%;
                .el1 {
                    font-size: 36px;
                    color: #E94F53;
                }
                .el2 {
                    color: #333;
                    font-size: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: $break-xs) {
    header {
            #menubutton.openMenu {
                left: -40px;
            }
        .top .sprite-logo_header {
            margin: 10px 10px;
        }

        #menu.openMenu {
            width: 222px;
        }
        .belka #menubutton.down.top.openMenu {
            right: 330px;
        }
            ul {
                padding-left: 5px;
                a {
                    margin-right: -40px;
                }
                li {
                    ul {
                        margin-left: -5px;
                        width: 300px;
                        li {
                            margin-left: 10px;

                        }
                    }
                }
            }
    }
    footer {

        #toTop.openMenu, #toHome.openMenu, #phone.openMenu, #conMail.openMenu {
            right: 310px;
        }
        .content {
            .sprite-logo_footer {
                text-align: center;
                margin: auto;
            }
            .row {
            }
            .mail a {
                // font-size: 1.1em!important;
            }
            .tel a {
                // font-size: 1.6em!important;
            }
            .contact {
                margin: auto;
                text-align: center;
            }
        }
        .formContact {
            padding: 0;
        }
        .menuFooter{
            .body {
                a {
                    display: block!important;
                    width: 100%;
                    text-align: center;
                }
            }
            .head {
                display: block!important;
                text-align: center;
            }
        }
    }
    body.openMenu {
        margin-left: -222px;
    }
}
@media screen and (max-width: 355px) {
    // header .belka #menubutton.down.top.openMenu {
    //     right: 285px;
    // }
    // header #menu.openMenu {
    //     width: 280px;
    // }
    // body.openMenu {
    //     margin-right: 280px;
    // }
    // header #menu ul {
    //     padding-left: 25px;
    // }
}
@media screen and (max-width: 365px) {
    footer .content .contact .tel a {
        font-size: 24px!important;
    }
    footer .content .contact .mail a {
        font-size: 18px!important;
    }
}

@media screen and (max-width: 767px) {
    header .top {
        background: white;
        padding: 0;
    }
    footer .contac .right {
        padding: 0;
    }
}

@media screen and (max-width: $break-md) {
    footer .content .sprite-logo_footer {
        margin: auto;
    }
    footer .content .row  {
        margin: 20px auto;
    }
}

@media screen and (max-width: 1053px) and (min-width: 993px) {
    header #menu ul li {
        padding-right: 11px;
    }
}

@media screen and (max-width: $break-sm) {
}

@media screen and (max-width: $break-xs) {
    footer .content .contact .mail a {
        // font-size: 18px!important;
    }
    footer .menuFooter .body a {
        padding-right: 0;
    }
}


.rules{
        background-color: white;

        height: auto;

        .rules-title{
            color: $darkgreen;
            font-size: 36px;
            font-family: 'ChaparralPro-BoldIt';
            text-align: center;
            margin-top: 56px;

            @media screen and (max-width: $break-xs){
                font-size: 27px;
            }
        }

        .rules-container{
            margin-top: 82px;
            margin-bottom: 100px;
            counter-reset: step-counter;
            .rules-column{
                padding: 0 0;




                ol.custom-counter{
                    margin: 0;
                    padding: 0;

                    li.custom-li{
                        counter-increment: step-counter;
                        margin-bottom: 5px;
                        //display: flex;
                        padding: 0 6%;

                        @media screen and (max-width: $break-xs){
                            padding-left: 45px;
                        }


                        &::before{
                              content: counter(step-counter)'.';
                              margin-right: 20px;
                              font-family: 'ChaparralPro-BoldIt';
                              font-size: 48px;
                              color: $blue;

                              padding: 3px 8px;
                              border-radius: 11px;
                              float: left;

                                height: 67px;
                        }

                        &.more-margin::before{
                              content: counter(step-counter)'.';
                              margin-right: 11px;
                              font-family: 'ChaparralPro-BoldIt';
                              font-size: 48px;

                              color: $blue;

                              padding: 3px 8px;
                              border-radius: 11px;
                              float: left;
                              width: 83px;
                              text-align: right;
                        }

                        padding-left: 60px;
                        list-style: none;
                        font-size: 20px;
                        color: $darkgreen;

                        font-family: 'OpenSans-Regular';
                        line-height: 21px;
                        margin-top: 0;
                        margin-bottom: 46px;

                        span{
                            font-family: 'OpenSans-ExtraBold';
                            color: $darkgreen;
                        }

                        ul{
                             padding-left: 129px;


                            li{

                                padding-left: 0;
                                font-size: 30px;
                                color: $darkgreen;
                                margin: 17px 0;
                                list-style: disc;
                                line-height: 19px;

                                span.for-ul{
                                    padding-left: 0;
                                    font-size: 20px;
                                    color: $darkgreen;
                                    margin: 20px 0;
                                    list-style: disc;
                                    line-height: 21px;
                                    font-family: 'OpenSans-Regular';

                                    span{
                                        font-family: 'OpenSans-ExtraBold';
                                        color: $darkgreen;
                                    }
                                }

                                &::before{
                                    content: none;

                                }


                                span{
                                    font-family: 'OpenSans-Bold';
                                    color: $darkgreen;
                                }

                            }
                    }

                    }


                }

                .payment-info{
                    border: solid 2px $darkgreen;
                    height: auto;
                    margin: 0 8%;
                    margin-top: -13px;
                    padding: 42px 8%;

                    .no-top-margin{
                        margin-top: 0;
                    }

                    .account-number{
                        margin-top: 30px;
                    }

                    .iban{
                        margin-top: 32px;
                    }

                    .foreign{
                        margin-top: 54px;
                    }

                    .swift-code{
                        margin-top: 26px;
                    }

                    h1{
                        font-family: 'OpenSans-ExtraBold';
                        font-size: 20px;
                        margin-bottom: 0px;
                        color: $darkgreen;
                    }

                    h2{
                        font-family: 'OpenSans-Regular';
                        font-size: 20px;
                        margin-bottom: 0px;
                        color: $darkgreen;

                        &.more-space-above{
                            margin-top: 50px;
                        }
                    }
                }
            }
        }
}

#booking-widget{
    font-size: 14px;
    position: fixed;
    right: -19px;
    top: 18.4%;
    width: 202px;
    height: 191px;
    z-index: 999;
    background-image: url('/media/images/booking-widget.png');
    color: white;
    font-family: 'OpenSans-Bold';
    text-align: center;
    cursor: pointer;

    @media screen and (max-width: $break-sm){
        display: none;
    }

    .grade{
        margin-top: 40px;
        margin-left: 41px;
        font-size: 49px;
        .gradeof{
            display: inline-block;
            font-size: 17px;
            color: #99DEF8;
        }
    }

    .grade-text{
        font-size: 17px;
        font-family: 'OpenSans-ExtraBold';
        margin-left: 27px;
        margin-top: -8px;
    }

    .opinion-count{
        color: #99DEF8;
        font-size: 9px;
        margin-left: 26px;
    }


}

#bookingicon{
    font-size: 14px;
    top: -79px;
    left: 509px;
    width: 298px;
    height: 293px;
    z-index: 1;
    background-image: url('/media/images/bookingonproduct.png');
    color: white;
    font-family: 'OpenSans-Bold';
    text-align: center;
    position: absolute;

    @media screen and (max-width: $break-sm){
        display: none;
    }

    .grade{
        margin-top: 40px;
        margin-left: -39px;
        font-size: 73px;
        .gradeof{
            display: inline-block;
            font-size: 17px;
            color: #99DEF8;
        }
    }

    .grade-text{
        font-size: 23px;
        font-family: 'OpenSans-ExtraBold';
        margin-left: -49px;
        margin-top: -8px;
    }

    .opinion-count{
        color: #99DEF8;
        font-size: 15px;
        margin-left: -47px;
    }


}

li{

                .licontainer{
                    margin: 0 20px;
                    margin-top: 25px;
                    margin-bottom: 25px;

                    @media screen and (max-width: 1854px){
                        margin-left: 7px;
                        margin-right: 7px;
                        font-size: 12px;
                    }

                    a{
                            text-decoration: none !important;
                    }
                }

}


@media screen and (max-width: $break-lg){

        li{

                .licontainer{
                    margin: 0 0;
                    background-color: transparent;
                    padding: 10px 0px;
                    margin-left: -35px;
                    padding-left: 73px;
                }

                &.active{
                    .licontainer{
                    background-color: #043333;

                        a{
                            color: white !important;
                        }
                    }
                }

        }

        .nav-stacked>li+li{
            margin-top: 0;
        }

}

#menu2{
    .licontainer{
        margin: 0 11px;
        font-size: 13px;
        margin-top: 10px;
        margin-bottom: 17px;
        a{
            color: #A28A5C;

            &:hover{
                color: $darkgreen;
            }
        }
    }
}

.sprite-go_to_top{
    background-image: url('/media/images/arrow_to_top.png');
    background-repeat: no-repeat;

    height: 131px;
    width: 131px;
}

.newsletter-button
    {
        transition:all 0.5s ease;
        display:inline-block;
        padding: 12px 15px;
        line-height: 56px;
        font-family: 'OpenSans-Bold';
        font-size:17px;
        cursor:pointer;
        height: 56px;
        margin-bottom: 30px;
        &.folded
        {
            &:before
            {
                content:attr(data-fold);
                transition:all 0.5s ease;
                position: relative;
                top: -12px;
            }
            &:after
            {
                content: "›";
                display: inline-block;
                transition:all 0.5s ease;
                position: relative;
                top: -14px;
                -webkit-transform: scale(1,1.5);
                -moz-transform: scale(1,1.5);
                -o-transform: scale(1,1.5);
                -ms-transform: scale(1,1.5);
                transform: scale(1,1.5);
            }
            transition:all 0.2s ease;
            border: 2px solid #A28A5C;
            background-color:#001818;
            color:#A28A5C;
            &:hover
            {
            transition:all 0.2s ease;
                color: #001818;
                background-color: #A28A5C;
            }

        }
        &.unfolded
        {
            &:before
            {
                content:attr(data-unfold);
                transition:all 0.5s ease;
                position: relative;
                top: -12px;
            }
            &:after
            {
                content: "›";
                display: inline-block;
                -webkit-transform: scale(1,1.5);
                -moz-transform: scale(1,1.5);
                -o-transform: scale(1,1.5);
                -ms-transform: scale(1,1.5);
                transform: scale(1,1.5);
                transition:all 0.5s ease;
                position: relative;
                top: -12px;
            }
            transition:all 0.2s ease;
            border: 2px solid #A28A5C;
            background-color:#A28A5C;
            color:#001818;
            &:hover
            {
                transition:all 0.2s ease;
                color:#001818;
                background-color:#A28A5C;
            }
        }
    }

    .dropdown-backdrop{
        z-index: 1;
    }

#owl-carousel-galeria{
    padding-bottom: 40px;
}

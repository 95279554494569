//
//	stylesheet.css replacement
//
//	This file requires SASS compiler.
//	You can find instructions here: http://www.artodia.com/phpbb-31-tutorials/compile-theme/
//
//	If you have any questions please visit support forum at:
//		http://www.artodia.com/forums/
//
//	Add your custom code to _custom.scss then recompile stylesheet
//
/*!
	DO NOT EDIT stylesheet.css!

	Edit .scss files instead. See http://www.artodia.com/phpbb-31-tutorials/compile-theme/
	If you have any questions, please ask on support forum at http://www.artodia.com/forums/
*/
@import "fonts";
@import "_default";
@import "layout";
@import "sprites";
@import "categorry";
@import "homepage";
@import "mapa";
@import "house";
@import "cennik";
@import "contact";
@import "why-domki";
@import "szczyrk";
@import "kids";
@import "map";
@import "bookingbox";
@import "reservation";


$kidbrown1: #726241;
$kidbrown2: #725F3F;

.kids-container{
  .szczyrk-container{
    background-image: url('/media/images/kids/kids-background.jpg');
    height: 1020px;
    background-position-x: 50%;

    @media screen and (max-width: $sm-break){
        height: auto;
        background-size: 100%;
    }

    .gufik{
      position: absolute;
      top: -26px;
      left: 545px;

      @media screen and (max-width: $break-sm){
        top: -82px;
        left: 34%;
      }

      @media screen and (max-width: $break-xs){
        left: 27%;
      }

      @media screen and (max-width: 362px){
        left: 19%;
      }
    }

    .info{
      -webkit-box-shadow: 10px 10px 29px 0px rgba(0,0,0,0.75);
      -moz-box-shadow: 10px 10px 29px 0px rgba(0,0,0,0.75);
      box-shadow: 10px 10px 29px 0px rgba(0,0,0,0.75);

      background-image: url('/media/images/kids/kids-white-box.jpg');
      height: 805px;
      width: 661px;
      padding: 0 66px;
      margin-top: 150px;
      margin-left: 10%;


      @media screen and (max-width: $md-break){
        padding-top: 1px;
      }

      @media screen and (max-width: $sm-break){
        margin-top: 52vw;
        width: inherit;
        background-size: cover;
        background-size: 100% 100%;
        height: auto;
        padding-bottom: 50px;
        margin-bottom: -1px;  //usuniecie linii pod kontenerem
        margin-left: 0;

        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }

      @media screen and (max-width: $xs-break){
        background-image: none;
        background-color: white;
      }

      .title{
          margin-top: 0;
          @media screen and (max-width: $break-sm){
            margin-top: 253px;
          }
          p{
              color: $verydarkgreen;
              font-family: 'ChaparralPro-BoldIt';
              font-size: 70px;
              margin-bottom: 0px;
              text-align: center;
              line-height: 66px;
              margin-top: 20px;

              &.szczyrk-title{
                @media screen and (max-width: $break-xs){
                  font-size: 43px;
                  line-height: 40px;
                }
              }
          }

          .subtitle{
            color: $kidbrown1;
            font-size: 40px;
            font-family: 'ChaparralPro-Italic';
            line-height: 35px;
            text-align: center;
            margin-top: 70px;
          }

          h1{
            color: $kidbrown2;
            font-family: 'ChaparralPro-Italic';
            font-size: 22px;
            margin-top: 28px;
            margin-bottom: 20px;
            text-align: center;

            span{
              font-family: 'ChaparralPro-BoldIt';
              color: $verydarkgreen;
              font-size: 28px;
            }
          }

          h2{
            color: $brown;
            font-family: 'OpenSans-Regular';
            font-size: 20px;
            line-height: 30px;
            text-align: center;
          }
      }

      .blue-button{
        width: 130px;

        @media all and (max-width: $break-xs){
          width: 130px;
        }
      }
    }
  }

  .benefits-row{
    background-image: url('/media/images/kids/kids-benefits-background.jpg');
    background-size: cover;
    background-position-x: 50%;

    @media screen and (max-width: $break-lg){
      padding: 0 60px;
    }

    @media screen and (max-width: $break-xs){
      padding: 0 30px;
    }

    .benefit{
      .text{

        @media screen and (max-width:$break-md){
          left: 28%;
        }

        span{
          font-size: 25px;
        }
      }
    }

    .title{
      margin-top: 0px !important;
      text-align: center;
      p{
          color: white;
          font-family: 'ChaparralPro-BoldIt';
          font-size: 70px;
          margin-top: 47px;
          margin-bottom: 0px;
          padding: 0 40px;

          @media screen and (max-width: $break-sm){
             line-height: 77px;
          }

          @media screen and (max-width: $break-xs){
            font-size: 45px;
            line-height: 45px;
          }
      }

      .subtitle{
        color: $lightbrown;
        font-size: 40px;
        font-family: 'ChaparralPro-Italic';
        font-weight: 100;
        margin-top: 0;
      }
    }

    .benefits-outer-container{
      margin-top: -15px;
      margin-bottom: 100px;

      .benefits-container{
        background-image: none;
        height: 840px;


        @media screen and (max-width: $break-lg){
          height: auto;
        }

        &.second-container{
          @media screen and (max-width: $break-sm){
            margin-top: -100px;
          }
        }
      }
    }


  }

  .reasons{
        background-color: white;

        height: auto;

        .reasons-title{
            color: $darkgreen;
            font-size: 28px;
            font-family: 'ChaparralPro-BoldIt';
            text-align: center;
            margin-top: 56px;
            margin-left: 28%;
            margin-right: 28%;

            @media screen and (max-width: $break-sm){
              margin-left: 14%;
              margin-right: 14%;
            }

            @media screen and (max-width: $break-xs){
                font-size: 21px;
                line-height: 21px;
            }
        }

        .reasons-container{
            margin-top: 100px;
            margin-bottom: 100px;

            @media screen and (max-width: $break-lg){
              margin-top: 50px;
            }

            .reasons-column{
                padding: 0 100px;
                padding-left: 15%;
                @media screen and (max-width: $break-xs){
                    padding: 0 42px;
                }

                ul{
                    li{
                        background: url(/media/images/css/check.png) no-repeat center left;
                        padding-left: 60px;
                        list-style: none;
                        font-size: 20px;
                        color: $verydarkgreen;
                        margin: 20px 0;
                        font-family: 'OpenSans-ExtraBold';
                        line-height: 40px;

                        @media screen and (max-width: $break-xs){
                          font-size: 16px;
                          height: 50px;
                          line-height: 25px;
                        }

                        span{
                            font-size: 28px;
                            font-family: 'ChaparralPro-BoldIt';
                            color: $darkgreen;
                        }

                    }
                }
            }

            .kids-reasons-column{
              border: solid 2px $verydarkgreen;
              margin-left: -90px;
              padding: 85px 6%;
              position: relative;
              padding-bottom: 30px;

              @media screen and (max-width: $break-lg){
                padding: 70px 4%;
                margin: 100px 16%;
              }

              img{
                position: absolute;
                top: -73px;
                left: 89%;
              }

              p{
                color: $blue;
                font-family: 'ChaparralPro-BoldIt';
                font-size: 28px;
                text-align: center;
                line-height: 33px;
                margin-bottom: 20px;
              }
            }
        }
    }


}

$darkbrown: #231605;
$grey: #F0F4F4;


.map-page-container{
    background: url('/media/images/map/machon.jpg');
    height: auto;

    overflow: hidden;
    position: relative;

    @media screen and (max-width: $break-md){
        height: auto;
    }
    
    .map-heading{
        position: relative;
        overflow: hidden;

        h1{
            font-size: 66px;
            font-family: 'ChaparralPro-BoldIt';
            color: $darkbrown;
            text-align: center;
            margin-top: 0;
            padding-top: 37px;
        }

        h2{
            color: white;
            font-size: 37px;
            font-family: 'ChaparralPro-Italic';
            text-align: center;
            line-height: 9px;
            margin-top: 0;
            
            @media screen and (max-width: $break-xs){
                font-size: 25px;
            }

        }

        .redpin{
            position: absolute;
            top: 35px;
            left: 30.7%;

            @media screen and (max-width: 1016px){
                left: 15%;
            }

            @media screen and (max-width: $break-xs){
                left: 3%;
            }
        }

        .photo{
            position: absolute;
            top: 56px;
            left: 1.9%;

            @media screen and (max-width: 1668px){
                left: -12.1%;
            }

            @media screen and (max-width: 1348px){
                left: -20.1%;
            }

            @media screen and (max-width: 1115px){
                display: none;
            }
        }

        .coffee{
            position: absolute;
            top: 108px;
            left: 75.2%;

            @media screen and (max-width: 1747px){
                left: 89.2%;
            }

            @media screen and (max-width: 1129px){
                display: none;
            }
        }

        .paper{
            color: $brown;
            font-family: 'ChaparralPro-Italic';
            font-size: 28px;
            background-image: url('/media/images/map/paper.jpg');
            background-repeat: no-repeat;
            background-size: cover;
            width: 866px;
            padding: 48px 45px;
            text-align: center;
            line-height: 36px;
            margin: 0 auto;
            margin-top: 39px;
            position: relative;

            @media screen and (max-width: $break-md){
                width: 90%;
            }

            .greenpin1{
                position: absolute;
                top: 0;
                left: -17px;
            }

            .greenpin2{
                position: absolute;
                top: -8px;
                right: -34px;
            }


        }



        h3{
            font-size: 40px;
            font-family: 'ChaparralPro-BoldIt';
            text-align: center;
            color: white;
            text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.75);
            margin-top: 40px;
        }

        
    }

    .pen{
            position: absolute;
            top: 744px;
            left: 88%;
            z-index: 1;

            @media screen and (max-width: 1500px){
                display: none;
            }
    }

    .map-papers{

                background-image: url('/media/images/map/map-papers.png');
                position: absolute;
                top: 526px;
                left: 13.9%;
                width: 1460px;
                height: 1500px;
                background-repeat: no-repeat;

                @media screen and (max-width: 1208px){
                    left: 0.9%;
                }

                @media screen and (max-width: 962px){
                    top: 580px;
                }

                @media screen and (max-width: 920px){
                    left: -10%;
                }

                @media screen and (max-width: 768px){
                    display: none;
                }

                
            }



    .map-attraction-container{
        margin-top: 101px;
        padding: 0 28%;

        margin-bottom: -100px;
        position: relative;


        @media screen and (max-width: 1660px){
            padding: 0 20%;
        }

        @media screen and (max-width: 1208px){
            padding: 0 10%;
        }

        // @media screen and (max-width: $break-md){
        //     background-color: white;
        // }

        @media screen and (max-width: 920px){
            padding: 0 0;
        }

        @media screen and (max-width: 768px){
            background: $grey;
            padding-bottom: 50px;
        }

        @media screen and (max-width: $break-sm){
                margin-top: 0;
                margin-bottom: 0;
        }

        .redpin{
            position: absolute;
            top: -53px;
            left: 20.1%;

            @media screen and (max-width: 1660px){
                left: 13.1%;
            }

            @media screen and (max-width: 1208px){
                left: 3.1%;
            }

            @media screen and (max-width: $break-md){
                display: none;
            }
        }

        .greenpin{
            position: absolute;
            top: 78px;
            right: 17.5%;

            @media screen and (max-width: $break-sm){
                display: none;
            }
        }


        

        .mapClickCol{
            text-align: center;
            cursor: pointer;

            @media screen and (max-width: $break-sm){
                margin-top: 60px;
            }
            .mapClick{
                color: $blue;
                font-size: 17px;
                font-family: 'OpenSans-ExtraBold';
                border: solid 2px $blue;
                padding: 17px 19px;
                background-color: #F0F4F4 !important;
                text-decoration: none;
                cursor: pointer;

                @media screen and (max-width: 1900px){
                    font-size: 14px;
                }

                &:hover{
                    background-color: $blue !important;
                    color: white;
                }
                &.mapButtonActive {
                    background-color: $blue !important;
                    color: white;
                }



            }
        }

        


    }

    
    
        
}
.clear {
    clear: both;
}
.black-wood{
    background-image: url('/media/images/map/black-wood.jpg');
    width: 100%;
    height: 433px;

    @media screen and (max-width: $break-sm){
        height: auto;
        padding-bottom: 1px;
    }

    h1{
        margin-top: 0;
        text-align: center;
        font-family: 'ChaparralPro-BoldIt';
        font-size: 40px;
        color: white;
        padding-top: 68px;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.75);
        margin-bottom: 59px;
        padding-left: 20px;
        padding-right: 20px;
    }

    h2{
        text-align: center;
        font-family: 'OpenSans-ExtraBold';
        color: white;
        font-size: 20px;
        margin-bottom: 35px;
        padding: 0 26px;

        img{
            margin-right: 6px;
        }

        a{
            color: $blue !important;
            text-decoration: none;
        }
    }

}

.endpapers{
    background-image: url('/media/images/map/map-papers-bottom.png');
    background-repeat: no-repeat;
    background-position-x: 55%;
    height: 140px;

    @media screen and (max-width: $break-sm){
        display: none;
    }
}
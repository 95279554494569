.clear {
    clear: both;
}
#house {
    clear: both;
    .zdj {
        .photo1 {
            position: relative;
            .price {
                position: absolute;
                bottom: 1.9vw;
                right: 2.8vw;
                
                font-family: 'Open Sans';
                .el1 {
                    background: #FFF;
                    color: #D93600;
                    font-size: 1em;
                    float: right;
                    padding: 5px 7px;
                    font-size: 1.5vw;
                @media screen and (max-width: 992px){
                    font-size: 3.5vw;
                }

                }
                .el2 {
                    clear: both;
                    font-weight: 800;
                    color: #FFF;
                    background: #D93600;
                    font-size: 2.3vw;
                    padding: 0 10px;
                @media screen and (max-width: 992px){
                    font-size: 4.5vw;
                }
                }
                @media screen and (max-width: 992px){
                    bottom: 3.9vw;
                    right: 5.7vw;
                }
            }
        }
    }
    .title1 {
        background: #003030;
        color: #A18A5B;
    }
    .title {
        margin: auto;
        text-align: center;
        font-size: 40px;
        height: 110px;
        font-family: 'Open Sans';
        font-weight: 800;
        vertical-align: middle;
        width: 100%;
        @media screen and (max-width: 1216px){
            font-size: 30px;
        }
        display: table;
        div {
            display: table-cell;
            vertical-align: middle;
        }

    }
    .title2 {
        color: #003030;
        background: #A28A5C;
    }
    .descript {
        .left {
            background-position: center;
            background-repeat: no-repeat;
            .content {
                max-width: 750px;
                width: 80%;
                margin: auto;
                h2 {
                    font-family: 'ChaparralPro-BoldIt';
                    font-weight: 800;
                    text-align: center;
                    color: #003030;
                    font-size: 70px;
                    margin: 70px auto;
                }
                ul {
                    li {
                        background: url('/media/images/css/check.png') no-repeat center left;
                        padding-left: 60px;
                        list-style: none;
                        font-size: 20px;
                        color: #726140;
                        margin: 20px 0;
                        strong {
                            color: #003030;
                            display: block;
                            font-size: 28px;
                            font-family: 'ChaparralPro-BoldIt';

                        }
                    }
                }
                .alert {
                    border: 2px solid #003030;
                    width: 100%;
                    margin: 120px 0;
                    border-radius: 0;
                    padding: 0;
                    position: relative;
                    .exclamation {
                        position: absolute;
                        background: url('/media/images/css/alert.png') no-repeat;
                        height: 138px;
                        width: 40px;
                        right: 30px;
                        top: -80px;
                    }
                    p {
                    padding: 70px 30px;
                        color: #2692FF;
                        font-family: 'ChaparralPro-BoldIt';
                        font-size: 26px;
                        text-align: center;
                    }
                }
            }
        }
    }
    .headPrice {
        margin-top: 50px;
        margin-bottom: 35px;
        font-family: 'ChaparralPro-BoldIt';
        h2 {
            color: #003030;
            font-size: 70px;
            text-align: center;
        }
        .content {
            font-size: 28px;
            color: #003030;
            text-align: center;
            padding: 0 20px;
        }
        p {
            text-align: center;
            color: #726241;
            font-size: 40px;
            margin-bottom: 30px;
        font-family: 'ChaparralPro-Italic';
        }
        img {
            margin: auto;
        }
    }
    .box-offert-container {
        height: auto;

        padding: 90px 0;

        @media screen and (max-width: $break-md){
            padding: 90px 20%;
        }

        @media screen and (max-width: $break-sm){
            padding: 90px 10%;
        }

        @media screen and (max-width: $break-xs){
            padding: 90px 0;
        }

        .col-offer, .container-fluid-offer{
            @media screen and (max-width: $break-xs){
                padding-left: 0;
                padding-right: 0;
            }
        }

        .offer{

            .col-img{
                @media screen and (max-width: $break-md){
                    height: 39vw;
                }
                
                @media screen and (max-width: $break-sm){
                    height: 50vw;
                }

                @media screen and (max-width: $break-xs){
                    height: 70vw;
                }

                    
            }

            //margin-right: 55px;

            @media screen and (min-width: $break-md + 1px) and (max-width: $break-lg){
                margin-right: 50px !important;
            }

            // @media screen and (max-width: $break-md){
            //     margin-left: 20% !important;
            //     margin-right: 20% !important;
            // }

            // @media screen and (max-width: 600px){
            //     margin-left: 10% !important;
            //     margin-right: 10% !important;
            // }

            // @media screen and (max-width: $break-xs){
            //     margin-left: 3% !important;
            //     margin-right: 3% !important;
            // }

            .box {
                // height: 307px;
                background: none;
                margin: auto;
                height: 307px;
                img {
                    //width: 100%;
                    float: right;
                    height: 307px;

                    @media screen and (max-width: $break-md){
                        width: 100%;
                        height: auto;
                    }

                }
            }

            .box-text{
                background: white;
                height: 307px;
                padding-top: 50px;
                padding-left: 30px;
                padding-right: 30px;

                h1{
                    margin-top: 0;
                    color: $verydarkgreen;
                    font-size: 50px;
                    font-family: 'ChaparralPro-BoldIt';

                    @media screen and (max-width: 1445px){
                        font-size: 40px;
                    }

                    @media screen and (max-width: 338px){
                        font-size: 38px;
                    }
                }

                h2{
                    color: $verydarkgreen;
                    font-family: 'OpenSans-Regular';
                    font-size: 16px;

                    @media screen and (max-width: 1445px){
                        font-size: 14px;
                    }

                    span{
                        font-family: 'OpenSans-ExtraBold';
                    }
                }

                a{
                    text-decoration: underline;
                    color: $blue;
                    font-size: 16px;
                }

            }

            &.offer-right{
                margin-right: 5%;

                @media screen and (max-width: $break-lg){
                    margin-right: 0;
                    margin-top: 50px !important;
                }
            }
    
        }
        ///media/images/css/6.jpg
    }///media/images/css/3.jpg
}
#usp {
    clear: both;
        .style1{
            background: #A28A5C;
            color: #003030;
        }
        .style2{
            background: #003030;
            color: #A28A5C;
        }
    .descript {
        font-family: 'ChaparralPro-BoldIt';
        font-size: 22px;
        height: 13vw;
        display: table;
        @media screen and (max-width: 992px) {
            height: 19.3vw
        }
        p {
            display: table-cell;
            vertical-align: middle;
            text-align: center;
            padding: 0 20px;
            @media screen and (max-width: 1215px) and (min-width: 992px) {
                font-size: 18px;
            }
            @media screen and (max-width: 810px) and (min-width: 767px) {
                font-size: 18px;
            }
        }
    }
}
#normalPrice {
    th {
        color: #003030;
    }
}

.collapsePriceGroup{
    margin-bottom: 0;
    .panel {
        border-radius: 0;
        margin-top: 0!important;
        border-width: 0px;
        border-bottom-width: 0;
        margin-bottom: 2px;
    }
    .panel-heading {
        border-radius: 0;
        text-align: center;
        background: #726241;
        .panel-title {
            text-transform: none;
            padding: 25px 0;
            padding-top: 10px;
            .info {
                padding-top: 15px;
            }
            .title-price {
                position: relative;
                p {
                    color: #2692FF;
                    font-size: 20px;
                    font-family: 'Open Sans';
                    font-weight: 800;
                    margin: 0;
                    padding: 0;
                    margin-top: 5px;
                }
            }
            a {
                background: url('/media/images/css/up.png') no-repeat bottom;
                width: 64px;
                height: 86px;
                display: block;
                position: absolute;
                right: 10vw;
                top: 0;
                @media screen and (max-width: 820px) {
                    right: 1vw;
                }
                @media screen and (max-width: 700px) {
                    position: relative;
                    margin: auto;
                    margin-top: 30px;
                    margin-bottom: -30px;
                }
            }
            a.collapsed {
                background: url('/media/images/css/down.png') no-repeat;
            }
            .info {
                color: #003030;
                margin: auto;
                font-family: 'Open Sans';
                font-weight: 800;
                font-size: 20px;
                padding-bottom: 10px;
            }
            .date {
                font-family: 'Open Sans';
                color: #FFF;
                font-weight: 800;
                font-size: 40px;
                line-height: 30px;
                span {
                    color: #BBAB8A;
                    font-size: 20px;
                    text-transform: lowercase;
                }
            }
        }
    }
    .collapse {
        margin: 0;
        padding: 0;
        th {
            color: #003030;
        }
    }
    table.panel-body {
        width: 100%;
        line-height: normal;
        margin-top: -2px;
        border-top-color: #726241!important;
        th.groupHouse {
            padding: 10px 25px;
            background: #B4A483!important;
        }
        th {
            background: #BCAB8A;
            font-family: 'ChaparralPro-BoldIt';
            font-size: 28px;
            text-align: center;
            padding: 20px 0;
            @media screen and (max-width: 450px) {
                font-size: 22px;
            }
            @media screen and (max-width: 330px) {
                font-size: 20px;
            }
            div {
                text-align: center;
            }
        }
        td {
            font-family: 'Open Sans';
            color: #726241;
            font-size: 20px;
            font-weight: 800;
            text-align: center;
            padding: 20px 0;
        }
        td.price {
            color: #D83400;
            background: #F8F7F5;
        }
        th:nth-child(3),th:nth-child(4), {
            background: #978665;
        }
        tr:nth-child(2n+1) {
            background: #EEEBE3;
            td.price {
                background: #E7E4DA;
            }
        }
        .empty {
            width: 30%;
            @media screen and (max-width: 1000px) {
                width: 0;
            }
        }
        .notempty {
            width: 20%;
            @media screen and (max-width: 1000px) {
                width: 50%;
            }
        }

    }
    .after_table {
        text-align: center;
        padding: 30px 0;
        color: #D93600;
        font-size: 20px;
        font-weight: 800;
        font-family: 'Open Sans';
    }
}
#specialPrice {
    .panel-heading {
        background: #003030;
        .panel-title {
            .info {
                color: #2692FF;
            }
            p {
                color: #FFF;
            }
        }

    }
    table.panel-body {
    
        border-top-color: #003030!important;
        th {
            background: #245E56;
            color: #BBAB8A;
        }
        th.groupHouse {
            background: #225750!important;
        }
        th:nth-child(3),th:nth-child(4), {
            background: #1B433F;
        }
        td {
            color: #003030;
        }
        td.price {
            background: #F0F4F4;
            color: #D83400;
        }
        tr:nth-child(2n+1) {
            background: #EEEBE3;
            td.price {
                background: #D7E3E3;
            }
        }
        tr:nth-child(2n+1) {
            background: #E1EAEA;
        }
    }
}//#

#gallery {
    clear: both;
    background: #003030;
    padding-top: 70px;
    h2 {
        color: #A18A5B;
        font-family: 'ChaparralPro-BoldIt';
        font-size: 70px;
        text-align: center;
    }
    .content {
        column-count: 4;
        column-gap: 100px;
        column-break-inside:avoid;
        img {
            margin-bottom: 20px;
        }
    }
}

.specialDates {
    width: 100%;
    background: #2693FF;
    padding: 40px 0;
    font-family: 'ChaparralPro-BoldIt';
    color: #FFF;
    font-size: 40px;
    text-align: center;
    position: relative;
    margin-top: -2px;
    .down {
        background: url('/media/images/css/arrow-down.png') no-repeat center;
        position: absolute;
        width: 100%;
        height: 24px;
        bottom: -24px;
        z-index: 100;
    }
    // arrow-down.png
}
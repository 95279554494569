#categorry {
	font-family: 'Lato';
	.owl .item img{
		display: block;
		width: 100%;
		height: auto;
	}
	.line {
		width: 100px;
		height: 6px;
		background: #008AC2;
		margin: auto;
	}
	.top {
		width: 100%;
		max-width: 1920px;
		height: 338px;
		text-align: center;
		margin: auto;
		background-position: center;
		.offert {
			font-size: 36px;
			color: #EBEBEB;
			padding-top: 70px;
		}
		.title {
			font-size: 66px;
			line-height: 63px;
			color: #FFF;
		}
		.line {
			margin-top: 25px;
		}
	}
	.productsContainer {
		padding-top: 110px;
		max-width: 1500px;
		width: 100%;
		margin: auto;

		.productsList {
			margin-bottom: 30px;
			font-size: 20px;
			color: #333;
			cursor: pointer;
			text-decoration: underline;
			@media screen and (max-width: $break-md) {
				text-align: center;
			}
		}
		.productsList:hover {
			color: #008AC2;
		}
	}
	.clear {
		clear: both;
	}
	h2 {
		font-family: 'FuturaLTPro-Book';
		font-weight: 100;
		text-align: center;
		margin-top: 60px;
		color: #333;
		font-size: 66px;
		margin-bottom: 50px;
	}
	#offertsList {
		margin-top: 100px;
		.product {
			.background {
				width: 100%;
				height: 530px;
				.top {
					position: absolute;
					width: 100%;
					overflow: hidden;
					height: 100%;
					.back {
						position: absolute;
						width: 100%;
						height: 530px;
					}
					img {
						position: absolute;
					}
				}
				.top.topLeft {
					.back {
						background: url('/media/images/pasek-flip.png') center;
					}
					img {
						right: 0;
					}
				}
				.top.topRight {
					.back {
						background: url('/media/images/pasek.png') center;//left
					}
					img {
						left: 0;
					}
				}
				}
			.background.noImage {
				background: #EBEBEB;
				height: 250px;
				.title {
					padding-top: 65px;
				}
				.image img {
					margin-top: -40px;
				}
			}
			.title {
				padding-top: 125px;
				display: table;
				height: 60px;
				h3 {
				    display: table-cell;
				    vertical-align: middle;
				    color: #333;
				    font-size: 26px;
				    padding-left: 25px;
				    font-family: 'FuturaLTPro-Book';
				}
			}
			.image {
				img {
					width: 95%;
					max-height: 650px;
					position: absolute;
					z-index: 1;
					margin-top: 250px;
					-webkit-box-shadow: $img-shadow;
					-moz-box-shadow: $img-shadow;
					box-shadow: $img-shadow;
				}
			}

			.description {
				width: 100%;
				background: #34495E;
				position: relative;
				min-height: 450px;
				.txt {
					padding: 60px 25px;
					font-size: 20px;
					color: #FFF;
				}
				ul {
				    list-style: none; /* Remove list bullets */
				    padding: 0;
				    margin: 0;
				    margin-bottom: -20px;
				    margin-left: 15px;
				}

				li { 
				    padding-left: 5px; 
				    margin-bottom: 20px;


		list-style: url("/media/images/liWhite.png");
				}

				// li:before {
				//     content: ">"; /* Insert content that looks like bullets */
				//     padding-right: 8px;
				// }
			}
			.longDescription {
				font-size: 18px;
				color: #333;
				padding-top: 20px;
				padding-bottom: 50px;
				.paddingbox {
					padding-left: 40px;
					padding-right: 40px;
				}
				.scroll {
					overflow: scroll;
				}
				li {
					white-space: normal;
				}
				.table1 {
					td {
						padding-right: 20px;
						padding-top: 10px;
						padding-bottom: 10px;
					}
					.border-bottom {
						border-bottom: 1px solid #BBB;
					}
				}
				.tabdescription {
					text-align: center;
					color: #333;
					margin: 20px auto;
					font-size: 36px;
					font-family: 'FuturaLTPro-Book';
					padding: 0 10px;
					@media screen and (max-width: $break-xs) {
						font-size: 30px;
					}
				}
				table.table2 {
					thead {
						td {
							background: #008AC2;
							font-family: 'FuturaLTPro-Book';
							font-size: 36px;
							color: #FFF;
							text-align: center;
						}
						td:nth-child(1) {
							background-color: #34495E;
						}
					}
					tbody {
						td {
							background: #E5F3F9;
							padding: 40px 50px;
						}
						td:nth-child(1) {
							background: #EAECEF
						}
						tr {
							border-bottom: 1px solid #BBB;
						}
					}
				}
				h4 {
					margin-top: 40px;
					font-size: 26px;
					color: #333;
					margin-bottom: 30px;
					font-weight: bold;
				}
				p {
				}
				ul {
				    list-style: none; /* Remove list bullets */
				    padding: 0;
				    margin: 0;
				    margin-bottom: -10px;
				    margin-left: 22px;
				}

				li { 
				    padding-left: 1px; 
				    margin-bottom: 10px;
		list-style: url("/media/images/li.png");
				}

				// li:before {
				//     content: ">"; /* Insert content that looks like bullets */
				//     padding-right: 8px;
				// }
			}
			.gallery {
				background-color: #34495E;
				.title {
					color: #FFF;
					font-size: 36px;
					padding: 80px 10px;
					padding-bottom: 40px;
					font-family: 'FuturaLTPro-Book';
				}
				.photos {

					@media screen and (min-width: 1500px) {
						margin-left: 12px;
					}
					img {
						padding-bottom: 50px;
						opacity: 0.5;
					}
					img:hover {
						opacity: 1;
					}
					.owl-buttons {
						margin-top: -30px;
						margin-bottom: 80px;
						.owl-prev, .owl-next {
							font-size: 0;
							width: 53px;
							height: 53px;
							background: url("/media/images/navigation.png") no-repeat;
							padding: 0 40px;
							margin-top: -40px;
						}
						.owl-next {
							-ms-transform: rotate(180deg); /* IE 9 */
							-webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
							transform: rotate(180deg);
						}
					}
				}
			}
		}
	}
	.foot {
		width: 100%;
		// height: 80vw;
		// min-height: 600px;
		// max-height: 900px;
		background: url('/media/images/background/foot.jpg') top center;
		text-align: center;
		position: relative;
		.in {
			padding: 20vw 15px;
			a {
				background: #E94F53;
				padding: 25px;
				border-radius: 55px;
				font-size: 25px;
				color: #FFF;
			}
			.txt {
				margin-bottom: 80px;
				font-family: 'FuturaLTPro-Book';
				font-size: 36px;
				color: #FFF;
				@media screen and (max-width: $break-md) {
					font-size: 28px;
				}
			}
		}
	}
}


@media screen and (max-width: $break-md) {
	#categorry {

		.top {
			.offert {
				font-size: 26px;
			}
			.title {
				font-size: 36px;
				line-height: 36px;
			}
		}
		#offertsList .product {

			.background.noImage .title {
				margin: auto;
			}
			.image img {
				width: 100%;
				margin-top: 150px!important;
				position: relative!important;
			}
			.description p {
				margin-bottom: 50px;
			}
			.description .txt{
				padding-right: 50px;
				padding-top: 10px;
				.paddingTop {
					margin-top: 100px;
				}
			}
		}
	}
}

@media screen and (max-width: $break-xs) {
	#categorry #offertsList .product .gallery .title {
		padding-bottom: 0;
	}
	.top {
		.offert {
			font-size: 20px!important;
		}
		.title {
			font-size: 30px!important;
		}
	}
	#categorry #offertsList .product .title {
		h3 {
			display: block;
			text-align: center;
			padding: 0;
		}
		.sprite {
			text-align: center;
	    	margin: auto;
			-ms-transform: rotate(90deg); /* IE 9 */
			-webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
			transform: rotate(90deg);
		}
	}

	.gallery .container-fluid {
		padding: 0;
	}
	#categorry .foot .in a {
		font-size: 20px;
	}
}

@media screen and (max-width: $break-sm) {
	#categorry{
		#offertsList .product {
			.gallery .photos img {
					opacity: 1;
			}
		}
	}
}


@media screen and (min-width: $break-xs) {
#categorry #offertsList .product .background.noImage .title {
    padding-top: 90px;
}

}



.reservations-container{

    background-size: cover;
    height: 875px;
    padding-top: 200px;

    & > div{
      @media screen and (max-width: 768px){
        width: 100%;
      }
    }

    @media screen and (max-width: $lg-break){
      padding-top: 50px;
      height: 1124px;
    }

    @media screen and (max-width: $sm-break){
      padding-top: 0px;
      height: auto;
    }

    @media screen and (max-width: $xs-break){
      padding-top: 0px;
      height: auto;
    }
    //padding: 0px 21.7vw;
    //padding-top: 183px;

    .reservation-row{
      

      @media screen and (max-width: $break-lg){
        padding: 0 24%;
      }


      @media screen and (max-width: $break-md){
        padding: 0 16%;
      }

      @media screen and (max-width: $break-sm){
        padding: 0;
      }
    }

    .reservations-image-block{
      height: auto;

      img{
        width: 100%;
        height: 513px;

        @media screen and (max-width: $break-sm){
          height: auto;
        }
      }
    }

    .reservations-white-block{
      background: white;
      height: 513px;
      padding-top: 91px !important;
      text-align: center;

      @media screen and (max-width: $break-xs){
        height: auto;
      }

      @media screen and (max-width: $break-xs){
        padding: 19px;
        padding-top: 35px !important;
      }

      .brown-text{
        color: $brown;
        font-size: 24px;
        margin-bottom: 0;
        font-family: 'OpenSans-Regular';

        @media screen and (max-width: $break-xs){
          font-size: 23px;
        }

        &.preemail{
          margin-top: 25px;
        }

        

        &.email a
        {
          font-size: 28px;
          font-weight:bold;
          color: $verydarkgreen;
          font-family: 'OpenSans-ExtraBold';
          text-decoration: none;
        }
      }

      .email{
        font-family: 'OpenSans-ExtraBold';
        font-size: 22px;
        color: $verydarkgreen;
        margin-top: 3px;
        margin-bottom: 0;

        a{
          text-decoration: none;
          color: inherit;
        }
      }

      .blue-text{
        color: $blue;
        font-size: 21px;
        margin-bottom: 0;
        font-family: 'OpenSans-BoldItalic';
        margin-top: 26px;
      }



      .big-text{
        font-size: 52px;
        font-weight: bolder;
        color: $verydarkgreen;
        margin-bottom: 0;
        font-family: 'OpenSans-ExtraBold';
        @media screen and (max-width: $break-xs){
          font-size: 44px;
        }
        a{
          color: inherit;
          text-decoration: none;
        }

      }

      .parenthesis-text{
        font-size: 16px;
        color: $verydarkgreen;
        margin-bottom: 0;
        font-family: 'OpenSans-Bold';
      }
    }
  }
.mapaRealizacji {
    margin-bottom: -10px;
    .featureTextContainer
    {
        background-color:white;
        text-align:center;
        .featureText
        {
            padding-top:5vw;
            font-size:66px;
            strong
            {
                color:#008AC2;
            }
        }
    }
    .mapContainer
    {
        padding:0;
        height:60vw;
        .mapTitle
        {
            background-color:#008AC2;
            width: 100%;
            height:10vw;
            line-height:10vw;
            text-align: center;
            p{
                font-size:2vw;
                color:white;
                margin:0;
                font-family: 'FuturaLTPro-Book';
            }
        }
    }
    ul {
        margin: 100px 0;
        li {
            font-size: 30px;
        }
    }
}
@media screen and (max-width: $break-sm) {
    .mapaRealizacji {
        .featureTextContainer{
         .featureText {
            font-size: 20px!important;
        }
    }
}
}
@media screen and (max-width: $break-md) {
    .mapaRealizacji {
        .featureTextContainer{
         .featureText {
            font-size: 40px!important;
        }
    }
}
}
.svg-flex-inner {
  position: relative;
  padding-top: 100%;
  background-color:#008AC2;
}

.svg-flex svg {
  position: absolute;
  margin-top: -100%;
  width: 100%;
  height: 100%;
}

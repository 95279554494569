$break-xs: 480px;
$break-sm: 768px;
$break-md: 992px;
$break-lg: 1200px;

$img-shadow: -21px 21px 52px 0px rgba(0, 0, 0, 0.75);

$red:	#E94F53;
$verydarkgreen:		#003030;


body {
    font-family: "OpenSans-Regular","Roboto","Helvetica Neue",Helvetica,Arial,sans-serif;
}

.nopadding{
	padding-left: 0;
	padding-right: 0;
}

.nomargin{
	margin-left: 0;
	margin-right: 0;
}

#menu .dropdown-submenu {
    position: relative;
}

a.button.btn {
	color: #FFF;
	background: #E94F53;
	font-size: 26px;
	border-radius: 55px;
	padding: 25px 40px;
	font-family: 'Lato';
	transition: all 0.3s;
}

.transition{
	transition: all 0.5s;
}

.lg-line-2 {
	@media screen and (min-width: $break-lg) {
		-webkit-column-count: 2;
		column-count: 2;
	}
}

.topSite 
{
	.top {
		font-family: 'ChaparralPro-BoldIt';
		width: 100%;
		height: 860px;
		text-align: center;
		margin: auto;
		background-position: center;
		padding-top: 126px;
		.offert {
			font-size: 36px;
			margin-bottom: 50px;
			color: $verydarkgreen;
			@media screen and (max-width: $break-md) {
				font-size: 30px!important;
			}
		}
		.title {
			font-size: 70px;
			color: $verydarkgreen;
			margin-top: 0;
			line-height: 60px;
			@media screen and (max-width: $break-md) {
				font-size: 50px!important;
			}
			@media screen and (max-width: $break-xs) {
				font-size: 40px!important;
			}
		}
		.line {
			margin-top: 15px;
			width: 100px;
			height: 6px;
			background: #008AC2;
			margin: auto;
		}
	}
}
ul.default {
	li {
		list-style: url("/media/images/li.png");
		font-size: 20px;
		margin-bottom: 25px;
		color: #333;
	}
}
ul.default.white {
	li {
		list-style: url("/media/images/liWhite.png");
		color: #FFF;
	}
}
.parallax-window {
	min-height: 330px;
	background: transparent;
	text-align: center;
	a {
		font-family: 'Lato';
		background: #E94F53;
		color: #FFF;
		font-size: 25px;
		padding: 30px;
		border-radius: 50px;
		margin-top: 110px;
	}
}


.download {
	.record {
		padding: 60px 0;
		.title {
			background: url('/media/images/homepage/arrow_blue_box.png') no-repeat top left;
			padding-left: 100px;
			padding-bottom: 40px;
			font-family: 'FuturaLTPro-Book';
			color: #333;
			font-size: 36px;
			margin-top: 75px;
		}
		.txt {
			padding-left: 100px;
			font-size: 20px;
			color: #333;
			padding-bottom: 30px;
		}
		.button {
			margin-top: 130px;
		}
	}
	.record:nth-child(2n) {
		background: #EBEBEB;
	}
}


@media screen and (max-width: $break-sm) {
	.parallax-window {
		background: url('/media/images/offertParalaxa.jpg');
	}


	.download {
		.record {
			padding: 0!important;
			.title {
				background: none;
				padding-left: 0;
				font-size: 25px;
			}
			.txt {
				padding-left: 0px;
				font-size: 20px;
			}
			.button {
				margin-bottom: 50px;
				margin-top: 0px!important;
			}
		}
	}
}

.glyphicon{
	&.glyphicon-menu-down{
		margin-left: 3px;
	}
}

.subactive {
	background-color: $red !important;
}
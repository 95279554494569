$grey: #EEEBE3;
$darkgreen: #003030;

.contact-container{
    

    .contact-left{
        background-color: $grey;
        height: auto;

        .wood-footer-container-contact {
            background-color: $grey;
            height: 640px;
            text-align: center;
            padding: 130px 0;
            color: $darkgreen;

            margin: 0;

            @media screen and (max-width: $break-md){
                padding: 45px 0;
            }
            
            img.wood-footer-upper-shadow
            {
                margin-top: -45px;
                position: absolute;
                left: 0;
            }

            @media screen and (max-width: $break-md){
                height: auto;
            }

            .wood-logo{
                @media screen and (max-width: $break-xs){
                    width: 277px;
                }
            }

            .title{
                font-family: "ChaparralPro-Bold";
                font-size: 32px;
                font-style: italic;

                line-height: 32px;
                margin-top: 32px;

                margin-bottom: 10px;
            }

            .address{
                font-family: "OpenSans-ExtraBold";
                font-size: 20px;

                @media screen and (max-width: $break-lg) and (min-width: 1024px){
                    margin-bottom: -4px;
                }

            }

            a, p{
                color: $darkgreen;
                font-size: 16px;
                font-family: 'OpenSans-ExtraBold';

                text-decoration: none;

                &.phone{
                    font-size: 20px;
                }

                span{
                    font-size: 20px;
                }
            }

            .mail-icon{
                margin-bottom: 26px;
            }

            .phone-icon{
                margin-bottom: 14px;
            }

            .marker-icon{

            }

            .contact-col{
                margin-top: 20px;
            }

        }

        &::after{
                top: 50%;
                left: 100%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(255, 255, 255, 0);
                border-left-color: $grey;
                border-width: 51px;
                margin-top: -51px;
                z-index: 1;

                @media screen and (max-width: $break-md){
                    top: 107%;
                    left: 50%;
                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                    border-color: rgba(255, 255, 255, 0);
                    border-top-color: $grey;
                    border-width: 51px;
                    margin-left: -51px;
                }

                @media screen and (max-width: 379px){
                    top: 105%;
                }
            }
    }

    .contact-right{
        background-image: url('/media/images/contact/kontakt-right.jpg');
        height: 640px;

        @media screen and (max-width: $break-xs){
            background-position-y: 134px;
        }

        .white-container{
            width: 48%; 
            height: auto;

            position: absolute;

            top: 92px;
            left: 37%;

            background-color: white;
            color: $darkgreen;

            padding: 25px;

            @media screen and (max-width: $break-xs){
                width: 100%;
                left: 0;
                top: 0;
                padding-top: 70px;
            }



            .title{
                text-align: center;

                p{
                    font-family: 'ChaparralPro-BoldIt';
                    font-size: 33px;
                    margin: 0;
                }

                .contact-content{
                    font-family: 'OpenSans-Regular';
                    font-size: 20px;
                    line-height: 22px;
                }
                
            }

            &::after{
                top: 100%;
                left: 50%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(255, 255, 255, 0);
                border-top-color: white;
                border-width: 30px;
                margin-left: -30px;
            }
        }
    }
}
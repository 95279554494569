$blue: #2693FF;
$black: #333333;
$red: #E94F53;
$gray: #EBEBEB;

$brown: #726140;
$verydarkbrown: #231605;
$lightbrown: #A18A5B;
$verydarkgreen: #003030;


$lg-break: 1200px;
$md-break: 992px;
$sm-break: 768px;
$xs-break: 480px;
$iphone-break: 355px;

.descriptionInMap{

      max-width: 200px;

      .nameOfMarker{
        font-size: 16px;
        font-family: 'OpenSans-ExtraBold';
        color: $verydarkgreen;
        text-align: center;
        margin-bottom: 10px;
      }

      .descriptionOfMarker{
        font-size: 15px;
        font-family: 'OpenSans-Regular';
        color: $verydarkgreen;
        text-align: center;
        margin-bottom: 10px;
      }

      p{
        text-align: center;

        .seeongoogle{
          text-decoration: underline;
          color: $blue;
          font-family: 'OpenSans-ExtraBold';
          font-size: 16px;
          
        }

        margin-bottom: 0;
      }



    }

.benefits-container{
    background-image: url('/media/images/homepage/benefits.jpg');
    background-size: cover;
    height: 970px;
    padding: 0 4%;

    @media screen and (max-width: 1701px){
      padding: 0 1%;
    }

    @media screen and (max-width: $break-lg){
      padding: 0 5%;
    }

    .blue-button-container{
          margin-top: 15px;
    }

    &.in-product{
       height: 1369px;

      @media screen and (max-width: $break-lg){
        height: auto;
      }

      .blue-button{
        margin-top: 100px;

        @media screen and (max-width: $break-lg){
          margin-top: 50px;
          margin-bottom: 50px;
        }

        @media screen and (max-width: $break-sm){
          margin-top: 100px;
          margin-bottom: 0;
        }

        @media screen and (max-width: $break-xs){
          width: 290px;
        }
      }

      .title{
        
        p{
          margin-top: 206px;

          @media screen and (max-width: $break-lg){
            margin-top: 40px;
          }
        }

        .subtitle{
          margin-top: 0;
        }
      }
    }

    @media all and (max-width: $break-md){
      height: auto;
    }

    @media all and (max-width: $break-sm){
      height: auto;
      padding-bottom: 100px;
    }

    .blue-button{
      @media screen and (max-width: $break-lg){
        margin-top: 50px;
        margin-bottom: 50px;
      }

      @media screen and (max-width: $break-sm){
        margin-top: 100px;
        margin-bottom: 0;
      }
    }

    .title{
      margin-top: 0px !important;
      text-align: center;


      p{
          color: white;
          font-family: 'ChaparralPro-BoldIt';
          font-size: 63px;
          margin-top: 61px;
          margin-bottom: 0px;

          @media screen and (max-width: $break-xs){
            font-size: 50px;
          }
      }

      .subtitle{
        color: $lightbrown;
        font-size: 40px;
        font-family: 'ChaparralPro-Italic';
        line-height: 5px;
        font-weight: 100;
        margin-top: 0;
      }
    }

    .benefit{
      margin-bottom: -61px;
      position: relative;

      @media all and (max-width: $break-sm){
        display: none;
      }

        .text{
          position: absolute;
          color: white;
          font-size: 20px;
          font-family: 'OpenSans-Light';
          text-align: center;
          width: 298px;
          left: 32%;;
          top: 75px;

          @media screen and (max-width: $break-lg){
            left: 36%;
          }

          @media screen and (max-width: $break-md){
            left: 31%;
          }

          &.left-text{
              @media screen and (min-width: $break-lg) and (max-width: 1556px){
                left: 11%;
              }
          }

          &.right-text{
              @media screen and (min-width: $break-lg) and (max-width: 1556px){
                left: 43%; 
              }
          }

          .bold{
            font-family: 'OpenSans-Bold';
          }
        }
      }

      .benefit-mobile{
        display: none;
        margin-top: 50px;


        @media all and (max-width: $break-sm){
          display: block;
        }

          .text{

            color: white;
            font-size: 20px;
            font-family: 'OpenSans-Light';
            text-align: center;
            padding: 0 5%;
            .bold{
              font-family: 'OpenSans-Bold';
            }
          }
      }
  }

.nopadding{
  padding: 0;
}

.nomargin{
  margin: 0;
}

.norightmargin{
  //margin-right: 0;
}

.button-arrow{
  vertical-align: middle;
}

.padding404{
  padding: 0px 22vw;
}

.title404{
  font-size: 4vw !important;
  line-height: 4.5vw;

  @media screen and (max-width: $sm-break){
    font-size: 6vw !important;
    line-height: 6vw;
  }
}

.blue-button-container{
  text-align: center;



  .blue-button{
    background-color: $blue;
    width: 311px;
    height: 56px;
    text-align: center;
    display: inline-block;
    font-size: 17px;
    color: white;
    font-family: 'OpenSans-Bold';
    line-height: 56px;
    cursor: pointer;
    transition: background-color 0.5s;

    &:hover{
      background-color: #46A3FF;
    }
  }
}

.button-find-out-more{
  display: block !important;

  background: $red;
  width: 330px;
  height: 78px;
  border-radius: 39px;
  margin: 0 auto;
  font-size: 26px;
  color: white;
  text-align: center;
  line-height: 70px;
  //margin-top: 88px;
  margin-bottom: 80px;


  transition: all 1s;

  &.rise-up:hover{

    @media screen and (min-width: $lg-break) {
      margin-top: 60px;
      margin-bottom: 145px;
      -webkit-box-shadow: 0px 12px 5px 0px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 12px 5px 0px rgba(0, 0, 0, 0.75);
      box-shadow: 0px 12px 5px 0px rgba(0, 0, 0, 0.75);
    }
  }

  @media screen and (max-width: $xs-break){
    width: 280px;
  }

  &.meet-the-team{
    margin-top: 150px;

    @media screen and (max-width: $lg-break){
      margin-top: 60px;
    }

    @media screen and (max-width: $md-break){
      margin-top: 151px;
    }

    @media screen and (min-width: $md-break){
      margin-top: 115px;
    }

    @media screen and (max-width: $sm-break){
      margin-top: 151px;
    }
  }
}



.line{
  background: $blue;
  height: 6px;
  margin-top: 33px;
}

.line-in-offers{
  background: $blue;
  height: 6px;
  margin-top: 33px;
}

.line-our-value{
  margin-right: 60%;
  margin-left: 30px;
  margin-top: 100px;



  @media screen and (min-width: $xs-break) and (max-width: $sm-break - 1) {
    margin-top: 33px;
  }

  @media screen and (min-width: $sm-break){
    margin-top: 120px;
  }

  @media screen and (max-width: $xs-break){
    margin-top: 120px;
  }

  @media screen and (max-width: $iphone-break){
    margin-top: 166px;
  }

  @media screen and (min-width: $md-break) and (max-width: $lg-break) {
    margin-top: 20px;
  }

}

.margintop150{
  margin-top: 150px;

  @media screen and (min-width: $lg-break) {
    margin-top: 100px;
  }

  @media screen and (max-width: $sm-break) {
    margin-top: 190px;
  }


}

#text-on-slider-container {
    font-family: 'FuturaLTPro-Book';
  @media screen and (max-width: $sm-break){
    display: none;
  }

  position: relative;
  width: 100%;

  #text-on-slider {
    position: absolute;
    font-size: 3.5vw;
    color: white;
    width: 69%;
    height: 300px;
    //background: red;
    z-index: 1;
    //top: 408px;
    top: 20vw;
    left: 16.6%;
  }
}

#scroll-down-button-container{
  @media screen and (max-width: $md-break){
    display: none;
  }

  position: relative;
  width: 100%;
  z-index: 2;


  #scroll-down-button{
    position: absolute;
    top: -40px;
    left: 49.6%;
    cursor: pointer;
  }
}

/* Wobble Horizontal */
@-webkit-keyframes hvr-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }

  33.3% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }

  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }

  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }

  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes hvr-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }

  33.3% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }

  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }

  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }

  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.hvr-wobble-horizontal {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
}
.hvr-wobble-horizontal:hover, .hvr-wobble-horizontal:focus, .hvr-wobble-horizontal:active {
  -webkit-animation-name: hvr-wobble-horizontal;
  animation-name: hvr-wobble-horizontal;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* Wobble Vertical */
@-webkit-keyframes hvr-wobble-vertical {
  16.65% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }

  33.3% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }

  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }

  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }

  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes hvr-wobble-vertical {
  16.65% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }

  33.3% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }

  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }

  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }

  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.hvr-wobble-vertical {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
}
.hvr-wobble-vertical:hover, .hvr-wobble-vertical:focus, .hvr-wobble-vertical:active {
  -webkit-animation-name: hvr-wobble-vertical;
  animation-name: hvr-wobble-vertical;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* Float Shadow */
.hvr-float-shadow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  -webkit-transition-duration: 0.3s;
  -ms-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -ms-transform: transform;
}
.hvr-float-shadow:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: '';
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 0;
  background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
  /* W3C */
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -ms-transform: 0.3s;
  -webkit-transition-property: transform, opacity;
  transition-property: transform, opacity;
  -ms-transform: transform, opacity;
}
.hvr-float-shadow:hover, .hvr-float-shadow:focus, .hvr-float-shadow:active {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  /* move the element up by 5px */
}
.hvr-float-shadow:hover:before, .hvr-float-shadow:focus:before, .hvr-float-shadow:active:before {
  opacity: 1;
  -webkit-transform: translateY(5px);
  transform: translateY(5px);
  -ms-transform: translateY(-5px);
  /* move the element down by 5px (it will stay in place because it's attached to the element that also moves up 5px) */
}

.owl-caption{
  position: absolute;
  font-size: 44px;
  color: $verydarkgreen;
  top: 20vw;
  left: 16.6%;
  width: 69%;
  font-family: 'ChaparralPro-BoldIt';
  text-align: center;
  padding: 32px 49px;


  @media screen and (max-width: $sm-break){
    background-color: white;
    width: 100%;
    left: 0;
    position: relative;
    top: 0;
    height: 36vw;
    font-size: 6vw;
    padding: 4vw;
    text-align: center;
    left: 0 !important;
    top: 0 !important;
    height: auto;
  }

  @media screen and (max-width: $xs-break){
    font-size: 32px;
  }
  
  &.caption1{
        top: 12vw;
        left: 53.7%;

        @media screen and (max-width: $break-lg){
          top: 3vw;
          left: 29.7%;
        }
  }

  &.caption2{
    top: 12vw; 
    left: 12.5%;
  }

  &.caption3{
    top: 12vw;
    left: 34.6%;
    line-height: 41px;

    @media screen and (max-width: $sm-break){
      line-height: 27px;
    }
  }

    
}



.homepage{



  

  .map-container{
    background-image: url('/media/images/homepage/map2.jpg');
    background-size: cover;
    height: 970px;
    background-position-x: 50%;

    // @media screen and (max-width: 1500px) and (min-width: 1200px){
    //   background-image: url('/media/images/homepage/map2.jpg');
    // }

    @media screen and (max-width: $break-lg) and (min-width: $break-md){
      background-size: 100% 1016px;
    }

    .blue-button-container{
      margin-top: 85px;
    }

    .title{
      margin-top: 0px;
      p{
          color: $verydarkbrown;
          font-family: 'ChaparralPro-BoldIt';
          font-size: 70px;
          margin-top: 47px;
          margin-bottom: 0px;
      }

      .subtitle{
        color: white;
        font-size: 40px;
        font-family: 'ChaparralPro-Italic';
        line-height: 5px;

        @media screen and (max-width: 418px){
          font-size: 26px;
        }
      }
    }

    .map-paper{
      background-image: url('/media/images/homepage/mappaper.png');
      background-repeat: no-repeat;
      height: 609px;
    }


    
  }

  .szczyrk-container{
    background-image: url('/media/images/homepage/szczyrk.jpg');
    height: 1180px;

    @media screen and (max-width: $sm-break){
        height: auto;
    }

    .info{
      -webkit-box-shadow: 10px 10px 29px 0px rgba(0,0,0,0.75);
      -moz-box-shadow: 10px 10px 29px 0px rgba(0,0,0,0.75);
      box-shadow: 10px 10px 29px 0px rgba(0,0,0,0.75);

      background-image: url('/media/images/homepage/snowy-background.jpg');
      height: 882px;
      width: 661px;
      padding: 0 55px;
      margin-top: 153px;
      margin-left: 8.6%;
      margin-bottom: 84px;
      padding-top: 70px;

      @media screen and (max-width: $sm-break){
        margin-left: 0;
        margin-top: 0px;
        width: inherit;
        background-size: cover;
        background-size: 100% 100%;
        height: auto;
        padding-bottom: 50px;
        margin-bottom: -1px;  //usuniecie linii pod kontenerem

        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }

      @media screen and (max-width: $xs-break){
        background-image: none;
        background-color: white;
      }

      .title{
          margin-top: 0px;
          margin-bottom: 83px;
          p{
              color: $verydarkgreen;
              font-family: 'ChaparralPro-BoldIt';
              font-size: 64px;
              margin-bottom: 0px;

              &.szczyrk-title{
                @media all and (max-width: 393px){
                  font-size: 50px;
                }
              }
          }

          .subtitle{
            color: $brown;
            font-size: 37px;
            font-family: 'ChaparralPro-Italic';
            line-height: 16px;

            @media screen and (max-width: $sm-break){
              font-size: 28px;
            }

            @media screen and (max-width: $xs-break){
              font-size: 32px;
              line-height: 30px;
            }
          }

          h1{
            font-family: 'ChaparralPro-BoldIt';
            font-size: 28px;
            margin-top: 62px;
            color: $verydarkgreen;

            &.wypoczynek{
              margin-top: 34px;
            }

            &.tutaj{
              margin-top: 32px;
            }
          }

          h2{
            color: $brown;
            font-family: 'OpenSans-Regular';
            font-size: 20px;
            line-height: 30px;
            margin-top: -3px;
          }
      }

      .blue-button{
        height: 59px;
        width: 226px;
        @media all and (max-width: $break-xs){
          width: 220px;
        }
      }
    }
  }

  



  .nopadding{
    padding: 0;
  }

  .nomargin{
    margin: 0;
  }

  .link-for-button{
    text-decoration: none;
  }

  .title{
    font-size: 5vw;
    text-align: center;
    margin-top: 100px;
    font-family: 'FuturaLTPro-Book';
    font-weight: 100;
    @media screen and (max-width: $lg-break) {
      font-size: 66px;
    }

    @media screen and (max-width: $xs-break){
      font-size: 16vw;
    }
  }

    .slider {
      position: relative;
      background: url('/media/images/homepage/slider_image1.png');
      background-repeat: no-repeat;
      height: 944px;
    }

    .blue-bar{
      height: 23px;
      background: $blue;
      @media screen and (max-width: $sm-break){
        display: none;
      }
    }

    .blue-bar-with-text{
      display: none;
      background: $blue;
      color: white;
      font-size: 25px;
      padding: 33px;
      text-align: center;
      @media screen and (max-width: $sm-break){
        display: block;
      }
    }

    .blue-triangle{
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 52px 56px 0 56px;
      border-color: $blue transparent transparent transparent;
      margin: 0 auto;
    }

    .statistics-container {
      margin-left: 70px;
      margin-bottom: 100px;
      margin-top: 80px;

      @media screen and (max-width: $sm-break) {
        margin: 0;
        margin-bottom: 100px;
      }

      .statistics {

        border-bottom: solid 8px $blue;

        @media screen and (max-width: $md-break) {
          margin-top: 100px;
          margin-left: 33px;
        }

        .over {
          font-size: 21px;
          color: $black;
          white-space: nowrap;
          margin-bottom: 10px;

          @media screen and (max-width: $md-break){
            font-size: 5vw;
          }
        }

        .big-text {
          font-family: 'FuturaLTPro-Bold';
          color: $blue;
          font-weight: bolder;
          float: left;
          font-size: 8vw;
          margin-top: -30px;

          @media screen and (max-width: $md-break) {
            font-size: 23vw;
            margin-top: -20px;
          }

          .small-text {

            @media screen and (min-width: $md-break) {
              font-size: 2.4vw;
            }

            @media screen and (max-width: $md-break) {
              font-size: 11vw;
            }


          }
        }

        .thin-text {
          font-size: 2.5vw;
          font-family: "Lato";
          color: $black;
          font-weight: 100;
          line-height: 2.5vw;
          margin-top: -15px;
          margin-bottom: 2.5vw;

          @media screen and (max-width: $md-break) {
            font-size: 46px;
            line-height: 64px;
          }

          @media screen and (max-width: $sm-break){
            font-size: 9vw;
            line-height: 9vw;
          }
        }
      }
    }

    .products-and-services{
     //background: url('/media/images/homepage/productsandservices.png');

      background-image: url('/media/images/homepage/productsandservices.jpg');
      background-color: $gray;
      background-size: 100% 100%;

      height: 1000px;
      color: $black;

      @media screen and (max-width: $lg-break) {
        background-image: url('/media/images/homepage/productsandservices_medium.jpg');
        height: 1650px;
      }

      @media screen and (max-width: $sm-break - 1) {
        background-image: url('/media/images/homepage/productsandservices_medium.jpg');
        //background-size: cover;
        height: 3380px;
      }

      @media screen and (max-width: $xs-break) {
        background-image: url('/media/images/homepage/productsandservices_mobile_new.jpg');
        //background-size: cover;
        height: 3400px;
      }

      .selected{
        opacity: 0.35;
        cursor: default !important;
      }

      &.error404{
        @media screen and (width: $sm-break){
          height: 1830px;
        }

        @media screen and (max-width: $sm-break - 1){
          height: 3600px;
        }

        @media screen and (max-width: $xs-break){
          height: 3400px;
        }

      }





      .clouds {
        //margin-left: 25px;
        .cloud {

          .products {
            height: 100px;
            background: url('/media/images/homepage/cloud_products.png');
            background-repeat: no-repeat;
            margin-left: -15px;

            @media screen and (max-width: $xs-break){
              background: url('/media/images/homepage/cloud_products_mobile.png');
              height: 66px;
              width: 220px;
              margin-left: -16%;
            }

          }

          .services {
            height: 100px;
            background: url('/media/images/homepage/cloud_services.png');
            background-repeat: no-repeat;
            margin-left: -14px;

            @media screen and (max-width: $xs-break){
              background: url('/media/images/homepage/cloud_services_mobile.png');
              height: 66px;
              width: 220px;
              margin-left: -16%;
            }
          }
        }
      }

      .margintop{
        margin-top: 112px;
      }

      .products {
        margin-left: 3%;

        @media screen and (max-width: $xs-break){
          margin-left: -11%;
        }

        .first-half-in-offers{
          padding: 0 11.3%;

          @media screen and (max-width: $lg-break){
            padding: 0 21.3%;
          }

          @media screen and (max-width: $sm-break){
            padding: 0;
          }
        }

        .image{
          //background: green;
          height: 234px;
          overflow: hidden;

          img{
            width: 100%;

            @media screen and (max-width: $sm-break) {
              max-width: 291px;
            }
          }
        }

        .text{
          color: $black;

          font-size: 25px;

          font-family: 'Lato';
          font-weight: 900;

          position: relative;
          height: 80px;
          margin-left: 15px;

          @media screen and (max-width: $md-break) {
            font-size: 26px;
          }

          @media screen and (max-width: $sm-break) {
            font-size: 27px;
          }

          @media screen and (max-width: $xs-break){
            font-size: 25px;

          }

          .text-at-bottom{
            position: absolute;
            bottom: 0;
            left: 0;
            transition: all 0.5s;
          }


        }

        $bottom-line-thickness: 7px;

        .product {
          //background: red;
          height: 390px;
          border-bottom: solid $bottom-line-thickness $black;
          cursor: pointer;

          -webkit-transition: all 0.5s;
          transition: all 0.5s;

          .grow { transition: all 0.5s ease-in-out; }
          &:hover .grow { transform: scale(1.5); }
          &:hover .text-at-bottom-hover{
            color: $blue;
          }

          &:hover.hoverable{
            border-bottom: solid $bottom-line-thickness $blue;
          }

          @media screen and (max-width: $sm-break) {
            margin-top: 50px;
          }

          .number{
            font-size: 36px;
            color: $blue;
            border: solid 3px $blue;
            background: $gray;
            margin-left: 15px;
            width: 64px;
          }




        }

        .service{
          //background: blue;
          height: 390px;
          border-bottom: solid $bottom-line-thickness $black;
          cursor: pointer;

          -webkit-transition: all 0.5s;
          transition: all 0.5s;

          .grow { transition: all 0.5s ease-in-out; }
          &:hover .grow { transform: scale(1.5); }
          &:hover .text-at-bottom-hover{
            color: $blue;
          }

          &:hover.hoverable{
            border-bottom: solid $bottom-line-thickness $blue;
          }

          @media screen and (max-width: $sm-break) {
            margin-top: 50px;
          }

          .number{
            font-size: 36px;
            color: $gray;
            border: solid 3px $blue;
            background: $blue;
            margin-left: 15px;
            width: 64px;
          }
        }

        .secondhalf{
          margin-top: 0px;

          @media screen and (max-width: $lg-break) {
            margin-top: 50px;
          }
        }
      }


    }

    .our-values {
      background: url('/media/images/homepage/ourvalues.jpg');
      background-size: cover;
      height: 1140px;
      color: white;
      background-size: 100% 100%;

      @media screen and (max-width: $lg-break) {
        height: 1940px;
        //background: url('/media/images/homepage/ourvalues.png');
        //background-size: cover;
      }

      @media screen and (max-width: $md-break){
        background-image: url('/media/images/homepage/ourvalues_tablet.jpg');
      }


      @media screen and (max-width: $sm-break - 1) {
        height: 3740px;
        background-image: url('/media/images/homepage/ourvalues_mobile.jpg');
      }

      @media screen and (max-width: $xs-break) {
        height: 3518px;
      }




      .our-values-container{
        margin-left: 2%;
        margin-top: 90px;

        @media screen and (max-width: $sm-break - 1){
          margin-top: 0;
        }

        @media screen and (min-width: $sm-break) and (max-width: $md-break){
          margin-left: 0;
        }



        .our-value{

          height: 491px;

          -webkit-transition: margin-top 1s;
          transition: margin-top 1s;

          &.selected{
            @media screen and (min-width: $lg-break) {
              margin-top: -58px;
            }
          }


          @media screen and (max-width: $sm-break - 1) {
            margin-top: 100px;
            margin-left: 4%;
          }

          .title-our-value{
            font-family: 'FuturaLTPro-Book';
            float: left;
            font-size: 1.7vw;
            margin-left: 10px;
            margin-top: 7px;
            margin-bottom: 0;
            color: white;

            @media screen and (max-width: $lg-break) {
              font-size: 24px;
            }

            @media screen and (max-width: $sm-break - 1) {
              font-size: 32px;
            }

            @media screen and (max-width: $xs-break) {
              font-size: 20px;
            }

          }

          .image-our-value{
            width: 100%;
            -webkit-box-shadow: -13px 12px 32px 0px rgba(0,0,0,0.75);
            -moz-box-shadow: -13px 12px 32px 0px rgba(0,0,0,0.75);
            box-shadow: -13px 12px 32px 0px rgba(0,0,0,0.75);
          }

          //.our-value-header:hover + .text-our-value-container, .text-our-value-container:hover{
          //  @media screen and (min-width: $lg-break) {
          //    display: block;
          //    height: 414px;
          //    padding-bottom: 50px;
          //    //margin-top: -58px;
          //  }
          //}



          .text-our-value-container {
            background: white;
            padding-bottom: 50px;
            height: 420px;
            position: relative;
            z-index: 1;
            margin-top: -1px;

            -webkit-box-shadow: -13px 12px 32px 0px rgba(0,0,0,0.75);
            -moz-box-shadow: -13px 12px 32px 0px rgba(0,0,0,0.75);
            box-shadow: -13px 12px 32px 0px rgba(0,0,0,0.75);

            &.selected2{
              @media screen and (min-width: $lg-break) {
                display: block;
                height: 414px;
                padding-bottom: 50px;
                //margin-top: -58px;
              }
            }

            @media screen and (min-width: $lg-break) {
              height: 0px;
              padding-bottom: 0px;
              overflow: hidden;
              -webkit-transition: height 1s, padding-bottom 1s;
              transition: height 1s, padding-bottom 1s;

            }

            @media screen and (min-width: $md-break) and (max-width: $lg-break){
              height: 320px;
            }

            @media screen and (max-width: $xs-break) {
              height: 420px;
            }

            @media screen and (max-width: $iphone-break) {
              height: 464px;
            }




            .text-our-value {
              color: $black;
              background: white;
              font-size: 20px;
              padding: 30px;
              height: 260px;

              @media screen and (min-width: $lg-break){
                font-size: 1.3vw;
              }

              .text-bold{
                font-weight: bold;
              }
            }
          }
        }

        .our-value-second{
          @media screen and (max-width: $sm-break - 1) {
            margin-top: 320px;
          }

          @media screen and (max-width: $xs-break) {
            margin-top: 230px;
          }
        }

        .secondhalf{
          margin-top: 0;

          @media screen and (max-width: $lg-break) {
            margin-top: 250px;
          }

          @media screen and (max-width: $xs-break) {
            margin-top: 145px;
          }
        }
      }



    }
}

#owl{
  position: relative;
  z-index: 2;

  -webkit-box-shadow: 0px 10px 18px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 18px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 18px 0px rgba(0, 0, 0, 0.75);
}

.owl-theme .owl-controls{
            margin-top: 10px;
            text-align: center;
        }

        /* Styling Next and Prev buttons */

        .owl-theme .owl-controls .owl-buttons div{
            color: #FFF;
            display: inline-block;
            zoom: 1;
            *display: inline;/*IE7 life-saver */
            margin: 5px;
            padding: 3px 10px;
            font-size: 12px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            border-radius: 30px;
            background: #869791;
            filter: Alpha(Opacity=50);/*IE7 fix*/
            opacity: 1;
            background-color: rgba(255,255,255,0.5);
        }
        /* Clickable class fix problem with hover on touch devices */
        /* Use it for non-touch hover action */
        .owl-theme .owl-controls.clickable .owl-buttons div:hover{
            filter: Alpha(Opacity=100);/*IE7 fix*/
            opacity: 1;
            text-decoration: none;
        }

        /* Styling Pagination*/

        .owl-theme .owl-controls .owl-page{
            display: inline-block;
            zoom: 1;
            *display: inline;/*IE7 life-saver */
        }
        .owl-theme .owl-controls .owl-page span{
            display: block;
            width: 12px;
            height: 12px;
            margin: 5px 7px;
            filter: Alpha(Opacity=50);/*IE7 fix*/
            opacity: 1;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            border-radius: 20px;
            background: #869791;
        }

        .owl-theme .owl-controls .owl-page.active span,
        .owl-theme .owl-controls.clickable .owl-page:hover span{
            filter: Alpha(Opacity=100);/*IE7 fix*/
            opacity: 1;
        }

        /* If PaginationNumbers is true */

        .owl-theme .owl-controls .owl-page span.owl-numbers{
            height: auto;
            width: auto;
            color: #FFF;
            padding: 2px 10px;
            font-size: 12px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            border-radius: 30px;
        }

        #owl-demo .owl-item > div img {
            display: block;
            width: 100%;
            height: auto;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            border-radius: 3px;
            margin-bottom:4px;
        }

        #owl-demo .owl-item > div{
            background : #42bdc2;
            text-align: center;
            padding:50px 0px;
            margin:3px;
            color: white;
            font-size:32px;
            border:1px white;
        }

        .wrapper-with-margin{
            margin:0px 50px;
        }

        .owl-controls{
            margin-bottom: -10px;
        }


        .owl-theme .owl-controls .owl-buttons div {
            position: absolute;
        }

        .owl-theme .owl-controls .owl-buttons .owl-prev{
            left: 30px;
            top: 22vw;
            width: 82px;
            height: 82px;
            border-radius: 41px;
            font-size: 32px;
            opacity: 1;
            background: none;
        }

        .owl-theme .owl-controls .owl-buttons .owl-next{
            right: 30px;
            top: 22vw;
            width: 82px;
            height: 82px;
            border-radius: 41px;
            font-size: 32px;
            opacity: 1;
            background: none;
        }

        @media screen and (max-width: 992px) {
            .owl-theme .owl-controls .owl-buttons .owl-prev{
                display: none;
            }

            .owl-theme .owl-controls .owl-buttons .owl-next{
                display: none;
            }
        }


.newsletter-container{
        background: url('/media/images/newsletter.jpg');
        height: 1286px;

        @media screen and (max-width: $break-sm){
          height: auto;
        }

        img.newsletter-lower-shadow
        {
            margin-bottom: -49px;
            position: absolute;
            bottom: 0;
            /* z-index: 1000; */
            left: 0;
        }
        .newsletter-box{
            border: solid 7px white;
            height: 775px;
            text-align: center;
            margin-top:200px;
            width: 640px;
            margin: 0 auto;
            margin-top: 205px;

            padding: 0 2%;

            

            @media screen and (max-width: $break-sm){
              width: 100%;
              margin-top: 0;
              height: auto;
              margin-bottom: 60px;
              padding: 0 5%;
            }

            @media screen and (max-width: $break-xs){
              padding: 0 50px;
            }

            .border-bottom
            {
                border-bottom: solid 7px white;
                position: absolute;
                display: block;
                height:7px;
                margin-top: -28px;
                &.left
                {
                    left: 0px;
                    width: 25%;
                    margin-left: -7px;
                }
                &.right
                {
                    right: 0px;
                    width: 25%;
                    margin-right: -7px;
                }

            }
            &.kek:after
            {
                content: '';
                width: 25%;
                margin-left: -7px;
                margin-top: -28px;
                display: block;
                /* height: 180px; */
                /* width: 100px; */
                position: absolute;
                left: 0px;
                border-bottom: solid 7px white;
            }
            @media screen and (max-width: $break-sm){
                border: none;
            }

            .title{
                margin-top: 0px;
                text-align: center;
                p{
                  color: white;
                  font-family: 'ChaparralPro-BoldIt';
                  font-size: 124px;
                  margin-top: 43px;
                  margin-bottom: 0px;
                  line-height: 124px;

                  @media screen and (max-width: $break-sm){
                    font-size: 90px;
                  }

                  @media screen and (max-width: $break-xs){
                    font-size: 62px;
                    line-height: 60px;
                  }
                }

              .subtitle{
                color: white;
                font-size: 31px;
                font-family: 'ChaparralPro-BoldIt';
                line-height: 5px;

                @media screen and (max-width: $break-sm){
                  font-size: 26px;
                }

                @media screen and (max-width: $break-xs){
                  line-height: 30px;
                }


              }

              .blue-text{
                color: $blue;
                font-size: 20px;
                margin-bottom: 0;
                font-family: 'OpenSans-BoldItalic';
                line-height: 25px;
                margin-top: 35px;

               }
            }

            .getyourmail{
                color: white;
                font-size: 25px;
                text-align: center;
                margin-top: 47px;
                font-size: 18px;
            }

            form{
                color: white;

                label{
                  width: 67%;
                  margin-top: 33px;

                  &.second-label{
                    margin-top: 3px;
                  }
                }

                input[type=text] {
                    padding: 8px 20px;
                    margin: 8px 0;
                    box-sizing: border-box;
                    background: none;
                    border: solid 2px white;
                    font-family: 'OpenSans-Italic';
                    font-size: 22px;
                    width: 309px;

                    &:focus{
                        outline-width: 0;
                        border-color: $blue;
                    }

                    &::placeholder{
                        color: white;
                    }
                    
                    @media screen and (max-width: $break-xs){
                      width: 100%;
                    }

                }

                input[type=checkbox] {
                    float: left;

                }

                label {
                    font-family: 'OpenSans-Regular';
                    font-size: 10px;
                    font-weight: 100;
                }

                span{
                    color: $red;
                    font-size: 11px;
                }

                .blue-button{
                    border: none;
                    margin-top: 40px;

                    @media screen and (max-width: $break-xs){
                        width: 170px;
                        height: 71px;
                        line-height: 36px;

                    }
                }
            }
        }
    }

    .selection-container{
    background-color: white;
    height: auto;

    .title{
      margin-top: 0px;
      padding: 0 40px;
      p{
          color: $verydarkgreen;
          font-family: 'ChaparralPro-BoldIt';
          font-size: 70px;
          margin-top: 47px;
          margin-bottom: 0px;
          text-align: center;

          @media screen and (max-width: $break-sm){
            font-size: 50px;
            line-height: 55px;
          }
      }

      .subtitle{
        color: $brown;
        font-size: 40px;
        font-family: 'ChaparralPro-Italic';
        line-height: 5px;
        text-align: center;

        @media screen and (max-width: $break-sm){
          margin-top: 20px;
        }
      }
    }

    $house-height: 800px;

    @mixin blue-button() {
        background-color: $blue;
        width: 250px;
        height: 56px;
        text-align: center;
        font-size: 17px;
        color: white;
        font-family: 'OpenSans-Bold';
        line-height: 56px;
        cursor: pointer;
        display: block;
        margin: 0 auto;

          @media all and (max-width: 441px){
            width: 279px;
          }

    }
    $transition: background-image 0.5s;

    .house-container{
      margin-top: 100px;

      .house{

      }

      .house-b{
        background-image: url('/media/images/homepage/1a.png');
        background-repeat: no-repeat;
        height: $house-height;
        position: relative;
        padding-top: 518px;

        //margin-left: -22px;

        transition: $transition;
        -webkit-transition: $transition;
        -moz-transition: $transition;
        -o-transition: $transition;
        -ms-transition: $transition;

        background-position-x: 50%;
        @media screen and (max-width: 1650px) and (min-width: $break-lg){
            background-size: 100%;
        }

        @media screen and (max-width: $break-lg){
          background-image: url('/media/images/homepage/1b.png');
        }

        @media screen and (max-width: $break-xs){
          background-size: 410px;
        }

        .blue-button{
          @include blue-button();

          

          // @media all and (max-width: $break-lg){
          //   left: 7%;
          // }
        }

      &:hover{
        background-image: url('/media/images/homepage/1b.png');
      }
    }
      

      .house-a{
        background-image: url('/media/images/homepage/2a.png');
        background-repeat: no-repeat;
        height: $house-height;
        position: relative;
        padding-top: 518px;

        //margin-left: -22px;

        transition: $transition;
        -webkit-transition: $transition;
        -moz-transition: $transition;
        -o-transition: $transition;
        -ms-transition: $transition;

        background-position-x: 50%;
        @media screen and (max-width: 1400px) and (min-width: $break-lg){
            background-size: 100%;
        }

        @media screen and (max-width: $break-lg){
          background-image: url('/media/images/homepage/2b.png');
        }

        
    


        @media screen and (max-width: $break-xs){
          background-size: 410px;
          //background-position-x: 16%;
        }

        @media screen and (max-width: 386px){
          //background-position-x: 29%;
        }

        @media screen and (max-width: 364px){
          // background-position-x: 39%;
        }

        .blue-button{
          @include blue-button();
          display: block;
          margin: 0 auto;
        }

        &:hover{
          background-image: url('/media/images/homepage/2b.png');
        }
      }

      .house-c{
        background-image: url('/media/images/homepage/3a.png');
        background-repeat: no-repeat;
        height: $house-height;
        position: relative;
        padding-top: 518px;

        //margin-left: -22px;

        transition: $transition;
        -webkit-transition: $transition;
        -moz-transition: $transition;
        -o-transition: $transition;
        -ms-transition: $transition;

        background-position-x: 50%;

        @media screen and (max-width: 1400px) and (min-width: $break-lg){
            background-size: 100%;
        }

        @media screen and (max-width: $break-lg){
          background-image: url('/media/images/homepage/3b.png');
        }

        @media screen and (max-width: $break-xs){
          background-size: 410px;
        }

        .blue-button{
          @include blue-button();
          display: block;
          margin: 0 auto;
        }

        &:hover{
          background-image: url('/media/images/homepage/3b.png');
        }
      }
    }


  }

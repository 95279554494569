@font-face {
  font-family: 'FuturaLTPro-Book';
  src: url('/media/fonts/FuturaLTPro-Book.eot?#iefix') format('embedded-opentype'),  
  url('/media/fonts/FuturaLTPro-Book.otf')  format('opentype'),
	     url('/media/fonts/FuturaLTPro-Book.woff') format('woff'),
	      url('/media/fonts/FuturaLTPro-Book.ttf')  format('truetype'), 
	      url('/media/fonts/FuturaLTPro-Book.svg#FuturaLTPro-Book') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'FuturaLTPro-Bold';
  src: url('/media/fonts/FuturaLTPro-Bold.eot?#iefix') format('embedded-opentype'),
       url('/media/fonts/FuturaLTPro-Bold.otf')  format('opentype'),
	     url('/media/fonts/FuturaLTPro-Bold.woff') format('woff'),
       url('/media/fonts/FuturaLTPro-Bold.ttf')  format('truetype'),
       url('/media/fonts/FuturaLTPro-Bold.svg#FuturaLTPro-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ChaparralPro-Bold';
  src: url("/media/fonts/ChaparralPro-Bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal; 
}

@font-face {
  font-family: 'ChaparralPro';
  src: url("/media/fonts/Chaparral-Pro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; 
}

@font-face {
  font-family: 'ChaparralPro-BoldIt';
  src: url("/media/fonts/ChaparralPro-BoldIt.otf") format("opentype");
  font-weight: normal;
  font-style: normal; 
}

@font-face {
  font-family: 'ChaparralPro-Italic';
  src: url("/media/fonts/ChaparralPro-Italic.otf") format("opentype");
  font-weight: normal;
  font-style: normal; 
}

@font-face {
  font-family: 'OpenSans-Bold';
  src: url("/media/fonts/OpenSans-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; 
}

@font-face {
  font-family: 'OpenSans-ExtraBold';
  src: url("/media/fonts/OpenSans-ExtraBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; 
}

@font-face {
  font-family: 'OpenSans-Regular';
  src: url("/media/fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; 
}

@font-face {
  font-family: 'OpenSans-Light';
  src: url("/media/fonts/OpenSans-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; 
}

@font-face {
  font-family: 'OpenSans-BoldItalic';
  src: url("/media/fonts/OpenSans-BoldItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; 
}

@font-face {
  font-family: 'OpenSans-Italic';
  src: url("/media/fonts/OpenSans-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; 
}



// alegreyablack
///* Generated by Font Squirrel (https://www.fontsquirrel.com) on December 29, 2016 */



@font-face {
    font-family: 'alegreyablack';
    src: url('/media/fonts/alegreya-black-webfont.woff2') format('woff2'),
         url('/media/fonts/alegreya-black-webfont.woff') format('woff'),
         url('/media/fonts/alegreya-black-webfont.svg#alegreyablack') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'alegreyablack_italic';
    src: url('/media/fonts/alegreya-blackitalic-webfont.woff2') format('woff2'),
         url('/media/fonts/alegreya-blackitalic-webfont.woff') format('woff'),
         url('/media/fonts/alegreya-blackitalic-webfont.svg#alegreyablack_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'alegreyabold';
    src: url('/media/fonts/alegreya-bold-webfont.woff2') format('woff2'),
         url('/media/fonts/alegreya-bold-webfont.woff') format('woff'),
         url('/media/fonts/alegreya-bold-webfont.svg#alegreyabold') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'alegreyabold_italic';
    src: url('/media/fonts/alegreya-bolditalic-webfont.woff2') format('woff2'),
         url('/media/fonts/alegreya-bolditalic-webfont.woff') format('woff'),
         url('/media/fonts/alegreya-bolditalic-webfont.svg#alegreyabold_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'alegreyaitalic';
    src: url('/media/fonts/alegreya-italic-webfont.woff2') format('woff2'),
         url('/media/fonts/alegreya-italic-webfont.woff') format('woff'),
         url('/media/fonts/alegreya-italic-webfont.svg#alegreyaitalic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'alegreyaregular';
    src: url('/media/fonts/alegreya-regular-webfont.woff2') format('woff2'),
         url('/media/fonts/alegreya-regular-webfont.woff') format('woff'),
         url('/media/fonts/alegreya-regular-webfont.svg#alegreyaregular') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'alegreya_scblack';
    src: url('/media/fonts/alegreyasc-black-webfont.woff2') format('woff2'),
         url('/media/fonts/alegreyasc-black-webfont.woff') format('woff'),
         url('/media/fonts/alegreyasc-black-webfont.svg#alegreya_scblack') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'alegreya_scblack_italic';
    src: url('/media/fonts/alegreyasc-blackitalic-webfont.woff2') format('woff2'),
         url('/media/fonts/alegreyasc-blackitalic-webfont.woff') format('woff'),
         url('/media/fonts/alegreyasc-blackitalic-webfont.svg#alegreya_scblack_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'alegreya_scbold';
    src: url('/media/fonts/alegreyasc-bold-webfont.woff2') format('woff2'),
         url('/media/fonts/alegreyasc-bold-webfont.woff') format('woff'),
         url('/media/fonts/alegreyasc-bold-webfont.svg#alegreya_scbold') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'alegreya_scbold_italic';
    src: url('/media/fonts/alegreyasc-bolditalic-webfont.woff2') format('woff2'),
         url('/media/fonts/alegreyasc-bolditalic-webfont.woff') format('woff'),
         url('/media/fonts/alegreyasc-bolditalic-webfont.svg#alegreya_scbold_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'alegreya_scitalic';
    src: url('/media/fonts/alegreyasc-italic-webfont.woff2') format('woff2'),
         url('/media/fonts/alegreyasc-italic-webfont.woff') format('woff'),
         url('/media/fonts/alegreyasc-italic-webfont.svg#alegreya_scitalic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'alegreya_scregular';
    src: url('/media/fonts/alegreyasc-regular-webfont.woff2') format('woff2'),
         url('/media/fonts/alegreyasc-regular-webfont.woff') format('woff'),
         url('/media/fonts/alegreyasc-regular-webfont.svg#alegreya_scregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
$brown: #72603F;

.reasons{
        background-color: white;
        padding: 0 20px;
        height: auto;

        .reasons-title{
            color: $darkgreen;
            font-size: 40px;
            font-family: 'ChaparralPro-BoldIt';
            text-align: center;
            margin-top: 56px;

            @media screen and (max-width: $break-xs){
                font-size: 32px;
            }
        }

        .reasons-container{
            margin-top: 40px;
            margin-bottom: 100px;
            .reasons-column{
                padding: 0 100px;
                @media screen and (max-width: $break-xs){
                    padding: 0 42px;
                }

                ul{
                    li{
                        background: url(/media/images/css/check.png) no-repeat center left;
                        padding-left: 60px;
                        list-style: none;
                        font-size: 22px;
                        color: $brown;
                        margin: 40px 0;
                        font-family: 'ChaparralPro-Italic';

                        @media screen and (max-width: $break-xs){
                          font-size: 17px;
                          line-height: 22px;
                        }

                        span{
                            font-size: 28px;
                            font-family: 'ChaparralPro-BoldIt';
                            color: $darkgreen;

                            @media screen and (max-width: $break-xs){
                              font-size: 20px;
                            }
                        }

                    }
                }
            }
        }
    }

.why-domki-container{
    .film-container{
        background: url('/media/images/woodfooter.jpg');
        height: 620px;

        -webkit-box-shadow: inset 0px 210px 96px -178px rgba(0, 0, 0, 0.8),inset 0px -210px 96px -178px rgba(0, 0, 0, 0.8);
        -moz-box-shadow: inset 0px 210px 96px -178px rgba(0, 0, 0, 0.8),inset 0px -210px 96px -178px rgba(0, 0, 0, 0.8);
        box-shadow: inset 0px 210px 96px -178px rgba(0, 0, 0, 0.8),inset 0px -210px 96px -178px rgba(0, 0, 0, 0.8);

        @media screen and (max-width: $break-lg){
            height: auto;
        }

        .film-row{

            margin-top: 103px;

            @media screen and (max-width: $break-lg){
                margin-top: 0px;
            }

            .film-left{
                background: url('/media/images/why-domki/zobacz-film.jpg');
                background-size: cover;
                height: 399px;

                @media screen and (max-width: $break-lg){
                    height: 498px;
                    margin: 0 109px;
                }

                @media screen and (max-width: $break-md){
                    margin: 0 0;
                }

                @media screen and (max-width: $break-sm){
                    background-position: 50%;
                }

                @media screen and (max-width: $break-xs){
                    height: 360px;
                }
            }

            .film-right{
                background: url('/media/images/why-domki/film-placeholder.jpg');
                background-size: cover;
                background-position: 50%;
                height: 399px;

                @media screen and (max-width: $break-lg){
                    height: 498px;
                    margin: 0 109px;
                }

                @media screen and (max-width: $break-md){
                  margin: 0 0;
                }

                @media screen and (max-width: $break-sm){
                    background-position: 50%;
                }
            }
        }

        
    }

    .cytat-container{
        background-image: url('/media/images/why-domki/cytat-background2.jpg');
        background-size: cover;
        height: 1138px;
        background-position-x: 50%;

        @media screen and (max-width: 1460px){
            background-image: url('/media/images/why-domki/cytat-background2-md.jpg');
        }

        @media screen and (max-width: $break-sm){
            background-image: url('/media/images/why-domki/cytat-background2-sm.jpg');
        }

        .cytat{
            color: white;
            font-family: "ChaparralPro-BoldIt";
            font-size: 36px;
            width: 974px;
            margin: 0 auto;
            line-height: 46px;
            padding-top: 406px;

            @media screen and (max-width: 1460px){
                width: 628px;
                padding-top: 319px;
                line-height: 44px;
            }

            @media screen and (max-width: $break-sm){
                line-height: 29px;
                width: 249px;
                padding-top: 251px;
                font-size: 24px;
                text-align: center;
            }

            .landlords{
                color: white;
                font-family: 'OpenSans-ExtraBold';
                font-size: 20px;
                text-align: right;
                line-height: 34px;
                margin-top: 10px;

                @media screen and (max-width: 1460px){
                    margin-top: 56px;
                }

                 @media screen and (max-width: $break-sm){
                    width: 251px;
                    text-align: center;
                 }

                span{
                    font-family: 'OpenSans-Regular';
                }
            }
        }



        
    }

    

    .szczyrk-container{
        background-image: url('/media/images/why-domki/opinie-background.jpg');
        height: 1265px;

        @media screen and (max-width: $sm-break){
            height: auto;
        }

        .info{
          -webkit-box-shadow: 10px 10px 29px 0px rgba(0,0,0,0.75);
          -moz-box-shadow: 10px 10px 29px 0px rgba(0,0,0,0.75);
          box-shadow: 10px 10px 29px 0px rgba(0,0,0,0.75);

          background-image: url('/media/images/why-domki/white-box.jpg');
          height: 973px;
          width: 661px;
          padding: 0 66px;
          margin-top: 150px;
          margin-right: 9%;

          float: right;

          @media screen and (max-width: $md-break){
            padding-top: 1px;
          }

          @media screen and (max-width: $sm-break){
            margin-top: 0px;
            width: inherit;
            background-size: cover;
            background-size: 100% 100%;
            height: auto;
            padding-bottom: 50px;
            margin-bottom: -1px;  //usuniecie linii pod kontenerem

            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;

            margin-right: 0;
          }

          @media screen and (max-width: $xs-break){
            background-image: none;
            background-color: white;
            padding-top: 50px;
          }

          .title{
              margin-top: 0px;
              p{
                  color: $verydarkgreen;
                  font-family: 'ChaparralPro-BoldIt';
                  font-size: 64px;
                  margin-top: 70px;
                  margin-bottom: 0px;
                  text-align: center;

                  &.szczyrk-title{
                    @media screen and (max-width: $break-sm){
                      font-size: 50px;
                    }

                    @media screen and (max-width: $break-xs){
                      line-height: 30px;
                      margin-top: 0;
                      font-size: 34px;
                    }
                  }
              }

              .subtitle{
                color: $brown;
                font-size: 36px;
                font-family: 'ChaparralPro-Italic';
                line-height: 29px;
                text-align: center;

                @media screen and (max-width: $break-xs){
                  font-size: 18px;
                }
              }

              h1{
                font-family: 'ChaparralPro-Italic';
                font-size: 28px;
                margin-top: 45px;
                color: $verydarkgreen;

                @media screen and (max-width: $break-xs){
                  font-size: 20px;
                }
              }

              h2{
                color: $verydarkgreen;
                font-family: 'OpenSans-ExtraBold';
                font-size: 20px;
                line-height: 30px;
                text-align: right;
                margin-top: -11px;

                @media screen and (max-width: $break-xs){
                  font-size: 16px;
                }
              }

              h3{
                color: $verydarkgreen;
                font-family: 'ChaparralPro-BoldIt';
                font-size: 40px;
                text-align: center;

                @media screen and (max-width: $break-xs){
                  font-size: 28px;
                }
              }

              a{
                text-align: center;
                color: $blue;
                font-family: 'OpenSans-ExtraBold';
                font-size: 17px;
                text-decoration: underline;
                display: block;
                line-height: 28px;
                margin-bottom: 10px;
              }
          }

          .blue-button{
            @media all and (max-width: $break-xs){
              width: 220px;
            }
          }
        }
    }
}
.booking-box{
    height: 1170px;

    @media screen and (max-width: $break-sm){
        height: auto;
        background-image: url(/pliki/domek/domekajpg.jpg);
        background-size: 100%;
        background-repeat: no-repeat;
    }

    .info{
      -webkit-box-shadow: 10px 10px 29px 0px rgba(0,0,0,0.75);
      -moz-box-shadow: 10px 10px 29px 0px rgba(0,0,0,0.75);
      box-shadow: 10px 10px 29px 0px rgba(0,0,0,0.75);

      background-image: url('/media/images/products/white-box.jpg');
      height: 726px;
      width: 661px;
      padding: 0 66px;
      position: relative;
      //margin-top: 150px;

      

      &.left-info{
        margin-top: 265px; // wersja prawa
        margin-left: 8.7%;
        float: left;

        @media screen and (max-width: $break-sm){
            margin-left: 0;
            margin-top: 63.3vw;
        }
      }

      &.right-info{
        margin-top: 265px; // wersja prawa
        margin-right: 8.7%;
        float: right;

        @media screen and (max-width: $break-sm){
            margin-right: 0;
            margin-top: 63.3vw;
        }
      }

      @media screen and (max-width: 1592px){

      }

      @media screen and (max-width: $break-md){
        padding-top: 1px;
      }

      @media screen and (max-width: $break-sm){
        margin-top: 90px;
        width: inherit;
        background-size: cover;
        background-size: 100% 100%;
        height: auto;
        padding-bottom: 50px;
        margin-bottom: -1px;  //usuniecie linii pod kontenerem

        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }

      @media screen and (max-width: $break-xs){
        background-image: none;
        background-color: white;
        width: 100%;
      }

      img.bookingicon{
        position: absolute;
        top: -79px;
        left: 509px;

        @media screen and (max-width: $break-sm){
          display: none;
          top: -82px;
          left: 34%;
        }

        @media screen and (max-width: $break-xs){
          left: 27%;
        }

        @media screen and (max-width: 362px){
          left: 19%;
        }
      }

      .title{
          margin-top: 0;
          
          p{
              color: $verydarkgreen;
              font-family: 'ChaparralPro-BoldIt';
              font-size: 70px;
              margin-bottom: 0px;
              text-align: center;
              line-height: 66px;
              margin-top: 20px;

              &.szczyrk-title{
                @media all and (max-width: 393px){
                  font-size: 43px;

                }
              }
          }

          .opinions{
            margin-top: 45px;
          }

          img.bookinglogo{
            display: block;
            margin: 0 auto;
            margin-top: 114px;

            @media screen and (max-width: $break-xs){
                width: 218px;
                margin-top: 40px;
            }
          }

          .subtitle{
            color: $lightbrown;
            font-size: 37px;
            font-family: 'ChaparralPro-Italic';
            line-height: 35px;
            text-align: center;
            margin-top: 14px;
          }

          h1{
            color: $blue;
            font-family: 'ChaparralPro-Italic';
            font-size: 22px;
            margin-top: 28px;
            margin-bottom: 20px;
            text-align: center;

            span{
              font-family: 'ChaparralPro-BoldIt';
              color: $verydarkgreen;
              font-size: 28px;
            }
          }

          h2{
            text-align: center;
            font-family: 'ChaparralPro-BoldIt';
            color: $verydarkgreen;
            font-size: 28px;
            margin-bottom: 35px;
            padding: 0 26px;

            img{
                margin: 0;
                margin-right: 6px;
                display: inline-block;
                float: none;
            }

        
        }
      }

      .blue-button{
        width: 226px;
        margin-top: 41px;
      }
    }
  }
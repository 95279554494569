$lightbrown: #A18A5B;

.szczyrk-page-container{
    .szczyrk-container{
    background-image: url('/media/images/szczyrk/szczyrk-background.jpg');
    height: 1000px;
    background-size: cover;

    position: relative;

    @media screen and (max-width: 1585px){
        height: 1350px;
    }

    @media screen and (max-width: $sm-break){
        height: auto;
        background-image: url('/media/images/szczyrk/szczyrk-background-mobile.jpg');
        background-size: 100% 700px;
        background-repeat: no-repeat;
    }

    @media screen and (max-width: $xs-break){
        background-size: 100% 530px;
    }

    .left-text{
        position: absolute;
        top: 30px;
        left: 228px;
        background: none;
        width: 564px;

        @media screen and (max-width: 1585px){
            left: 24%;
            width: 55%;
        }

        @media screen and (max-width: $break-xs){
            left: 22%;
            width: 53%;
        }

        h1{
            color: $verydarkgreen;
            text-align: center;
            font-family: 'ChaparralPro-BoldIt';
            font-size: 70px;

            @media screen and (max-width: $break-xs){
                font-size: 48px;
            }
        }

        p{
            color: $verydarkgreen;
            text-align: center;
            font-family: 'ChaparralPro-BoldIt';
            font-size: 28px;
            line-height: 30px;

            @media screen and (max-width: $break-xs){
                line-height: 22px;
                font-size: 18px;
            }
        }
    }

    .info{
      -webkit-box-shadow: 10px 10px 29px 0px rgba(0,0,0,0.75);
      -moz-box-shadow: 10px 10px 29px 0px rgba(0,0,0,0.75);
      box-shadow: 10px 10px 29px 0px rgba(0,0,0,0.75);

      background-image: url('/media/images/homepage/snowy-background.jpg');
      height: 805px;
      width: 661px;
      padding: 0 66px;
      margin-top: 83px;
      margin-right: 9%;

      float: right;



      @media screen and (max-width: 1585px){
        margin-top: 450px;
        float: none;
        margin-left: auto;
        margin-right: auto;
        padding-top: 1px;
      }

      @media screen and (max-width: $lg-break){
        margin-left: auto;
        margin-right: auto;
        padding-top: 1px;
        float: none;
      }

    

      @media screen and (max-width: $sm-break){
        width: inherit;
        background-size: cover;
        background-size: 100% 100%;
        height: auto;
        padding-bottom: 50px;
        margin-bottom: -1px;  //usuniecie linii pod kontenerem
        margin-right: 0;
        margin-top: 700px;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }

      @media screen and (max-width: $xs-break){
        margin-top: 530px;
        background-image: none;
        background-color: white;
        padding-left: 30px;
        padding-right: 30px;
      }

      @media screen and (max-width: 390px){
        margin-top: 530px;
      }

      .title{
          margin-top: 0px;
          p{
              color: $lightbrown;
              font-family: 'ChaparralPro-BoldIt';
              font-size: 48px;
              margin-top: 60px;
              margin-bottom: 0px;
              text-align: center;

              &.szczyrk-title{
                @media all and (max-width: 393px){
                  font-size: 40px;
                }
              }
          }

          .subtitle{
            color: $verydarkgreen;
            font-size: 35px;
            font-family: 'ChaparralPro-Italic';
            line-height: 35px;
            text-align: center;
            margin-top: -16px;
          }

          h1{
            font-family: 'ChaparralPro-BoldIt';
            font-size: 28px;
            margin-top: 50px;
            color: $verydarkgreen;
            text-align: center;

            &.small-margin{
                margin-top: 5px;
            }
          }

          h2{
            color: $verydarkgreen;
            font-family: 'OpenSans-Regular';
            font-size: 20px;
            line-height: 30px;
            text-align: center;
            margin-top: 47px;

            span{
                font-family: 'OpenSans-ExtraBold';
            }
          }

          h3{
            color: $verydarkgreen;
            font-family: 'ChaparralPro-BoldIt';
            font-size: 40px;
            text-align: center;
          }

          a{
            text-align: center;
            color: $blue;
            font-family: 'OpenSans-ExtraBold';
            font-size: 20px;
            text-decoration: underline;
            display: block;
            line-height: 28px;
          }

          img{
            display: block;
            margin: 0 auto;
            margin-top: 30px;
          }
      }

      .blue-button{
        @media all and (max-width: $break-xs){
          width: 220px;
        }
      }
    }
  }

  

}

.check-calendar{
    background-image: url('/media/images/szczyrk/check-szczyrk-background.jpg');
    height: 974px;
    background-size: cover;

    @media screen and (max-width: 1044px){
      height: auto;
    }

    h1{
      color: white;
      font-family: 'ChaparralPro-BoldIt';
      font-size: 40px;
      text-align: center;
      margin-top: 80px;
      padding: 0 40px;

      @media screen and (max-width: 520px){
        font-size: 30px;
        margin-top: 19px;
      }
    }

    h2{
      color: $verydarkgreen;
      font-family: 'ChaparralPro-Italic';
      font-size: 40px;
      text-align: center;
      padding: 0 40px;

      @media screen and (max-width: 520px){
        font-size: 24px;
      }
    }

    img{
      display: block;
      margin: 0 auto;
      position: relative;
      margin-top: 50px;

      @media screen and (max-width: 520px){
          margin-top: 10px;
      }
    }

    .calendar{
      background: url('/media/images/szczyrk/calendar.jpg');
      position: relative;
      width: 1027px;
      height: 513px;
      margin: 0 auto;
      margin-top: 28px;

      @media screen and (max-width: 1044px){
        background: url('/media/images/szczyrk/calendar-sm.jpg');
        width: 512px;
        height: 1025px;
        margin-bottom: 100px;
      }

      @media screen and (max-width: 520px){
        background: url('/media/images/szczyrk/calendar-xs.jpg');
        width: 303px;
        height: 609px;
      }

      .blue-button{
        position: absolute;
        
        width: 131px;
        height: 58px;
        top: 266px;

        &.left-button{
          left: 191px;

          @media screen and (max-width: 520px){
            top: 173px;
            left: 87px;
          }
        }

        &.right-button{
          right: 191px;

          @media screen and (max-width: 1044px){
            top: 778px;
          }

          @media screen and (max-width: 520px){
            top: 479px;
            right: 87px;
          }
        }
      }
    }
    
  }

  .meet-the-szczyrk{
    background: url('/media/images/szczyrk/meet-the-szczyrk-background.jpg');
    height: 352px;

    .meet-the-szczyrk-box{
      margin: 0 auto;
      width: 807px;
      height: 152px;
      border: solid 2px white;
      text-align: center;
      font-family: 'ChaparralPro-BoldIt';
      font-size: 40px;
      color: white;
      vertical-align: center;
      padding-top: 45px;
      margin-top: 90px;

      @media screen and (max-width: 847px){
        width: 87%;
      }

      @media screen and (max-width: $break-xs){
        font-size: 30px;
      }

      .blue-button{
        width: 200px;
        margin-top: 18px;

        @media screen and (max-width: $break-xs){
          margin-top: 33px;
        }
      }
    }
  }